import React, { useEffect } from 'react'

import useProvideAuth from '@/hook/useProvideAuth'
import AuthContext from '@/context/AuthContext'

const AuthProvider: React.FC<PropType> = ({ children }) => {
  const auth = useProvideAuth()

  useEffect(() => {
    // 用户登录状态初始化
    auth.init()
  }, [])

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

interface PropType {
  children: React.ReactNode
}

export { AuthProvider }
