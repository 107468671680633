import React, { useContext } from 'react'
import { twMerge } from 'tailwind-merge'

import AuthContext from '@/context/AuthContext'
import { PopUpStore } from '@/store/PopupStore'
import Picture from '../Picture'
import Mask from '../Mask'
import { ReactComponent as CameraSVG } from '@/asset/svg/camera.svg'
import { ReactComponent as AvatarSVG } from '@/asset/svg/avatar.svg'

const Avatar: React.FC<PropType> = ({
  onClick,
  shape = 'circular',
  hoverEdit = false,
  focusEdit = false,
  className
}) => {
  const { user } = useContext(AuthContext)

  const handleClick = () => {
    ;(hoverEdit || focusEdit) && PopUpStore.setEditAvatarPopupVisible(true)
    onClick && onClick()
  }

  const classNames = twMerge(
    'relative flex aspect-square h-full items-center justify-center overflow-hidden bg-[#8a9bff] group',
    shape === 'square' ? 'rounded-xl' : 'rounded-full',
    onClick || hoverEdit || focusEdit ? 'cursor-pointer' : '',
    className
  )

  return (
    <div className={classNames} onClick={handleClick}>
      {user?.avatar ? <Picture src={user?.avatar} /> : <AvatarSVG className="aspect-square w-9/12 fill-white" />}
      {hoverEdit && (
        <>
          <Mask visible={true} getContainer={false} zIndex={10} className="hidden group-hover:block" />
          <div className="absolute left-1/2 top-1/2 z-20 hidden h-8 w-8 -translate-x-1/2 -translate-y-1/2 items-center justify-center overflow-hidden rounded-full bg-gray-200 group-hover:flex">
            <CameraSVG className="h-5 w-5" />
          </div>
        </>
      )}
      {focusEdit && (
        <div className="absolute flex h-10 w-10 items-center justify-center overflow-hidden rounded-full bg-black/80">
          <CameraSVG className="h-5 w-5 fill-white" />
        </div>
      )}
    </div>
  )
}

interface PropType {
  onClick?: () => void
  shape?: 'circular' | 'square'
  hoverEdit?: boolean
  focusEdit?: boolean
  className?: string
}

export default Avatar
