import React from 'react'
import { useTranslation } from 'react-i18next'

import useStatePopup from '@/hook/useStatePopup'
import { PopupSelector, PopUpStore } from '@/store/PopupStore'
import Drawer from '@/component/Drawer'
import VIPOrderDetailMain from './VIPOrderDetailMain'

const VIPOrderDetailPopup: React.FC<PropType> = () => {
  const { t } = useTranslation('vip')
  const { visible } = useStatePopup(PopupSelector.vipOrderDetailPopup)

  const handleClose = () => {
    PopUpStore.setVIPOrderDetailPopup({
      visible: false
    })
  }

  return (
    <Drawer
      visible={visible}
      zIndex={904}
      onClose={handleClose}
      lockScroll={true}
      destroyOnClose
      className="bg-primary-light"
      title={t('modalTitle.orderDetail')}
    >
      <VIPOrderDetailMain />
    </Drawer>
  )
}

interface PropType {}

export default VIPOrderDetailPopup
