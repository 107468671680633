import React from 'react'

import BlankCard from '@/component/card/BlankCard'
import Divider from '@/component/Divider'
import Award from '@/component/Award'
import TaskBtn from '../TaskBtn'
import type { TaskItemType } from '@kulee/tga-sdk/types/types/growth'

const TaskItem: React.FC<PropType> = ({ task }) => {
  const { title, rewards, progresses } = task

  return (
    <BlankCard className="mb-sm p-md">
      <div className="flex gap-sm">
        <div className="flex-grow">
          <h4 className="text-base">{title}</h4>
          <Award items={rewards} />
        </div>
        <div>
          <TaskBtn task={task} />
        </div>
      </div>
      <Divider />
      <ul className="flex list-inside list-disc flex-col gap-1 text-xs">
        {progresses.map((progress, index) => (
          <li key={index}>{progress}</li>
        ))}
      </ul>
    </BlankCard>
  )
}

interface PropType {
  task: TaskItemType
}

export default TaskItem
