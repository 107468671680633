import type { GameRankConfig } from '@/types/rank'

class Convert {
  /**
   * 游戏排行配置数据转换
   * @param data 服务端数据
   * @returns 客户端数据
   */
  public static gameRankConfigsToClient(data: ServerDataNS.GameRankConfig[]): GameRankConfig[] {
    return data.map(item => {
      const { id, idx: order, rankName: name, unit, description: desc } = item

      return {
        id,
        order,
        name,
        unit,
        desc
      }
    })
  }
}

export default Convert
