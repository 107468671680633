import logger from '../../config/logger'
import type { OrderStatus } from '../../types'
import type {
  ChargeCommodity,
  ChargeOrder,
  OrderExt,
  PayOrderType,
  PayStatus,
  Wallet,
  WalletBilling
} from '../../types/wallet'

class Convert {
  /**
   * 钱包数据转换
   * @param wallet 服务端钱包数据
   * @returns 客户端钱包数据
   */
  public static walletToClient(wallet: WalletDataNS.Wallet): Wallet {
    const walletRes: Wallet = {}
    Object.keys(wallet).map(code => {
      const { type, ...rest } = wallet[code as WalletDataNS.CurrencyCode] as WalletDataNS.Currency

      walletRes[code as WalletDataNS.CurrencyCode] = {
        ...rest,
        type: type === 1 ? 'cash' : type === 2 ? 'token' : type === 3 ? 'coin' : type
      }
      return code
    })

    return walletRes
  }

  public static billingToClient(billing: WalletDataNS.WalletBilling): WalletBilling {
    const { type, description: desc, ...rest } = billing
    return {
      ...rest,
      type: type === 1 ? 'in' : type === 2 ? 'out' : type,
      desc
    }
  }

  public static billingsToClient(billings: WalletDataNS.WalletBilling[]): WalletBilling[] {
    return billings.map(item => this.billingToClient(item))
  }

  /**
   * 钱包商品数据转换
   * @param commodity 服务端钱包商品
   * @returns 客户端钱包商品
   */
  public static chargeCommodityToClient(commodity: WalletDataNS.ChargeCommodity): ChargeCommodity {
    const { currencyCode: targetCurrency, paymentCurrencyCode: payCurrency, prefix, remark: desc, ...rest } = commodity

    return {
      ...rest,
      targetCurrency,
      payCurrency,
      payCurrencyPrefix: prefix || '',
      desc
    }
  }

  /**
   * 钱包商品列表数据转换
   * @param commodities 服务端钱包商品列表
   * @returns 客户端钱包商品列表
   */
  public static chargeCommoditiesToClient(commodities: WalletDataNS.ChargeCommodity[]): ChargeCommodity[] {
    return commodities.map(item => this.chargeCommodityToClient(item))
  }

  /**
   * 充值订单数据转换
   * @param order 服务端充值定单
   * @returns 客户端充值订单
   */
  public static createChargeOrderResultToChargeOrder(order: WalletDataNS.CreateChargeOrderResult): ChargeOrder {
    const { description: desc, externalParam, ...rest } = order

    let ext = {}

    try {
      ext = JSON.parse(externalParam)
    } catch (error) {
      logger.warn('parse order ext error', error)
    }

    return {
      ...rest,
      status: 'pending',
      ext: ext as OrderExt,
      desc
    }
  }

  public static ChargeOrderToClient(order: WalletDataNS.ChargeOrder): ChargeOrder {
    const { tradeStatus, ...rest } = order

    return {
      ...rest,
      paymentCode: 'GOOGLEPAY',
      status: this.chargeOrderStatusToClient(tradeStatus),
      desc: null
    }
  }

  public static payOrderToClient(order: WalletDataNS.PayOrderType): PayOrderType {
    const {
      orderNo,
      bizOrderNo,
      amount,
      payStatus,
      prefix: currencyPrefix,
      commodityId: id,
      title: name,
      currencyCode,
      description: desc,
      ...rest
    } = order

    return {
      ...rest,
      orderNo: bizOrderNo,
      payOrderNo: orderNo,
      amount,
      status: this.payStatusToClient(payStatus),
      currencyCode,
      currencyPrefix,
      commodity: {
        id,
        name,
        payCurrency: currencyCode,
        payCurrencyPrefix: currencyPrefix,
        price: amount,
        desc,
        ext: {}
      }
    }
  }

  public static payStatusToClient(payStatus: WalletDataNS.PayStatus): PayStatus {
    return payStatus === -1
      ? 'canceled'
      : payStatus === 0
      ? 'pending'
      : payStatus === 1
      ? 'paid'
      : payStatus === 2
      ? 'refunded'
      : payStatus
  }

  private static chargeOrderStatusToClient(status: WalletDataNS.ChargeOrderStatus): OrderStatus {
    return status === 0 ? 'pending' : status === 1 ? 'paid' : status === 2 ? 'completed' : status
  }
}

export default Convert
