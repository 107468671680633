import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const GoldExchangeInput: React.FC<PropType> = ({ step = 1, max = 0, onChange }) => {
  const { t } = useTranslation('wallet')
  const [inputValue, setInputValue] = useState('0')
  const [realValue, setRealValue] = useState(0)

  const getValue = (value: number) => {
    return value > 0 && value <= max ? value : 0
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    if (/\D/.test(e.target.value)) {
      setInputValue(realValue.toString())
    } else {
      setRealValue(getValue(parseInt(e.target.value)))
    }
  }

  const handleAdd = () => {
    setRealValue(getValue(realValue + step))
  }
  const handleSub = () => {
    setRealValue(getValue(realValue - step))
  }
  const handleMax = () => {
    setRealValue(max)
  }

  useEffect(() => {
    setInputValue(realValue.toString())
    onChange && onChange(realValue)
  }, [realValue])

  return (
    <div className="flex gap-sm">
      <div className="flex-grow">
        <div className="flex h-10 items-center justify-center overflow-hidden rounded-xl bg-gray-200 ">
          <button className="h-10 w-10 text-xl active:bg-gray-300" onClick={handleSub}>
            -
          </button>
          <div className="flex-grow">
            <input
              value={inputValue}
              onChange={handleChange}
              onBlur={handleBlur}
              className="h-10 w-full bg-gray-200 text-center text-base outline-none"
            />
          </div>
          <button className="h-10 w-10 text-xl active:bg-gray-300" onClick={handleAdd}>
            +
          </button>
        </div>
      </div>
      <div>
        <button className="h-full rounded-xl bg-gray-200 px-md active:bg-gray-300" onClick={handleMax}>
          {t('btn.max')}
        </button>
      </div>
    </div>
  )
}

interface PropType {
  step?: number
  max?: number
  onChange?: (value: number) => void
}

export default GoldExchangeInput
