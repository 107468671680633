import React from 'react'

import { CheckinDayItemType } from '@kulee/tga-sdk/types/types/growth'
import Award from '@/component/Award'

const DayItem: React.FC<PropType> = ({ item, today = false }) => {
  const { title, status, rewards } = item

  const statusClassName = status === 'received' ? (today ? '' : 'opacity-50') : 'grayscale opacity-70'

  return (
    <div
      className={`flex flex-col items-center justify-center overflow-hidden rounded-lg border border-primary-dark bg-gradient-to-b from-white from-30% to-primary-extralight ${statusClassName}`}
    >
      <div className="text-primary-text mb-sm w-2/3 rounded-bl-2xl rounded-br-2xl bg-primary text-center font-custom-bold leading-6">
        {title}
      </div>
      <div className="mb-1">
        <Award items={rewards} dir="col" size="large" />
      </div>
    </div>
  )
}

interface PropType {
  item: CheckinDayItemType
  today: boolean
}

export default DayItem
