import React from 'react'
import { useTranslation } from 'react-i18next'

import useStatePopup from '@/hook/useStatePopup'
import { PopupSelector, PopUpStore } from '@/store/PopupStore'
import Drawer from '@/component/Drawer'
import VIPOrderMain from './VIPOrderMain'

const VIPOrderPopup: React.FC<PropType> = () => {
  const { t } = useTranslation('vip')
  const vipOrderPopupVisible = useStatePopup(PopupSelector.vipOrderPopupVisible)

  const handleClose = () => {
    PopUpStore.setVIPOrderPopupVisible(false)
  }

  return (
    <Drawer
      visible={vipOrderPopupVisible}
      zIndex={903}
      onClose={handleClose}
      lockScroll={true}
      destroyOnClose
      className="bg-primary-light"
      title={t('modalTitle.order')}
    >
      <VIPOrderMain />
    </Drawer>
  )
}

interface PropType {}

export default VIPOrderPopup
