import BaseModule from '../../BaseModule'
import logger from '../../config/logger'

import GameService from './Service'

class Game extends BaseModule {
  public static override clazz = 'Game'

  public service!: GameService

  public override run(): void {
    logger.log(`run module ${Game.clazz}`)

    this.service = new GameService(this.context)
  }
}

export default Game
