import { twMerge } from 'tailwind-merge'

const useIconProps = ({ size = 'middle', fill = 'black', rotate = 0, className }: IconPropType) => {
  const sizeClassName = size === 'small' ? 'w-4' : size === 'middle' ? 'w-6' : 'w-8'
  const rotateValue = `rotate-${rotate}`
  const fillValue = `fill-${fill}`

  return {
    className: twMerge('aspect-square', sizeClassName, rotateValue, fillValue, className)
  }
}

export interface IconPropType {
  size?: 'small' | 'middle' | 'large'
  fill?: string
  rotate?: number
  className?: string
}

export default useIconProps
