import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import WalletContext from '@/context/WalletContext'
import { PopUpStore } from '@/store/PopupStore'
import Button from '@/component/Button'
import CurrencyIcon from '@/component/CurrencyIcon'

const DiamondBalance: React.FC<PropType> = () => {
  const { t } = useTranslation('wallet')
  const { wallet } = useContext(WalletContext)

  const diamondCurrency = wallet['DIAMOND']

  if (!diamondCurrency) {
    return null
  }

  return (
    <div className="mb-md overflow-hidden rounded-2xl bg-[#70c1fe] px-md py-sm text-white">
      <div className="text-right">
        <Button
          type="link"
          className="px-0 text-sm font-normal text-white underline underline-offset-1"
          onClick={() =>
            PopUpStore.setBillingPopup({
              visible: true,
              active: 'DIAMOND'
            })
          }
        >
          {t('btn.transactionBreakdown')}
        </Button>
      </div>
      <p className="mb-2">
        {t('currency.diamond')} {t('title.balance')}
      </p>
      <div className="mb-4 flex flex-grow items-center gap-sm">
        <CurrencyIcon code="diamond" size="large" />
        <span className="font-custom-bold text-4xl">{wallet.DIAMOND?.balance}</span>
      </div>
    </div>
  )
}

interface PropType {}

export default DiamondBalance
