import React from 'react'

import { ReactComponent as Icon } from '@/asset/svg/arrow.svg'
import useIconProps, { IconPropType } from '@/hook/useIconProps'

const ArrowIcon: React.FC<IconPropType> = props => {
  const iconProps = useIconProps(props)

  return <Icon {...iconProps} />
}

export default ArrowIcon
