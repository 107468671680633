import BaseModule from '../../BaseModule'
import logger from '../../config/logger'

import GrowthService from './Service'

class Growth extends BaseModule {
  public static override clazz = 'Growth'

  public service!: GrowthService

  public override run(): void {
    logger.log(`run module ${Growth.clazz}`)

    this.service = new GrowthService(this.context)
  }
}

export default Growth
