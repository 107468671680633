import { useContext } from 'react'

import useLinkTo from './useLinkTo'
import AuthContext from '@/context/AuthContext'
import { KULEWEBVIEW } from '@/config/constant'

const useAppLogin = () => {
  const { linkTo } = useLinkTo()
  const { init } = useContext(AuthContext)

  const appLogin = async () => {
    if (KULEWEBVIEW) {
      await window.Bridge.uc.login()

      init()
    } else {
      linkTo('/login/')
    }
  }

  return appLogin
}

export default useAppLogin
