import { TGASDK, Game, Ad } from '@kulee/tga-sdk'

import { env } from '@/helper'

const TGASDKInit = () => {
  TGASDK.init({
    env,
    appConfig: window.AppConfig.data
  })

  TGASDK.addModule(Game)
  TGASDK.addModule(Ad)
}

export { TGASDKInit }

export default TGASDK
