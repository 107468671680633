import React from 'react'
import { useTranslation } from 'react-i18next'

import useStatePopup from '@/hook/useStatePopup'
import { PopupSelector, PopUpStore } from '@/store/PopupStore'
import Drawer from '@/component/Drawer'
import MyCheckin from './MyCheckin'
import CheckinMain from './CheckinMain'

const CheckinPopup: React.FC<PropType> = () => {
  const { t } = useTranslation('growth')
  const checkinPopupVisible = useStatePopup(PopupSelector.checkinPopupVisible)

  const handleClose = () => {
    PopUpStore.setCheckinPopupVisible(false)
  }

  return (
    <Drawer
      visible={checkinPopupVisible}
      zIndex={901}
      onClose={handleClose}
      lockScroll={true}
      destroyOnClose={true}
      className="bg-primary-light"
      title={t('modalTitle.checkin')}
    >
      <div className="px-sm">
        <MyCheckin />
        <CheckinMain />
      </div>
    </Drawer>
  )
}

interface PropType {}

export default CheckinPopup
