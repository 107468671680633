import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

const Mask: React.FC<PropType> = ({
  visible = false,
  zIndex = 900,
  getContainer = document.body,
  lockScroll,
  className,
  onClick
}) => {
  // unready: 未初始化、firstEntering：首次进场中、entering：进场中、enter：进场结束、exiting：出场中、exit：出场结束
  const [status, setStatus] = useState<'unready' | 'firstEntering' | 'entering' | 'enter' | 'exiting' | 'exit'>(
    'unready'
  )
  const [classNames, setClassNames] = useState('invisible bg-transparent')
  const bodyOverflowValueRef = useRef('')

  const container = typeof getContainer === 'function' ? getContainer() : getContainer

  const handleTransitionEnd = () => {
    if (!visible) {
      setClassNames('invisible bg-transparent')
      lockScroll && unlockWinScroll()
    }
  }

  const lockWinScroll = () => {
    bodyOverflowValueRef.current = document.body.style.overflow
    document.body.style.overflow = 'hidden'
  }

  const unlockWinScroll = () => {
    document.body.style.overflow.indexOf('hidden') >= 0 && (document.body.style.overflow = bodyOverflowValueRef.current)
  }

  useEffect(() => {
    if (status === 'unready' && !visible) {
      return
    }

    setStatus(visible ? (status === 'unready' ? 'firstEntering' : 'entering') : 'exiting')
  }, [visible])

  useEffect(() => {
    switch (status) {
      case 'firstEntering':
      case 'entering':
        lockScroll && lockWinScroll()
        setTimeout(
          () => {
            setClassNames('visible bg-black/50')
          },
          status === 'firstEntering' ? 50 : 0
        )
        break
      case 'exiting':
        setClassNames('bg-transparent')
        break
      default:
        break
    }
  }, [status])

  if (status === 'unready') {
    return null
  }

  const mask = (
    <div
      className={`pointer-events-auto inset-0 transition-all duration-200 ease-in-out ${container ? 'fixed' : 'absolute'} ${classNames} ${className}`}
      style={{
        zIndex
      }}
      onClick={onClick}
      onTransitionEnd={handleTransitionEnd}
    ></div>
  )

  return container ? createPortal(mask, container) : <>{mask}</>
}

interface PropType {
  visible?: boolean
  zIndex?: number
  getContainer?: false | HTMLElement | (() => HTMLElement)
  className?: string
  lockScroll?: boolean
  onClick?: () => void
}

export default Mask
