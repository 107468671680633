import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { VIP } from '@kulee/tga-sdk'

import TGASDK from '@/config/TGASDK'
import VIPContext from '@/context/VIPContext'
import Loading from '@/component/Loading'
import VIPCommodityItem from '../VIPCommodityItem'
import Button from '@/component/Button'
import Message from '@/component/Message'

import { PopUpStore } from '@/store/PopupStore'
import BenefitList from '../BenefitList'
import type { VIPItem, VIPCommodity } from '@kulee/tga-sdk/types/types/vip'
import type { BussnessOrderType } from '@kulee/tga-sdk/types/types/wallet'

const VIPSeller: React.FC<PropType> = () => {
  const { t } = useTranslation('vip')
  const { init: vipInit } = useContext(VIPContext)
  const [vip, setVIP] = useState<VIPItem>()
  const [commodity, setCommodity] = useState<VIPCommodity>()
  const [orderStatus, setOrderStatus] = useState<'success' | 'error' | 'warn'>()

  const handleVIPCommodityClick = (item: VIPCommodity) => {
    setCommodity(item)
  }

  const checkOrderMutation = useMutation({
    mutationFn: async (order: BussnessOrderType) => {
      const { data } = await TGASDK.module.VIP.service.getOrderDetail(order.orderNo)

      if (data.status === 'completed') {
        return data
      }

      throw new Error('')
    },
    retry: 3,
    retryDelay: 1000
  })

  const handleBuyClick = () => {
    if (!commodity) {
      return
    }

    PopUpStore.setPayPopup({
      visible: true,
      commodity,
      createOrder: async (paymentCode: string) => {
        const { data } = await TGASDK.module.VIP.service.createVIPOrder({
          commodityId: commodity.id,
          paymentCode
        })
        return data
      },
      onSuccess: order => {
        setOrderStatus('warn')

        checkOrderMutation.mutate(order, {
          onSuccess: () => {
            setOrderStatus('success')
            vipInit()
          },
          onError: error => {
            setOrderStatus('error')
          }
        })
      }
    })
  }

  const init = async () => {
    try {
      const { data } = await TGASDK.module.VIP.service.getVIPs()

      if (data.length > 0) {
        setVIP(data[0])
        setCommodity(data[0].commodities[0])
      }
    } catch (error) {}
  }

  useEffect(() => {
    TGASDK.addModule(VIP)
    init()
  }, [])

  if (!vip) {
    return <Loading className="h-40" />
  }

  return (
    <div className="mb-md">
      <div className="mb-md grid grid-cols-2 gap-sm">
        {vip.commodities.map(item => (
          <VIPCommodityItem
            key={item.id}
            item={item}
            checked={commodity?.id === item.id}
            onClick={handleVIPCommodityClick}
          />
        ))}
      </div>
      {orderStatus && (
        <Message type={orderStatus} className="mb-sm">
          {orderStatus === 'warn'
            ? t('tip.orderChecking')
            : orderStatus === 'success'
              ? t('tip.orderSuccess')
              : t('tip.orderFail')}
        </Message>
      )}
      <Button type="primary" className="mb-md w-full" onClick={handleBuyClick}>
        {t('btn.buy')}
      </Button>
      <BenefitList benefits={vip.benefits} />
    </div>
  )
}

interface PropType {}

export default VIPSeller
