import React from 'react'

import Price from '@/component/Price'
import type { VIPCommodity } from '@kulee/tga-sdk/types/types/vip'

const VIPCommodityItem: React.FC<PropType> = ({ item, checked = false, onClick }) => {
  const { name, payCurrency, payCurrencyPrefix, price, priceOriginal } = item

  const prefix = payCurrency === 'GOLD' || payCurrency === 'DIAMOND' ? '钻石' : payCurrencyPrefix

  const handleClick = () => {
    onClick(item)
  }

  return (
    <div
      className={`flex cursor-pointer flex-col items-center justify-center overflow-hidden rounded-lg border border-primary-dark/30 ${checked ? 'bg-primary/15' : 'bg-gray-400/20'}`}
      onClick={handleClick}
    >
      <div className="mb-sm w-2/3 rounded-bl-2xl rounded-br-2xl bg-primary/30 text-center font-custom-bold leading-6">
        {name}
      </div>
      <div className="mb-md w-full py-sm">
        <Price price={price} currencyCode={payCurrency} currencyPrefix={payCurrencyPrefix} size="large" type="stress" />
        {priceOriginal && (
          <Price price={priceOriginal} currencyCode={payCurrency} currencyPrefix={prefix} size="small" type="delete" />
        )}
      </div>
    </div>
  )
}

interface PropType {
  item: VIPCommodity
  checked?: boolean
  onClick: (item: VIPCommodity) => void
}

export default VIPCommodityItem
