import React from 'react'
import { type RouteObject } from 'react-router-dom'

import MainLayout from '@/component/layout/MainLayout'
import RedirectLayout from '@/component/layout/RedirectLayout'

const AuthRequired = React.lazy(() => import('@/component/AuthRequired'))

const routes: RouteObject[] = [
  {
    path: '/',
    children: [
      {
        // 处理跳转
        index: true,
        element: <RedirectLayout />
      },
      {
        path: ':lang/:channel/',
        element: <MainLayout />,
        children: [
          {
            index: true,
            lazy: async () => {
              return {
                Component: (await import('../page/Home')).default
              }
            }
          },
          {
            path: 'recent/',
            lazy: async () => {
              return {
                Component: (await import('../page/Recent')).default
              }
            }
          },
          {
            path: 'tags/',
            lazy: async () => {
              return {
                Component: (await import('../page/Tags')).default
              }
            }
          },
          {
            path: 'tag/:code/',
            lazy: async () => {
              return {
                Component: (await import('../page/Tag')).default
              }
            }
          },
          {
            path: 'new/',
            lazy: async () => {
              return {
                Component: (await import('../page/Recommend')).default
              }
            },
            loader: () => 'new'
          },
          {
            path: 'hot/',
            id: 'hot',
            lazy: async () => {
              return {
                Component: (await import('../page/Recommend')).default
              }
            },
            loader: () => 'hot'
          },
          {
            path: 'terms/',
            id: 'terms',
            lazy: async () => {
              return {
                Component: (await import('../page/Law')).default
              }
            },
            loader: () => 'terms'
          },
          {
            path: 'privacy/',
            id: 'privacy',
            lazy: async () => {
              return {
                Component: (await import('../page/Law')).default
              }
            },
            loader: () => 'privacy'
          },
          {
            path: 'cookies/',
            id: 'cookies',
            lazy: async () => {
              return {
                Component: (await import('../page/Law')).default
              }
            },
            loader: () => 'cookies'
          },
          {
            path: 'games/',
            lazy: async () => {
              return {
                Component: (await import('../page/Games')).default
              }
            }
          },
          {
            path: 'search/',
            lazy: async () => {
              return {
                Component: (await import('../page/Search')).default
              }
            }
          },
          {
            path: 'play/:code/',
            lazy: async () => {
              return {
                Component: (await import('../page/Play')).default
              }
            }
          },
          {
            path: 'battle/:code/',
            lazy: async () => {
              return {
                element: <AuthRequired>{(await import('../page/Battle')).default}</AuthRequired>
              }
            }
          },
          {
            path: 'my/',
            lazy: async () => {
              return {
                element: <AuthRequired>{(await import('../page/My')).default}</AuthRequired>
              }
            }
          },
          {
            path: 'about',
            // Single route in lazy file
            lazy: () => import('../page/About')
          },
          {
            path: 'login/',
            element: <>LOGIN</>
          },
          {
            path: 'register/',
            element: <>REGISTER</>
          },
          {
            path: 'forgot/',
            element: <>FORGOT</>
          }
        ]
      },
      {
        path: '*',
        element: <RedirectLayout />
      }
    ]
  }
]

export default routes
