import { useEffect, useState } from 'react'

const useScreenFixed = (init = false) => {
  const [screenFixed, setScreenFixed] = useState(init)

  useEffect(() => {
    document.body.style.overflow = screenFixed ? 'hidden' : 'unset'
  }, [screenFixed])

  return setScreenFixed
}

export default useScreenFixed
