import React from 'react'

const CurrencyIcon: React.FC<PropType> = ({ code, size = 'middle' }) => {
  const getIcon = (code: string) => {
    switch (code.toLocaleLowerCase()) {
      case 'gold':
        return require(`@/asset/image/currency/gold.png`)
      case 'diamond':
        return require(`@/asset/image/currency/diamond.png`)
      default:
        return code
    }
  }

  return (
    <img src={getIcon(code)} className={size === 'middle' ? 'h-7 w-7' : size === 'large' ? 'h-9 w-9' : 'h-6 w-6'} />
  )
}

interface PropType {
  code: 'gold' | 'GOLD' | 'diamond' | 'DIAMOND' | string
  size?: 'large' | 'middle' | 'small'
}

export default CurrencyIcon
