import React from 'react'
import { useTranslation } from 'react-i18next'

import useMediaBreakpoint from '@/hook/useMediaBreakpoint'
import LinkEnhance from '@/component/LinkEnhance'
import { PopUpStore } from '@/store/PopupStore'

import type { NavItemType } from '@/type/common'

const NavItem: React.FC<PropType> = ({ item }) => {
  const { t } = useTranslation()
  const { size } = useMediaBreakpoint()
  const { name, icon, url, active } = item

  const handleClick = () => {
    size === 'xs' && PopUpStore.setMenuPopupVisible(false)
  }

  return (
    <div onClick={handleClick}>
      <LinkEnhance className="group/item text-fontcolor-600" href={url}>
        <div
          className={`flex h-8 w-48 border-l-[6px] leading-8 ${active ? 'border-primary bg-primary-light' : 'border-transparent'}`}
        >
          <div className="flex items-center px-4">
            <img src={require(`@/asset/svg/menu/${icon}`)} className="cus_menu-icon aspect-square h-[20px]" />
          </div>
          <div className="text-nowrap text-sm font-custom-bold leading-8 transition-all duration-150 ease-in-out group-hover:visible group-hover/item:translate-x-2 group-hover/item:opacity-70 sm:invisible 2xl:visible">
            {t(`menu.${name}`)}
          </div>
        </div>
      </LinkEnhance>
    </div>
  )
}

interface PropType {
  item: NavItemType
}

export default NavItem
