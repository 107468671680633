import { Logger } from '@kulee/helper'

// 日志前缀
export const LOGGER_PREFIX = '__CLAZZ__'
// 日志输出等级
export const LOGGER_LEVEL = Logger.LEVEL.INFO

// 广告渠道编码
export const AD_CHANNEL_CODE: Record<'APPLOVIN' | 'GOOGLE_AFG', GameAdConfigNS.GameAdCode> = {
  APPLOVIN: 'applovin',
  GOOGLE_AFG: 'googleAFG'
}

// 没有自带测试模式的渠道
export const NONE_TEST_MODE_AD_CHANNEL_CODES = [AD_CHANNEL_CODE.APPLOVIN]

// google afg广告默认配置
export const DEFAULT_GOOGLE_ADCONFIG: GameAdConfigNS.GameAdConfig<GameAdConfigNS.GameAdGoogleAFGConfig> = {
  enabled: true,
  testEnabled: true,
  channel: {
    channelCode: AD_CHANNEL_CODE.GOOGLE_AFG,
    channelConfig: {
      clientId: 'ca-app-pub-123456789',
      channelId: '123456789',
      limit: 1,
      admobIntersitialSlot: 'ca-app-pub-123456789/123456789',
      admobRewardedSlot: 'ca-app-pub-123456789/123456789',
      prerollAd: {
        enabled: true
      },
      interstitialAd: {
        enabled: true,
        frequency: 30
      },
      rewardAd: {
        enabled: true,
        frequency: 0
      }
    }
  }
}

// 返回的错误码
export const RESPONSE_CODES = {
  // 广告资源未准备就绪
  AD_RESOURCE_UNREADY: 'AD/RESOURCE_UNREADY',
  // 广告关闭
  AD_CLOSED: 'AD/CLOSED',
  // 广告没有可用资源
  AD_RESOURCE_NA: 'AD/RESOURCE_NA',
  // 广告请求过于频繁
  AD_FREQUENTLY: 'AD/FREQUENTLY',
  // 未找到广告渠道
  AD_NO_CHANNEL: 'AD/NO_CHANNEL',
  // 未知错误
  AD_UNKNOWN_ERROR: 'AD/UNKNOWN_ERROR'
}
