import { CustomError } from '@kulee/helper'

import BaseService from '../../BaseService'
import logger from '../../config/logger'
import { handleError } from '../../helper/request'
import Convert from './Convert'

import type { CustomAxiosResponse } from '../../types'
import type { GameRank, GameRankConfig, GameRankData } from '@/types/rank'

class RankService extends BaseService {
  /**
   * 获取游戏排行版配置
   * @param gameId 游戏ID
   * @returns 游戏排行榜配置
   */
  public async getGameRankConfig(gameId: string): Promise<CustomAxiosResponse<GameRankConfig[]>> {
    try {
      const res = await this.request.get<unknown, CustomAxiosResponse<ServerDataNS.GameRankConfig[]>>(
        `${this.apiUrl}/v1/game/app/rank/config?gameId=${gameId}`
      )

      return {
        ...res,
        data: Convert.gameRankConfigsToClient(res.data)
      }
    } catch (error: any) {
      logger.error('get game rank config error', error)
      throw new CustomError(handleError(error))
    }
  }

  /**
   * 获取游戏排行数据
   * @param rankId 排行榜ID
   * @returns 游戏排行数据
   */
  public async getGameRank(rankId: string): Promise<CustomAxiosResponse<GameRank>> {
    try {
      const res = await this.request.get<unknown, CustomAxiosResponse<GameRank>>(
        `${this.apiUrl}/v1/game/app/rank/data?rankId=${rankId}`
      )

      return res
    } catch (error: any) {
      logger.error('get game rank error', error)
      throw new CustomError(handleError(error))
    }
  }

  /**
   * 上报排行榜数据
   * @param options 排行榜数据
   * @returns
   */
  public async reportGameRankScore(options: GameRankData): Promise<CustomAxiosResponse<null>> {
    try {
      const res = await this.request.post<unknown, CustomAxiosResponse<null>>(`${this.apiUrl}/v1/game/app/rank/data`, {
        ...options
      })

      return res
    } catch (error: any) {
      logger.error('report game rank data error', error)
      throw new CustomError(handleError(error))
    }
  }
}

export default RankService
