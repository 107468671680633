import type { ShowInterstitialAdOptions, ShowPrerollAdOptions, ShowRewardAdOptions } from './types'

class BaseChannel<T> {
  // 广告配置数据
  protected gameAdConfig!: GameAdConfigNS.GameAdConfig<T>

  public setAdConfig(gameAdConfig: GameAdConfigNS.GameAdConfig<T>) {
    this.gameAdConfig = gameAdConfig
  }

  public async init(): Promise<boolean> {
    throw new Error('kulee/ad `init` have to be implement')
  }

  public showPrerollAd(_options?: ShowPrerollAdOptions) {
    throw new Error('kulee/ad `showPrerollAd` have to be implement')
  }

  public showInterstitialAd(_options: ShowInterstitialAdOptions) {
    throw new Error('kulee/ad `showInterstitialAd` have to be implement')
  }

  public showRewardAd(_options: ShowRewardAdOptions) {
    throw new Error('kulee/ad `showRewardAd` have to be implement')
  }
}

export default BaseChannel
