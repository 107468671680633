import React from 'react'
import { twMerge } from 'tailwind-merge'

const Skeleton: React.FC<PropType> = ({ type, row = 3, className }) => {
  const baseClassName = 'bg-gray-400/20'

  const classNames = twMerge('flex flex-col gap-2', className)

  let component
  switch (type) {
    case 'title':
      component = <div className={twMerge(baseClassName, 'h-4 w-1/2 rounded-md')}></div>
      break
    case 'icon':
      component = <div className={twMerge(baseClassName, 'h-full w-full rounded-xl')}></div>
      break
    case 'paragraph':
      component = (
        <div className={classNames}>
          <div className={twMerge(baseClassName, 'h-4 w-1/2 rounded-md')}></div>
          {new Array(row).fill(null).map((item, index) => (
            <div
              key={index}
              className={twMerge(baseClassName, 'h-3 rounded-md', index === row - 1 ? 'w-2/3' : '')}
            ></div>
          ))}
        </div>
      )
      break
    default:
      component = <></>
  }

  return component
}

interface PropType {
  type: 'title' | 'icon' | 'button' | 'paragraph'
  className?: string
  row?: number
}

export default Skeleton
