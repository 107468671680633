import { CustomError } from '@kulee/helper'

import BaseService from '../../BaseService'
import Convert from './Convert'
import logger from '../../config/logger'
import { handleError } from '../../helper/request'

import type { CustomAxiosResponse } from '../../types'
import type {
  BaseVIPOrder,
  CreateVIPOrderOptions,
  GetVIPOrdersOptions,
  UserVIP,
  UserVIPBenefit,
  VIPItem,
  VIPOrder
} from '../../types/vip'

class VIPService extends BaseService {
  /**
   * 获取我的VIP信息
   * @returns 用户的VIP信息
   */
  public async getUserVIPs(): Promise<CustomAxiosResponse<UserVIP[]>> {
    try {
      const res = await this.request.get<unknown, CustomAxiosResponse<VIPDataNS.UserVIP[]>>(
        `${this.apiUrl}/v1/vip/app/vip/user`
      )

      return {
        ...res,
        data: Convert.userVIPsToClient(res.data)
      }
    } catch (error: any) {
      logger.error('get user vip', error)
      throw new CustomError(handleError(error))
    }
  }

  public async getUserVipBenefits(): Promise<CustomAxiosResponse<UserVIPBenefit[]>> {
    try {
      const res = await this.request.get<unknown, CustomAxiosResponse<VIPDataNS.UserVIPBenefit[]>>(
        `${this.apiUrl}/v1/vip/app/vip/user/interests`
      )

      return {
        ...res,
        data: Convert.userVipBenefitsToClient(res.data)
      }
    } catch (error: any) {
      logger.error('get user vip', error)
      throw new CustomError(handleError(error))
    }
  }

  public async getVIPs(): Promise<CustomAxiosResponse<VIPItem[]>> {
    try {
      const res = await this.request.get<unknown, CustomAxiosResponse<VIPDataNS.VIPItem[]>>(
        `${this.apiUrl}/v1/vip/app/vip/interests`
      )

      return {
        ...res,
        data: Convert.vipsToClient(res.data)
      }
    } catch (error: any) {
      logger.error('get vips', error)
      throw new CustomError(handleError(error))
    }
  }

  public async createVIPOrder(options: CreateVIPOrderOptions): Promise<CustomAxiosResponse<BaseVIPOrder>> {
    try {
      const res = await this.request.post<unknown, CustomAxiosResponse<VIPDataNS.CreateVIPOrderResult>>(
        `${this.apiUrl}/v1/vip/app/vip/user/order`,
        {
          ...options
        }
      )

      return {
        ...res,
        data: Convert.createVIPOrderResultToVIPOrder(res.data)
      }
    } catch (error: any) {
      logger.error('create vip order error', error)
      throw new CustomError(handleError(error))
    }
  }

  public async getVIPOrders(options: GetVIPOrdersOptions): Promise<CustomAxiosResponse<VIPOrder[]>> {
    const { status, ...rest } = options
    try {
      const res = await this.request.get<unknown, CustomAxiosResponse<VIPDataNS.VIPOrder[]>>(
        `${this.apiUrl}/v1/vip/app/vip/user/order`,
        {
          params: {
            ...rest,
            status: typeof status !== 'undefined' ? Convert.vipOrderStatusToServer(status) : undefined,
            orderByColumn: 'createTime',
            isAsc: 'desc'
          }
        }
      )

      return {
        ...res,
        data: Convert.vipOrdersToClient(res.data)
      }
    } catch (error: any) {
      logger.error('get orders', error)
      throw new CustomError(handleError(error))
    }
  }

  public async cancelVIPOrder(orderNo: string): Promise<CustomAxiosResponse<null>> {
    try {
      return await this.request.post<unknown, CustomAxiosResponse<null>>(
        `${this.apiUrl}/v1/vip/app/vip/user/order/close/${orderNo}`
      )
    } catch (error: any) {
      logger.error('cancel vip order error', error)
      throw new CustomError(handleError(error))
    }
  }

  public async deleteVIPOrder(orderNo: string): Promise<CustomAxiosResponse<null>> {
    try {
      return await this.request.delete<unknown, CustomAxiosResponse<null>>(
        `${this.apiUrl}/v1/vip/app/vip/user/order/${orderNo}`
      )
    } catch (error: any) {
      logger.error('delete vip order error', error)
      throw new CustomError(handleError(error))
    }
  }

  public async getOrderDetail(orderNo: string): Promise<CustomAxiosResponse<VIPOrder>> {
    try {
      const res = await this.request.get<unknown, CustomAxiosResponse<VIPDataNS.VIPOrder>>(
        `${this.apiUrl}/v1/vip/app/vip/user/order/${orderNo}`
      )

      return {
        ...res,
        data: Convert.vipOrderToClient(res.data)
      }
    } catch (error: any) {
      logger.error('get order detail error', error)
      throw new CustomError(handleError(error))
    }
  }
}

export default VIPService
