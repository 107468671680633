import React, { useEffect, useState } from 'react'

import useMediaBreakpoint from '@/hook/useMediaBreakpoint'
import useStatePopup from '@/hook/useStatePopup'
import { PopupSelector, PopUpStore } from '@/store/PopupStore'
import Divider from '../Divider'
import Drawer from '../Drawer'
import MenuList from './MenuList'
import NavFooter from './NavFooter'
import TagList from './TagList'
const Nav: React.FC<PropType> = () => {
  const smallSizes = ['sm', 'md', 'lg', 'xl']
  const { size, overSize } = useMediaBreakpoint()
  const menuVisible = useStatePopup(PopupSelector.menuPopupVisible)
  const [width, setWidth] = useState(smallSizes.indexOf(size) >= 0 ? 64 : 192)

  const rootClassName = size === 'xs' ? 'top-0' : 'top-14'

  const handleClose = () => {
    PopUpStore.setMenuPopupVisible(false)
  }

  const handleMouseOver = () => {
    if (smallSizes.indexOf(size) >= 0) {
      setWidth(192)
    }
  }
  const handleMouseOut = () => {
    if (smallSizes.indexOf(size) >= 0) {
      setWidth(64)
    }
  }

  useEffect(() => {
    setWidth(smallSizes.indexOf(size) >= 0 ? 64 : 192)

    if (overSize('sm')) {
      PopUpStore.setMenuPopupVisible(true)
    }
  }, [size])

  return (
    <Drawer
      visible={menuVisible}
      onClose={handleClose}
      placement="left"
      width={width}
      zIndex={size === 'xs' ? 101 : 99}
      closable={size === 'xs' ? true : false}
      mask={size === 'xs' ? true : false}
      lockScroll={false}
      className="group bg-primary-extralight"
      rootClassName={rootClassName}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <MenuList />
      <Divider />
      <TagList />
      <Divider />
      <NavFooter />
    </Drawer>
  )
}

interface PropType {}

export default React.memo(Nav)
