import React from 'react'
import { twMerge } from 'tailwind-merge'
import CurrencyIcon from '../CurrencyIcon'

const Award: React.FC<PropType> = ({ items = [], dir = 'row', size = 'small', className }) => {
  const classNames = twMerge('flex flex-wrap gap-x-8', className)

  return (
    <div className={classNames}>
      {items.map(item => (
        <div key={item.code} className={`flex items-center gap-sm text-nowrap ${dir === 'col' && 'flex-col'}`}>
          <CurrencyIcon code={item.code} size={size} />
          <span className={`font-custom-bold ${size === 'large' ? 'text-xl' : 'text-lg'}`}>x {item.count}</span>
        </div>
      ))}
    </div>
  )
}

interface PropType {
  items?: {
    title: string
    code: string
    count: number
  }[]
  dir?: 'row' | 'col'
  size?: 'small' | 'middle' | 'large'
  className?: string
}

export default Award
