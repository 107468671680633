import React, { useEffect, useState } from 'react'

import { timeRemaining } from '@/helper'

const TimeRemainning: React.FC<PropType> = ({ timestamp, expireSeconds, onComplete }) => {
  const secondsRemainning = timeRemaining(timestamp, expireSeconds)

  const [minutes, setMinutes] = useState(Math.floor(secondsRemainning / 60))
  const [seconds, setSeconds] = useState(secondsRemainning % 60)

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      } else {
        if (minutes === 0) {
          clearInterval(interval)
          onComplete && onComplete()
        } else {
          setMinutes(minutes - 1)
          setSeconds(59)
        }
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [minutes, seconds])

  return (
    <>
      {String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
    </>
  )
}

interface PropType {
  timestamp: number
  expireSeconds: number
  onComplete?: () => void
}

export default TimeRemainning
