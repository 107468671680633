import { useEffect } from 'react'
import { useLocation, useMatch } from 'react-router-dom'

import { getClient } from '@/helper'

const useRuntime = (): Runtime => {
  const match = useMatch('/:lang/:channel/*')

  const { search } = useLocation()
  const params = match?.params

  return {
    lang: params?.lang || 'en',
    channel: params?.channel || '',
    client: getClient(),
    path: params ? params['*'] || '' : '',
    query: new URLSearchParams(search),
    search
  }
}

interface Runtime {
  lang: string
  channel: string
  client: 'mobile' | 'pc'
  path: string
  query: URLSearchParams
  search: string
}

export default useRuntime
