import LocalStorage from './LocalStorage'

class TimeRecord {
  // 键值前缀
  private static KEY_PREFIX = '__kulee'

  /**
   * 添加记录
   * @param key 记录的键值
   */
  public static add(key: string, justRecord = false) {
    const keyWithPrefix = this.getKey(key)

    const record = LocalStorage.getItem<Record>(keyWithPrefix) || {
      time: 0,
      records: []
    }

    const dateNow = Date.now()
    record.time = justRecord ? record.time : dateNow
    record.records.unshift(dateNow)
    if (record.records.length > 20) {
      record.records.pop()
    }

    LocalStorage.setItem(keyWithPrefix, record)
  }

  /**
   * 移除记录
   * @param key 记录的键值
   */
  public static remove(key: string) {
    const keyWithPrefix = this.getKey(key)
    const item = LocalStorage.getItem(keyWithPrefix)

    if (item) {
      LocalStorage.removeItem(keyWithPrefix)
    }
  }

  /**
   * 获取剩余过期秒数
   * @param key 记录的键值
   * @param expireTime 过期时间，单位秒
   * @returns 过期秒数
   */
  public static getRestTime(key: string, expireTime: number): number {
    const keyWithPrefix = this.getKey(key)

    const record = LocalStorage.getItem<Record>(keyWithPrefix)
    if (record) {
      const time = record.time + expireTime * 1000 - new Date().getTime()
      return time <= 0 ? 0 : Math.ceil(time / 1000)
    } else {
      return 0
    }
  }

  public static getTotalRecord(key: string, startTime: number, endTime: number): number {
    const keyWithPrefix = this.getKey(key)

    const record = LocalStorage.getItem<Record>(keyWithPrefix)
    if (record) {
      return record.records.filter(item => item > startTime && item <= endTime).length
    } else {
      return 0
    }
  }

  /**
   * 键值添加前缀
   * @param key 记录的键值
   * @returns 添加了键值的键值
   */
  private static getKey(key: string) {
    return `${this.KEY_PREFIX}_${key}`
  }
}

interface Record {
  time: number
  records: number[]
}

export default TimeRecord
