import ENV from './ENV'

/**
 * 获取api地址
 * @param env 环境
 * @returns api地址
 */
function getBaseHost(env: string): string {
  const protocol = window.location.protocol

  if (env === ENV.DEVELOPMENT) {
    return `//tgadev.just4fun.asia`
  }
  if (env === ENV.TEST) {
    return `https://data.just4fun.sg`
  }
  if (env === ENV.PRODUCTION) {
    return `https://tga.just4fun.sg`
  }
  if (env === ENV.BIP_TEST) {
    return `${protocol}//prpbipgaming.tvoip.turkcell.com.tr`
  }
  if (env === ENV.BIP || env === 'bip-production') {
    return `${protocol}//bipgaming.bip.com`
    //return `${protocol}//bipgaming.bip.com`
    return ``
  }
  if (env === ENV.METAONE) {
    return `${protocol}//mgapi.metaone.gg`
  }

  return `${protocol}//data.just4fun.sg`
}

/**
 * 获取cdn地址
 * @param env 环境
 * @returns cdn地址
 */
function getCDNHost(env: string): string {
  if (env === 'metaone') {
    return `https://mgcdn.metaone.gg`
  }

  return `https://cdn.j4fgame.com`
}

function getMetaoneUrl(env: string, state: string): string {
  if (env === ENV.DEVELOPMENT) {
    return `https://tst.metaone.gg/metaone-auth/oauth/authorize?response_type=code&client_id=avSVfLtp&redirect_uri=${encodeURIComponent(
      getBaseHost(env) + '/mobile-web'
    )}&state=${state}`
  }
  if (env === ENV.TEST) {
    return `https://tst.metaone.gg/metaone-auth/oauth/authorize?response_type=code&client_id=avSVfLtp&redirect_uri=${encodeURIComponent(
      getBaseHost(env) + '/mobile-web'
    )}&state=${state}`
  }
  if (env === ENV.PRODUCTION) {
    return `https://metaone.gg/metaone-auth/oauth/authorize?response_type=code&client_id=avSVfLtp&redirect_uri=${encodeURIComponent(
      'https://metaone.j4fgame.com/mobile-web'
    )}&state=${state}`
  }

  return `https://tst.metaone.gg/metaone-auth/oauth/authorize?response_type=code&client_id=avSVfLtp&redirect_uri=${encodeURIComponent(
    getBaseHost(env) + '/mobile-web'
  )}&state=${state}`
}

function getMetaoneAuthUrl(env: string, url: string, state: string): string {
  const protocol = window.location.href.indexOf('https://') === 0 ? 'https' : 'http'
  const host = window.location.host

  if (env === ENV.DEVELOPMENT) {
    return `https://tst.metaone.gg/metaone-auth/oauth/authorize?response_type=code&client_id=avSVfLtp&redirect_uri=${encodeURIComponent(
      protocol + '://' + host + '/' + url
    )}&state=${state}`
  }
  if (env === ENV.TEST) {
    return `https://tst.metaone.gg/metaone-auth/oauth/authorize?response_type=code&client_id=avSVfLtp&redirect_uri=${encodeURIComponent(
      protocol + '://' + host + '/' + url
    )}&state=${state}`
  }
  if (env === ENV.PRODUCTION || env === ENV.METAONE) {
    return `https://metaone.gg/metaone-auth/oauth/authorize?response_type=code&client_id=avSVfLtp&redirect_uri=${encodeURIComponent(
      protocol + '://' + host + '/' + url
    )}&state=${state}`
  }

  return `https://tst.metaone.gg/metaone-auth/oauth/authorize?response_type=code&client_id=avSVfLtp&redirect_uri=${encodeURIComponent(
    protocol + '://' + host + '/' + url
  )}&state=${state}`
}

export { getBaseHost, getCDNHost, getMetaoneAuthUrl, getMetaoneUrl }
