const RESPONSE_CODES = {
  // ---通用模块---
  // 成功
  SUCCESS: 'SUCCESS',
  // 参数错误
  PARAMETER_INVALID: 'PARAMETER_INVALID',
  // 服务端错误
  SERVER_ERROR: 'SERVER_ERROR',
  // 网络异常
  NETWORK_ERROR: 'NETWORK_ERROR',
  // 未知错误
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',

  // ---用户中心模块---
  // 应用禁用用户中心
  UC_DISABLED: 'UC/DISABLED',
  // 权限不足
  UC_PERMISSION_INVALID: 'UC/PERMISSION_INVALID',
  // 用户不存在
  UC_USER_NOT_EXIST: 'UC/USER_NOT_EXIST',
  // 密码不正确
  UC_PASSWORD_INVALID: 'UC/PASSWORD_INVALID',

  // 以下可能未使用
  // 无效用户认证
  UC_AUTH_INVALID: 'UC/AUTH_INVALID',
  // 登录失败
  UC_LOGIN_FAILED: 'UC/LOGIN_FAILED',
  // 获取用户签到状态失败
  UC_GET_SIGNIN_STATUS_FAILED: 'UC/GET_SIGNIN_STATUS_FAILED',
  // 获取用户签到状态
  UC_GET_USER_INFO_FAILED: 'UC/GET_USER_INFO_FAILED',
  UC_GET_OPEN_ID_FAILED: 'UC/GET_OPEN_ID_FAILED',
  UC_GET_USER_SIGN_STATUS_FAILED: 'UC/GET_USER_SIGN_STATUS_FAILED',
  UC_USER_ACTIVE_REPORT_FAILED: 'UC/USER_ACTIVE_REPORT_FAILED',
  UC_USER_SIGN_FAILED: 'UC/USER_SIGN_FAILED',
  UC_GET_USER_SIGN_REWARDS_FAILED: 'UC/GET_USER_SIGN_REWARDS_FAILED',
  UC_GET_TASKS_FAILED: 'UC/GET_TASKS_FAILED',
  UC_GET_ACHIEVEMENTS_FAILED: 'UC/GET_ACHIEVEMENTS_FAILED',
  // getReward
  UC_GET_REWARD_FAILED: 'UC/GET_REWARD_FAILED',
  UC_GET_USER_VIP_FAILED: 'UC/GET_USER_VIP_FAILED',
  UC_GET_TGAAPP_ACCOUNT_FAILED: 'UC/GET_TGAAPP_ACCOUNT_FAILED',
  UC_GET_USER_EXT_FAILED: 'UC/GET_USER_EXT_FAILED',
  UC_BIND_CODE_FAILED: 'UC/BIND_CODE_FAILED',
  UC_LOGIN_CODE_FAILED: 'UC/LOGIN_CODE_FAILED',
  UC_GET_CODE_FAILED: 'UC/GET_CODE_FAILED',
  UC_TP_LOGIN_BY_CODE_FAILED: 'UC/TP_LOGIN_BY_CODE_FAILED',
  UC_GET_EMAIL_CODE_FAILED: 'UC/GET_EMAIL_CODE_FAILED',
  UC_REGISTER_FAILED: 'UC/REGISTER_FAILED',
  UC_UPDATE_USER_INFO_FAILED: 'UC/UPDATE_USER_INFO_FAILED',
  UC_RESET_PASSWORD_FAILED: 'UC/RESET_PASSWORD_FAILED',

  // ---钱包模块---
  WALLET_PAY_FAILED: 'WALLET/PAY_FAILED',

  // ---排行榜模块---
  RANK_SCORE_OVER_LIMIT: 'RANK/SCORE_OVER_LIMIT',
  RANK_GET_RANK_LIST: 'RANK/GET_RANK_LIST',

  // ---游戏房间模块---
  // 创建或者加入房间错误
  ROOM_JOIN_OR_CREATE_ERROR: 'ROOM/JOIN_OR_CREATE_ERROR',
  // 用户房间不存在
  ROOM_USER_ROOM_NOT_EXIST: 'ROOM/USER_ROOM_NOT_EXIST',
  // 连接websocket失败
  ROOM_WEBSOCKET_UNAVAILABLE: 'ROOM/WEBSOCKET_UNAVALILABLE',

  // ---广告模块 ---
  // 广告资源未准备就绪
  AD_RESOURCE_UNREADY: 'AD/RESOURCE_UNREADY',
  // 广告关闭
  AD_CLOSED: 'AD/CLOSED',
  // 广告没有可用资源
  AD_RESOURCE_NA: 'AD/RESOURCE_NA',
  // 广告请求过于频繁
  AD_FREQUENTLY: 'AD/FREQUENTLY',
  // 未找到广告渠道
  AD_NO_CHANNEL: 'AD/NO_CHANNEL',
  // 未知错误
  AD_UNKNOWN_ERROR: 'AD/UNKNOWN_ERROR',

  // ---游戏模块---
  GAME_GET_GAME_LIST_FAILED: 'GAME/GET_GAME_LIST_FAILED',
  GAME_GET_GAME_INFO_FAILED: 'GAME/GET_GAME_INFO_FAILED',
  GAME_SEARCH_GAME_FAILED: 'GAME/SEARCH_GAME_FAILED',
  GAME_GET_TAGS_GAME_LIST_FAILED: 'GAME/GET_TAGS_GAME_LIST_FAILED',

  // ---payment模块---
  // 获取金币和砖石
  PAYMENT_GET_COIN_DIAMOND_FAILED: 'PAYMENT/GET_COIN_DIAMOND_FAILED',
  PAYMENT_GET_TRADE_RECORD_FAILED: 'PAYMENT/GET_TRADE_RECORD_FAILED',
  PAYMENT_GET_APP_COMMODITY_FAILED: 'PAYMENT/GET_APP_COMMODITY_FAILED',
  PAYMENT_CREATE_ORDER_FAILED: 'PAYMENT/CREATE_ORDER_FAILED',
  PAYMENT_CREATE_EXTERNAL_ORDER_FAILED: 'PAYMENT/CREATE_EXTERNAL_ORDER_FAILED',
  PAYMENT_GET_APP_COMMODITY_ORDERS_FAILED: 'PAYMENT/GET_APP_COMMODITY_ORDERS_FAILED',
  PAYMENT_GET_APP_PAYMENT_CHANNEL_FAILED: 'PAYMENT/GET_APP_PAYMENT_CHANNEL_FAILED',
  PAYMENT_GET_WITHDRAW_ORDER_FAILED: 'PAYMENT/GET_WITHDRAW_ORDER_FAILED',

  // ---对战游戏模块---
  PKGAME_GET_CONFIG_FAILED: 'PKGAME/GET_CONFIG_FAILED',
  PKGAME_MATCH_FAILED: 'PKGAME/MATCH_FAILED',
  PKGAME_WEBSOCKET_UNAVALILABLE: 'PKGAME/WEBSOCKET_UNAVALILABLE',
  PAYMENT_GET_PAYMENT_RESULT_FAILED: 'PAYMENT/GET_PAYMENT_RESULT_FAILED',

  // vip模块
  VIP_GET_VIP_SINGLE_FAILED: 'VIP/GET_VIP_SINGLE_FAILED',
  VIP_GET_VIP_LIST_FAILED: 'VIP/GET_VIP_LIST_FAILED',
  VIP_CREATE_VIP_ORDER_FAILED: 'VIP/CREATE_VIP_ORDER_FAILED',

  // 成就
  ACHIEVEMENT_GET_ACHIEVEMENT_LIST_FAILED: 'ACHIEVEMENT/GET_ACHIEVEMENT_LIST_FAILED',
  ACHIEVEMENT_RECEIVE_ACHIEVEMENT_FAILED: 'ACHIEVEMENT/RECEIVE_ACHIEVEMENT_FAILED',
  ACHIEVEMENT_GET_ACHIEVEMENT_COUNT_FAILED: 'ACHIEVEMENT/GET_ACHIEVEMENT_COUNT_FAILED'
}

export default RESPONSE_CODES
