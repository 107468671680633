import React, { useEffect } from 'react'
import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom'

// import useMediaBreakpoint from '@/hook/useMediaBreakpoint'
import Header from '../Header'
import Nav from '../Nav'
import useStatePopup from '@/hook/useStatePopup'
import { PopupSelector } from '@/store/PopupStore'
import useThemeBasis from '@/hook/useThemeBasis'
import EditProfilePopup from '../popup/EditProfilePopup'
import EditAvatarPopup from '../popup/EditAvatarPopup'
import EditNicknamePopup from '../popup/EditNicknamePopup'
import EditCoverPopup from '../popup/EditCoverPopup'
import CheckinPopup from '../popup/CheckinPopup'
import TaskPopup from '../popup/TaskPopup'
import AchievementPopup from '../popup/AchievementPopup'
import BillingPopup from '../popup/BillingPopup'
import TopUpGoldPopup from '../popup/TopUpGoldPopup'
import TopUpDiamondPopup from '../popup/TopUpDiamondPopup'
import VIPPopup from '../popup/VIPPopup'
import VIPOrderPopup from '../popup/VIPOrderPopup'
import VIPOrderDetailPopup from '../popup/VIPOrderDetailPopup'
import VIPBenefitPopup from '../popup/VIPBenefitPopup'
import PayPopup from '../popup/PayPopup'
import SharePopup from '../popup/SharePopup'
import { KULEWEBVIEW_IOS } from '@/config/constant'

const MainLayout: React.FC<PropType> = () => {
  // const navigation = useNavigation()
  const { pathname } = useLocation()
  // const { size } = useMediaBreakpoint()
  const menuPopupVisible = useStatePopup(PopupSelector.menuPopupVisible)
  const { lightHeader } = useThemeBasis()

  // TODO 处理页面跳转时Loading UI
  // console.log('state:::', navigation.state)

  // useEffect(() => {
  //   // size === 'xs' && setMenu(false)
  // }, [pathname])

  const className = lightHeader
    ? 'bg-background text-fontcolor-500 !border-gray-300/80'
    : 'bg-primary text-primary-text'

  return (
    <>
      <div className="flex flex-col">
        <header className={`cus_header fixed left-0 top-0 z-[100] h-14 w-full ${className}`}>
          <Header />
        </header>
        <main className={`h-[1px] min-h-screen pt-14 ${menuPopupVisible ? 'sm:pl-16 2xl:pl-48' : ''}`}>
          <Outlet />
        </main>
      </div>
      <ScrollRestoration />
      <Nav />
      <EditProfilePopup />
      <EditAvatarPopup />
      <EditNicknamePopup />
      <EditCoverPopup />
      <CheckinPopup />
      <TaskPopup />
      <AchievementPopup />
      <BillingPopup />
      <TopUpGoldPopup />
      <TopUpDiamondPopup />
      <VIPPopup />
      <VIPOrderPopup />
      <VIPOrderDetailPopup />
      <VIPBenefitPopup />
      <PayPopup />
      <SharePopup />
    </>
  )
}

interface PropType {}

export default MainLayout
