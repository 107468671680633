import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@/component/Button'
import { ReactComponent as OKSVG } from '@/asset/svg/ok.svg'
import { ReactComponent as CloseSVG } from '@/asset/svg/close.svg'
import { PopUpStore } from '@/store/PopupStore'
import type { BussnessOrderType, Payment } from '@kulee/tga-sdk/types/types/wallet'

const PayResult: React.FC<PropType> = ({ type, order, payment }) => {
  const { t } = useTranslation('wallet')

  const handleBackClick = () => {
    PopUpStore.setPayPopup({
      visible: false
    })
  }

  return (
    <div className="flex h-full flex-col items-center justify-between">
      <div>
        <div
          className={`mx-auto mb-sm flex h-20 w-20 items-center justify-center rounded-full ${type === 'success' ? 'bg-semantic-success' : 'bg-semantic-error'}`}
        >
          {type === 'success' ? (
            <OKSVG className="aspect-square w-16 fill-white" />
          ) : (
            <CloseSVG className="aspect-square w-16 fill-white" />
          )}
        </div>
        <p className="text-lg">{type === 'success' ? t('tip.paySuccess') : t('tip.payFail')}</p>
      </div>
      <div className="min-w-80 rounded-xl bg-primary/30 px-md py-sm">
        <p className="flex gap-sm">
          <label className="w-20 text-right">{t('title.orderNo')}</label>
          <span>{order?.payOrderNo}</span>
        </p>
        <p className="flex gap-sm">
          <label className="w-32 text-right">{t('title.payMethod')}</label>
          <span>{payment?.name}</span>
        </p>
      </div>
      <Button type="primary" className="w-full" onClick={handleBackClick}>
        {t('btn.back')}
      </Button>
    </div>
  )
}

interface PropType {
  type: 'success' | 'fail'
  order?: BussnessOrderType
  payment?: Payment
}

export default PayResult
