import React from 'react'

import { useAchievements } from '@/hook/data/useGrowth'
import Loading from '@/component/Loading'
import Empty from '@/component/Empty'
import AchievementItem from '../AchievementItem'

const AchievementMain: React.FC<PropType> = () => {
  const { data: achievements } = useAchievements({ refetchOnMount: 'always' })

  if (!achievements) {
    return <Loading className="h-40" />
  }

  if (achievements.length <= 0) {
    return <Empty />
  }

  return (
    <div className="grid grid-cols-2 gap-sm">
      {achievements.map(item => (
        <AchievementItem key={item.id} item={item} />
      ))}
    </div>
  )
}

interface PropType {}

export default AchievementMain
