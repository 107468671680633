import React, { useEffect, useState } from 'react'

import TGASDK from '@/config/TGASDK'
import Loading from '@/component/Loading'
import SearchGameItem from '../SearchGameItem'
import LinkEnhance from '@/component/LinkEnhance'

import type { BaseGameType } from '@kulee/tga-sdk/types/types/game'

// TODO： PC中键盘上下键监听，移动结果选择
const SearchResult: React.FC<PropType> = ({ keyword }) => {
  const [games, setGames] = useState<BaseGameType[]>()

  const search = async () => {
    if (!keyword) {
      return
    }

    const { data } = await TGASDK.module.Game.service.searchGames({
      key: keyword
    })
    setGames(data)
  }

  useEffect(() => {
    setGames(undefined)
    search()
  }, [keyword])

  if (!games) {
    return <Loading className="h-24" fill="text-primary-text" />
  }

  return (
    <div className="h-full">
      <div className="max-h-[calc(100%-3.5rem)] overflow-hidden sm:max-h-96 sm:overflow-y-auto">
        {games.map(game => {
          return <SearchGameItem key={game.code} game={game} />
        })}
      </div>
      <div className="h-10 text-center leading-10">
        <LinkEnhance className="text-primary-text text-base" href={`/search?keyword=${keyword}`}>
          Search All
        </LinkEnhance>
      </div>
    </div>
  )
}

interface PropType {
  keyword?: string
}

export default SearchResult
