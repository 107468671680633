import logger from '../../config/logger'
import type { CurrencyCode, OrderExt } from '../../types/wallet'
import type {
  BaseVIPOrder,
  UserVIP,
  UserVIPBenefit,
  VIPBenefit,
  VIPCommodity,
  VIPItem,
  VIPOrder
} from '../../types/vip'
import type { OrderStatus } from '../../types'

class Convert {
  public static userVIPToClient(userVIP: VIPDataNS.UserVIP): UserVIP {
    const { interestsName: name, ...rest } = userVIP

    return {
      ...rest,
      name
    }
  }

  public static userVIPsToClient(userVIPs: VIPDataNS.UserVIP[]): UserVIP[] {
    return userVIPs.map(item => this.userVIPToClient(item))
  }

  public static vipToClient(vip: VIPDataNS.VIPItem): VIPItem {
    const { vipConfigVos, interestsConfig, ...rest } = vip

    return {
      ...rest,
      commodities: this.vipCommoditiesToClient(vipConfigVos),
      benefits: this.vipBenefitsToClient(interestsConfig)
    }
  }

  public static vipsToClient(vips: VIPDataNS.VIPItem[]): VIPItem[] {
    return vips.map(item => this.vipToClient(item))
  }

  public static vipCommodityToClient(vipCommodity: VIPDataNS.VIPCommodity): VIPCommodity {
    const {
      salePrice: price,
      originalPrice: priceOriginal,
      currencyCode,
      prefix: payCurrencyPrefix,
      remark: desc,
      ...rest
    } = vipCommodity

    return {
      ...rest,
      price,
      priceOriginal,
      payCurrency: currencyCode as CurrencyCode,
      payCurrencyPrefix,
      desc
    }
  }

  public static vipCommoditiesToClient(vipCommodities: VIPDataNS.VIPCommodity[]): VIPCommodity[] {
    return vipCommodities.map(item => this.vipCommodityToClient(item))
  }

  public static vipBenefitToClient(vipBenefit: VIPDataNS.VIPBenefit): VIPBenefit {
    const { description: desc, ...rest } = vipBenefit

    return { ...rest, desc }
  }

  public static vipBenefitsToClient(vipBenefits: VIPDataNS.VIPBenefit[]): VIPBenefit[] {
    return vipBenefits.map(item => this.vipBenefitToClient(item))
  }

  public static userVIPBenefitToClient(userVIPBenefit: VIPDataNS.UserVIPBenefit): UserVIPBenefit {
    const { description: desc, ...rest } = userVIPBenefit

    return {
      ...rest,
      desc
    }
  }

  public static userVipBenefitsToClient(userVIPBenefits: VIPDataNS.UserVIPBenefit[]): UserVIPBenefit[] {
    return userVIPBenefits.map(item => this.userVIPBenefitToClient(item))
  }

  public static createVIPOrderResultToVIPOrder(order: VIPDataNS.CreateVIPOrderResult): BaseVIPOrder {
    const { description: desc, externalParam, ...rest } = order

    let ext = {}

    try {
      ext = JSON.parse(externalParam)
    } catch (error) {
      logger.warn('parse order ext error', error)
    }

    return {
      ...rest,
      status: 'pending',
      ext: ext as OrderExt,
      desc
    }
  }

  public static vipOrderToClient(order: VIPDataNS.VIPOrder): VIPOrder {
    const { price: amount, status, interestsName: vipName, vipConfigName: vipCommodityName, ...rest } = order

    return {
      ...rest,
      amount,
      status: this.vipOrderStatusToClient(status),
      vipName,
      vipCommodityName,
      desc: ''
    }
  }

  public static vipOrdersToClient(orders: VIPDataNS.VIPOrder[]): VIPOrder[] {
    return orders.map(item => this.vipOrderToClient(item))
  }

  public static vipOrderStatusToServer(status: OrderStatus): VIPDataNS.VIPOrderStatus {
    return status === 'canceled'
      ? -1
      : status === 'pending' || status === 'paid'
      ? 0
      : status === 'completed'
      ? 1
      : status
  }

  private static vipOrderStatusToClient(status: VIPDataNS.VIPOrderStatus): OrderStatus {
    return status === -1 ? 'canceled' : status === 0 ? 'pending' : status === 1 ? 'completed' : status
  }
}

export default Convert
