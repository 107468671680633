import React, { useContext } from 'react'
import { twMerge } from 'tailwind-merge'

import VIPContext from '@/context/VIPContext'
import vipIcon from '@/asset/image/vip.png'

const VIPIcon: React.FC<PropType> = ({ size, onClick }) => {
  const { status, userVIPs } = useContext(VIPContext)

  if (!window.AppConfig.data.feature.vip.enabled) {
    return null
  }

  const classNames = twMerge(
    'aspect-[3/1]',
    size === 'large' ? 'w-12' : size === 'small' ? 'w-8' : 'w-10',
    status === 'unready' || userVIPs.length <= 0 ? 'grayscale' : ''
  )
  const icon = <img className={classNames} src={vipIcon} />

  return (
    <>
      {onClick ? (
        <button className="block" onClick={onClick}>
          {icon}
        </button>
      ) : (
        icon
      )}
    </>
  )
}

interface PropType {
  size?: 'large' | 'middle' | 'small'
  onClick?: () => void
}

export default VIPIcon
