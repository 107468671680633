class Logger {
  public static LEVEL = {
    NONE: 0,
    ERROR: 1,
    WARN: 2,
    INFO: 3,
    DEBUG: 4
  }

  private prefix: string
  private level: number

  constructor(prefix = 'unknown', level: number = Logger.LEVEL.DEBUG) {
    this.prefix = prefix
    this.level = level
  }

  /**
   * 打印错误日志
   * @param optionalParams 日志参数
   */
  public error(message: any = '', ...optionalParams: any[]) {
    if (this.isLevelVisible(Logger.LEVEL.ERROR)) {
      console.error.apply(null, [`${this.getRealPrefix()} ${message}`].concat(optionalParams))
    }
  }

  /**
   * 打印警告日志
   * @param optionalParams 日志参数
   */
  public warn(message?: any, ...optionalParams: any[]) {
    if (this.isLevelVisible(Logger.LEVEL.WARN)) {
      console.warn.apply(null, [`${this.getRealPrefix()} ${message}`].concat(optionalParams))
    }
  }

  /**
   * 打印普通日志
   * @param optionalParams 日志参数
   */
  public info(message?: any, ...optionalParams: any[]) {
    if (this.isLevelVisible(Logger.LEVEL.INFO)) {
      console.info.apply(null, [`${this.getRealPrefix()} ${message}`].concat(optionalParams))
    }
  }

  /**
   * 打印普通日志
   * @param optionalParams 日志参数
   */

  public log(message?: any, ...optionalParams: any[]) {
    if (this.isLevelVisible(Logger.LEVEL.INFO)) {
      console.log.apply(null, [`${this.getRealPrefix()} ${message}`].concat(optionalParams))
    }
  }

  /**
   * 打印debug日志
   * @param optionalParams 日志参数
   */
  public debug(message?: any, ...optionalParams: any[]) {
    if (this.isLevelVisible(Logger.LEVEL.DEBUG)) {
      console.debug.apply(null, [`${this.getRealPrefix()} ${message}`].concat(optionalParams))
    }
  }

  private getRealPrefix() {
    return `[${this.prefix}]`
  }

  private isLevelVisible(level: number) {
    return level <= this.level
  }
}

export default Logger
