import React from 'react'
import { useTranslation } from 'react-i18next'

import type { UserVIPBenefit } from '@kulee/tga-sdk/types/types/vip'

const VIPBenefitItem: React.FC<PropType> = ({ item }) => {
  const { t } = useTranslation('vip')

  const { name, totalCount, usedCount, desc, effectTime, expireTime } = item

  return (
    <div className="mb-sm flex flex-col gap-sm overflow-hidden rounded-lg bg-white p-md text-[#002231]">
      <h4 className="text-base font-semibold">{name}</h4>
      <div className="text-xs">{desc}</div>
      {totalCount > 0 && (
        <div className="text-xs">
          {t('tip.benefitUsage')} {usedCount}/{totalCount}
        </div>
      )}
      <div className="text-xs text-gray-400">
        {t('tip.expireTime')}
        {effectTime} - {expireTime}
      </div>
    </div>
  )
}

interface PropType {
  item: UserVIPBenefit
}

export default VIPBenefitItem
