import { TimeRecord } from '@kulee/helper'

import logger from './logger'
import { AD_CHANNEL_CODE, RESPONSE_CODES } from './constant'
import type BaseChannel from './BaseChannel'
import type { ShowInterstitialAdOptions, ShowPrerollAdOptions, ShowRewardAdOptions } from './types'

class KuleAd<T = GameAdConfigNS.AllowGameAdConfig> {
  public __version = '__VERSION__'

  private interstitialAdFrequencyKey = 'TGA-AD-I-F'
  private rewardAdFrequencyKey = 'TGA-AD-R-F'

  // 初始化完成标识
  private inited = false
  private channel: BaseChannel<T>
  private gameAdConfig: GameAdConfigNS.GameAdConfig<T>

  private maxDayTimes = 7
  private maxChannelCodes = [AD_CHANNEL_CODE.GOOGLE_AFG]

  constructor(options: { channel: BaseChannel<T>; gameAdConfig: GameAdConfigNS.GameAdConfig<T> }) {
    const { channel, gameAdConfig } = options

    this.channel = channel
    this.gameAdConfig = gameAdConfig

    this.channel.setAdConfig(this.gameAdConfig)
  }

  public async init(): Promise<boolean> {
    const { enabled, free = false } = this.gameAdConfig

    if (free) {
      return (this.inited = true)
    }

    if (!enabled) {
      logger.warn('ad is not enabled')
      return false
    }

    return (this.inited = await this.channel.init())
  }

  public showPrerollAd(options: ShowPrerollAdOptions = {}) {
    if (!this.inited) {
      logger.warn('ad is not inited')
      return
    }

    const { onPlay = () => {}, onError = () => {}, onFinish = () => {} } = options

    const config = (this.gameAdConfig.channel.channelConfig as GameAdConfigNS.AllowGameAdConfig).prerollAd

    // 广告配置为关闭处理
    if (!config.enabled) {
      onError({
        code: RESPONSE_CODES.AD_CLOSED,
        message: 'Ad closed.'
      })
      onFinish()
      return
    }

    // 处理免广告
    if (this.gameAdConfig.free) {
      onFinish()

      return
    }

    // 处理当天广告次数
    if (
      this.maxChannelCodes.indexOf(this.gameAdConfig.channel.channelCode) >= 0 &&
      TimeRecord.getTotalRecord(this.interstitialAdFrequencyKey, this.getTodayTimestamp(), this.getNextDayTimestamp()) >
        this.maxDayTimes
    ) {
      onFinish()

      return
    }

    return this.channel.showPrerollAd({
      ...options,
      onPlay: () => {
        TimeRecord.add(this.interstitialAdFrequencyKey, true)
        onPlay()
      }
    })
  }

  public showInterstitialAd(options: ShowInterstitialAdOptions) {
    if (!this.inited) {
      logger.warn('ad is not inited')
      return
    }
    const { onPlay = () => {}, onError = () => {}, onFinish = () => {} } = options

    const config = (this.gameAdConfig.channel.channelConfig as GameAdConfigNS.AllowGameAdConfig).interstitialAd

    // 广告配置为关闭处理
    if (!config.enabled) {
      onError({
        code: RESPONSE_CODES.AD_CLOSED,
        message: 'Ad closed.'
      })
      onFinish()
      return
    }

    // 处理免广告
    if (this.gameAdConfig.free) {
      onFinish()

      return
    }

    // 处理当天广告次数
    if (
      this.maxChannelCodes.indexOf(this.gameAdConfig.channel.channelCode) >= 0 &&
      TimeRecord.getTotalRecord(this.interstitialAdFrequencyKey, this.getTodayTimestamp(), this.getNextDayTimestamp()) >
        this.maxDayTimes
    ) {
      onFinish()

      return
    }

    // 广告间隔处理
    if (config.frequency) {
      const restTime = TimeRecord.getRestTime(this.interstitialAdFrequencyKey, config.frequency)
      if (restTime > 0) {
        onError({
          code: RESPONSE_CODES.AD_FREQUENTLY,
          message: 'ad frequency reaches the upper limit.',
          payload: restTime
        })
        onFinish()
        return
      }
    }

    return this.channel.showInterstitialAd({
      ...options,
      onPlay: () => {
        TimeRecord.add(this.interstitialAdFrequencyKey)
        onPlay()
      }
    })
  }

  public showRewardAd(options: ShowRewardAdOptions) {
    if (!this.inited) {
      logger.warn('ad is not inited')
      return
    }

    const { onPlay = () => {}, onReward = () => {}, onError = () => {}, onFinish = () => {} } = options
    const config = (this.gameAdConfig.channel.channelConfig as GameAdConfigNS.AllowGameAdConfig).rewardAd

    // 广告配置为关闭处理
    if (!config.enabled) {
      onError({
        code: RESPONSE_CODES.AD_CLOSED,
        message: 'Ad closed.'
      })
      onFinish()
      return
    }

    // 处理免广告
    if (this.gameAdConfig.free) {
      onReward()
      onFinish()

      return
    }

    // 广告间隔处理
    if (config.frequency) {
      const restTime = TimeRecord.getRestTime(this.rewardAdFrequencyKey, config.frequency)
      if (restTime > 0) {
        onError({
          code: RESPONSE_CODES.AD_FREQUENTLY,
          message: 'ad frequency reaches the upper limit.',
          payload: restTime
        })
        onFinish()
        return
      }
    }

    return this.channel.showRewardAd({
      ...options,
      onPlay: () => {
        TimeRecord.add(this.rewardAdFrequencyKey)
        onPlay()
      }
    })
  }

  private getTodayTimestamp() {
    const now = new Date()
    now.setHours(0, 0, 0, 0)

    return now.getTime()
  }

  private getNextDayTimestamp() {
    const now = new Date()
    now.setHours(0, 0, 0, 0)
    now.setDate(now.getDate() + 1)

    return now.getTime()
  }
}

export default KuleAd
