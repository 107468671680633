import React from 'react'

import useStatePopup from '@/hook/useStatePopup'
import { PopupSelector } from '@/store/PopupStore'
import useShare from '@/hook/useShare'

import type { ShareChannel } from '@/type/share'

// TODO 添加其他第三方分享
const ShareMain: React.FC<PropType> = () => {
  const { params, onSuccess, onFail } = useStatePopup(PopupSelector.sharePopup)
  const { channels, share } = useShare()
  const { appIcon, appName } = window.AppConfig.data

  const handleShare = async (channel: ShareChannel) => {
    if (!params) {
      return
    }

    try {
      await share(channel, params)
      onSuccess && onSuccess()
    } catch (error) {
      onFail && onFail()
    }
  }

  return (
    <div className="flex gap-md">
      {channels.map(channel =>
        channel === 'inApp' ? (
          <button
            className="block w-14"
            onClick={() => {
              handleShare(channel)
            }}
          >
            <img src={appIcon} />
            <div className="text-xs">{appName}</div>
          </button>
        ) : (
          ''
        )
      )}
    </div>
  )
}

interface PropType {}

export default ShareMain
