import TGASDK from './TGASDK'
import type { Context } from './types'

class BaseModule {
  public static clazz = 'UNSET'

  public context: Context
  public SDK: typeof TGASDK

  constructor(context: Context) {
    this.context = context
    this.SDK = TGASDK
  }

  public run(): void {
    throw new Error(`you have to implement method of 'run'`)
  }
}

export default BaseModule
