import { CustomError } from '@kulee/helper'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { Md5 } from 'ts-md5'

import BaseService from '../../BaseService'
import logger from '../../config/logger'
import { handleError } from '../../helper/request'

import type { CustomAxiosResponse } from '../../types'
import type {
  LoginOptions,
  LoginWithFpIdOptions,
  RegisterOptions,
  ResetPasswordOptions,
  SendEmailCodeOptions,
  Token,
  UpdatePasswordOptions,
  UpdateUserInfoOptions,
  UserInfo
} from '../../types/uc'

class UCService extends BaseService {
  /**
   * 指纹ID登录
   * @param options
   * @param options.fpId fpId
   * @param options.nickname 登录时直接修改的昵称
   * @param options.avatar 登录时直接修改的头像
   * @returns 用户Token
   */
  public async loginWithFpId(options: LoginWithFpIdOptions = {}): Promise<CustomAxiosResponse<Token>> {
    const { fpId: fpIdT, nickname, avatar } = options
    const fpId = fpIdT || (await this.createFpId())

    try {
      const res = await this.request.post<unknown, CustomAxiosResponse<Token>>(`${this.apiUrl}/v1/user-center/login`, {
        appId: this.appId,
        fpId,
        nickname,
        avatar,
        type: 4,
        timestamp: new Date().getTime()
      })

      return res
    } catch (error: any) {
      logger.error('login error', error)
      throw new CustomError(handleError(error))
    }
  }

  /**
   * 常规登录，用户名密码登录
   * @param options
   * @param options.username 用户名
   * @param options.password 密码
   * @returns 用户Token
   */
  public async login(options: LoginOptions): Promise<CustomAxiosResponse<Token>> {
    const { username, password } = options

    try {
      const res = await this.request.post<unknown, CustomAxiosResponse<Token>>(`${this.apiUrl}/v1/user-center/login`, {
        appId: this.appId,
        username,
        password: Md5.hashStr(password),
        type: 1,
        timestamp: new Date().getTime()
      })

      return res
    } catch (error: any) {
      logger.error('login', error)
      throw new CustomError(handleError(error))
    }
  }

  /**
   * 获取邮件验证码
   * @param options 参数
   * @param options.email 邮箱
   * @param options.grantType 类型
   * @returns 验证码
   */
  public async sendEmailCode(options: SendEmailCodeOptions): Promise<CustomAxiosResponse<null>> {
    try {
      const { email, grantType } = options

      const res = await this.request.get<unknown, CustomAxiosResponse<null>>(
        `${this.apiUrl}/v1/user-center/verify-code/email?email=${email}&grantType=${grantType}`
      )

      return res
    } catch (error: any) {
      logger.error('getEmailCode', error)
      throw new CustomError(handleError(error))
    }
  }

  /**
   * 用户注册
   * @param options 用户注册参数
   * @param options.username 用户名
   * @param options.password 密码
   * @param options.code 验证码
   * @returns 用户Token
   */
  public async register(options: RegisterOptions): Promise<CustomAxiosResponse<Token>> {
    const { username, password, code } = options

    try {
      const res = await this.request.post<unknown, CustomAxiosResponse<Token>>(
        `${this.apiUrl}/v1/user-center/register`,
        {
          appId: this.appId,
          account: username,
          password: Md5.hashStr(password),
          code
        }
      )

      return res
    } catch (error: any) {
      logger.error('register', error)
      throw new CustomError(handleError(error))
    }
  }

  /**
   * 获取用户信息
   * @returns 用户信息
   */
  // public async getUserInfo(): Promise<CustomAxiosResponse<UserInfo>> {
  //   try {
  //     const res = await this.request.get<unknown, CustomAxiosResponse<UserInfoResponse>>(
  //       `${this.apiUrl}/v1/user-center/user-info`
  //     )
  //     const {
  //       data: { id: userId, nickname, avatar, userType, sex }
  //     } = res
  //     return {
  //       ...res,
  //       data: {
  //         userId,
  //         nickname,
  //         avatar,
  //         userType,
  //         sex
  //       }
  //     }
  //   } catch (error: any) {
  //     logger.error(`get user information error`, error)
  //     throw new CustomError(handleError(error))
  //   }
  // }

  /**
   * 获取完整用户信息
   * @returns 完整用户信息
   */
  public async getUserInfo(): Promise<CustomAxiosResponse<UserInfo>> {
    try {
      const res = await this.request.get<unknown, CustomAxiosResponse<FullUserInfoResponse>>(
        `${this.apiUrl}/v1/user-center/user-info/full`
      )
      const {
        data: {
          id: userId,
          nickname,
          avatar,
          userType,
          sex,
          extInfo: { cover, signature }
        }
      } = res

      return {
        ...res,
        data: {
          userId,
          nickname,
          avatar,
          userType,
          sex,
          signature: signature ? signature.value : undefined,
          cover: cover ? cover.value : undefined
        }
      }
    } catch (error: any) {
      logger.error(`get full user information error`, error)
      throw new CustomError(handleError(error))
    }
  }

  /**
   * 根据邮箱验证码重置密码（找回密码）
   * @param options 参数
   * @param options.email 邮箱
   * @param options.code 验证码
   * @param options.password 新密码
   * @returns
   */
  public async resetPassword(options: ResetPasswordOptions): Promise<CustomAxiosResponse<string>> {
    try {
      const { email, code, password } = options
      const res = await this.request.post<unknown, CustomAxiosResponse<string>>(
        `${this.apiUrl}/v1/user-center/password/reset`,
        {
          appId: this.appId,
          email,
          verifyCode: code,
          password: Md5.hashStr(password)
        }
      )

      return res
    } catch (error: any) {
      logger.error('resetPassword', error)
      throw new CustomError(handleError(error))
    }
  }

  /**
   * 根据原密码修改密码
   * @param options 参数
   * @returns 用户信息
   */
  public async updatePassword(options: UpdatePasswordOptions): Promise<CustomAxiosResponse<UserInfo>> {
    try {
      const res = await this.request.put<unknown, CustomAxiosResponse<UserInfoResponse>>(
        `${this.apiUrl}/v1/user-center/password/`,
        {
          oldPassword: options.oldPassword,
          newPassword: options.newPassword,
          verifyCode: options.code,
          type: 0
        }
      )

      return {
        ...res,
        data: {
          ...res.data,
          userId: res.data.id
        }
      }
    } catch (error: any) {
      logger.error('update password error', error)
      throw new CustomError(handleError(error))
    }
  }

  /**
   * 修改用户信息
   * @param options 修改用户信息参数
   * @param options.userId 要修改的用户ID
   * @param options.nickname 新的用户昵称
   * @param options.avatar 新的用户头像
   * @returns 新的用户信息
   */
  public async updateUserInfo(options: UpdateUserInfoOptions): Promise<CustomAxiosResponse<UserInfo>> {
    try {
      const res = await this.request.put<unknown, CustomAxiosResponse<UserInfoResponse>>(
        `${this.apiUrl}/v1/user-center/user-info/`,
        {
          nickname: options.nickname,
          avatar: options.avatar
        }
      )

      return {
        ...res,
        data: {
          ...res.data,
          userId: res.data.id
        }
      }
    } catch (error: any) {
      logger.error('updateUserInfo', error)
      throw new CustomError(handleError(error))
    }
  }

  /**
   * 更新用户扩展信息
   * @param options 扩展信息键值
   */
  public async updateUserExtInfo(options: {
    key: string
    value: Record<string, string>
  }): Promise<CustomAxiosResponse<null>> {
    try {
      return this.request.put<unknown, CustomAxiosResponse<null>>(
        `${this.apiUrl}/v1/user-center/user-info/ext`,
        options
      )
    } catch (error: any) {
      logger.error('updateUserExtInfo', error)
      throw new CustomError(handleError(error))
    }
  }

  /**
   * 创建FPID
   * @returns FPID
   */
  private async createFpId(): Promise<string> {
    const fp = await FingerprintJS.load()
    return (await fp.get()).visitorId
  }
}

// 获取用户信息服务端返回类型
interface UserInfoResponse {
  appId: string
  id: string
  avatar: string
  nickname: string
  txnId: string
  userType: 'VISITOR' | any
  sex: 0 | 1
}

type FullUserInfoResponse = UserInfoResponse & {
  extInfo: {
    cover?: {
      value: string
    }
    signature?: {
      value: string
    }
  }
}

export default UCService
