import EventBus from '@/config/EventBus'
import { EVENT_NAMESPACE } from '@/config/constant'
import type { ShareParams } from '@/type/share'
import type { BussnessOrderType, BaseCommodityType } from '@kulee/tga-sdk/types/types/wallet'

// Store
export class PopUpStore {
  static menuPopupVisible: boolean = false
  static setMenuPopupVisible = (value: boolean) => {
    this.menuPopupVisible = value
    EventBus.dispatch(EVENT_NAMESPACE.POPUP)
  }

  static editProfilePopupVisible: boolean = false
  static setEditProfilePopupVisible = (value: boolean) => {
    this.editProfilePopupVisible = value
    EventBus.dispatch(EVENT_NAMESPACE.POPUP)
  }

  static editAvatarPopupVisible: boolean = false
  static setEditAvatarPopupVisible = (value: boolean) => {
    this.editAvatarPopupVisible = value
    EventBus.dispatch(EVENT_NAMESPACE.POPUP)
  }

  static editNicknamePopupVisible: boolean = false
  static setEditNicknamePopupVisible = (value: boolean) => {
    this.editNicknamePopupVisible = value
    EventBus.dispatch(EVENT_NAMESPACE.POPUP)
  }

  static editCoverPopupVisible: boolean = false
  static setEditCoverPopupVisible = (value: boolean) => {
    this.editCoverPopupVisible = value
    EventBus.dispatch(EVENT_NAMESPACE.POPUP)
  }

  static checkinPopupVisible: boolean = false
  static setCheckinPopupVisible = (value: boolean) => {
    this.checkinPopupVisible = value
    EventBus.dispatch(EVENT_NAMESPACE.POPUP)
  }

  static taskPopupVisible: boolean = false
  static setTaskPopupVisible = (value: boolean) => {
    this.taskPopupVisible = value
    EventBus.dispatch(EVENT_NAMESPACE.POPUP)
  }

  static achievementPopupVisible: boolean = false
  static setAchievementPopupVisible = (value: boolean) => {
    this.achievementPopupVisible = value
    EventBus.dispatch(EVENT_NAMESPACE.POPUP)
  }

  static billingPopup: BillingPopupState = {
    visible: false
  }
  static setBillingPopup = (state: BillingPopupState) => {
    this.billingPopup = state
    EventBus.dispatch(EVENT_NAMESPACE.POPUP)
  }

  static topUpGoldPopupVisible: boolean = false
  static setTopUpGoldPopupVisible = (value: boolean) => {
    this.topUpGoldPopupVisible = value
    EventBus.dispatch(EVENT_NAMESPACE.POPUP)
  }

  static topUpDiamondPopupVisible: boolean = false
  static setTopUpDiamondPopupVisible = (value: boolean) => {
    this.topUpDiamondPopupVisible = value
    EventBus.dispatch(EVENT_NAMESPACE.POPUP)
  }

  static vipPopupVisible: boolean = false
  static setVIPPopupVisible = (value: boolean) => {
    this.vipPopupVisible = value
    EventBus.dispatch(EVENT_NAMESPACE.POPUP)
  }

  static vipOrderPopupVisible: boolean = false
  static setVIPOrderPopupVisible = (value: boolean) => {
    this.vipOrderPopupVisible = value
    EventBus.dispatch(EVENT_NAMESPACE.POPUP)
  }

  static vipOrderDetailPopup: VIPOrderDetailPopupState = {
    visible: false
  }
  static setVIPOrderDetailPopup = (state: VIPOrderDetailPopupState) => {
    this.vipOrderDetailPopup = state
    EventBus.dispatch(EVENT_NAMESPACE.POPUP)
  }

  static vipBenefitPopupVisible: boolean = false
  static setVIPBenefitPopupVisible = (value: boolean) => {
    this.vipBenefitPopupVisible = value
    EventBus.dispatch(EVENT_NAMESPACE.POPUP)
  }

  static payPopup: PayPopupState = {
    visible: false,
    commodity: undefined,
    createOrder: undefined
  }
  static setPayPopup = (state: PayPopupState) => {
    this.payPopup = state
    EventBus.dispatch(EVENT_NAMESPACE.POPUP)
  }

  static sharePopup: SharePopupState = {
    visible: false
  }
  static setSharePopup = (state: SharePopupState) => {
    this.sharePopup = state
    EventBus.dispatch(EVENT_NAMESPACE.POPUP)
  }
}

// selector
export class PopupSelector {
  static menuPopupVisible: SelectorType<boolean> = (store: typeof PopUpStore) => store.menuPopupVisible
  static editProfilePopupVisible: SelectorType<boolean> = (store: typeof PopUpStore) => store.editProfilePopupVisible
  static editAvatarPopupVisible: SelectorType<boolean> = (store: typeof PopUpStore) => store.editAvatarPopupVisible
  static editNicknamePopupVisible: SelectorType<boolean> = (store: typeof PopUpStore) => store.editNicknamePopupVisible
  static editCoverPopupVisible: SelectorType<boolean> = (store: typeof PopUpStore) => store.editCoverPopupVisible
  static checkinPopupVisible: SelectorType<boolean> = (store: typeof PopUpStore) => store.checkinPopupVisible
  static taskPopupVisible: SelectorType<boolean> = (store: typeof PopUpStore) => store.taskPopupVisible
  static achievementPopupVisible: SelectorType<boolean> = (store: typeof PopUpStore) => store.achievementPopupVisible
  static billingPopup: SelectorType<BillingPopupState> = (store: typeof PopUpStore) => store.billingPopup
  static topUpGoldPopupVisible: SelectorType<boolean> = (store: typeof PopUpStore) => store.topUpGoldPopupVisible
  static topUpDiamondPopupVisible: SelectorType<boolean> = (store: typeof PopUpStore) => store.topUpDiamondPopupVisible
  static vipPopupVisible: SelectorType<boolean> = (store: typeof PopUpStore) => store.vipPopupVisible
  static vipOrderPopupVisible: SelectorType<boolean> = (store: typeof PopUpStore) => store.vipOrderPopupVisible
  static vipOrderDetailPopup: SelectorType<VIPOrderDetailPopupState> = (store: typeof PopUpStore) =>
    store.vipOrderDetailPopup
  static vipBenefitPopupVisible: SelectorType<boolean> = (store: typeof PopUpStore) => store.vipBenefitPopupVisible
  static payPopup: SelectorType<PayPopupState> = (store: typeof PopUpStore) => store.payPopup
  static sharePopup: SelectorType<SharePopupState> = (store: typeof PopUpStore) => store.sharePopup
}

type SelectorType<T> = (store: typeof PopUpStore) => T

interface PayPopupState {
  visible: boolean
  payOrderNo?: string
  commodity?: BaseCommodityType
  createOrder?: (paymentCode: string) => Promise<BussnessOrderType>
  onSuccess?: (bunessOrder: BussnessOrderType) => void
  onError?: (error: Error) => void
}

interface BillingPopupState {
  visible: boolean
  active?: 'GOLD' | 'DIAMOND'
}

interface VIPOrderDetailPopupState {
  visible: boolean
  orderNo?: string
}

interface SharePopupState {
  visible: boolean
  params?: ShareParams | undefined
  onSuccess?: () => void | undefined
  onFail?: () => void | undefined
}
