class LocalStorage {
  private static store = window.localStorage

  public static clear() {
    return this.store.clear()
  }

  public static getItem<T = unknown>(key: string): T | null {
    const value = this.store.getItem(key)
    if (value === null) {
      return null
    }

    try {
      return JSON.parse(value)
    } catch (error) {
      return value as T
    }
  }

  public static setItem(key: string, value: string | any) {
    try {
      this.store.setItem(key, JSON.stringify(value))
    } catch (error) {
      this.store.setItem(key, value)
    }
  }

  public static removeItem(key: string) {
    return this.store.removeItem(key)
  }

  public static key(index: number): string | null {
    return this.store.key(index)
  }
}

export default LocalStorage
