import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { VIP } from '@kulee/tga-sdk'

import TGASDK from '@/config/TGASDK'
import { east8TimestampToDateTime } from '@/helper'
import Button from '@/component/Button'
import TimeRemainning from '@/component/TimeRemaining'
import Modal from '@/component/Modal'
import type { VIPOrder } from '@kulee/tga-sdk/types/types/vip'
import { PopUpStore } from '@/store/PopupStore'
import ArrowIcon from '@/component/Icon/Arrow'

const VIPOrderItem: React.FC<PropType> = ({ item, onRemove, onCancel, onPay }) => {
  const { t } = useTranslation('vip')
  const [cancelStatus, setCancelStatus] = useState<'none' | 'confirming' | 'pedding' | 'completed'>('none')
  const [deleteStatus, setDeleteStatus] = useState<'none' | 'confirming' | 'pedding' | 'completed'>('none')
  const { orderNo, vipName, vipCommodityName, createTime, expireDay, amount, status, payOrderNo } = item

  const handleTimeComplete = () => {
    onCancel && onCancel(item)
  }

  const handleDetailClick = () => {
    PopUpStore.setVIPOrderDetailPopup({
      visible: true,
      orderNo: item.orderNo
    })
  }

  const handleModalCancel = () => {
    setCancelStatus('none')
    setDeleteStatus('none')
  }

  const handleCancelModalOk = async () => {
    setCancelStatus('pedding')

    try {
      await TGASDK.module.VIP.service.cancelVIPOrder(orderNo)

      setCancelStatus('completed')
      onCancel && onCancel(item)
    } catch (error) {}
  }

  const handleDeleteModalOk = async () => {
    setDeleteStatus('pedding')

    try {
      await TGASDK.module.VIP.service.deleteVIPOrder(orderNo)

      setDeleteStatus('completed')
      onRemove && onRemove(item)
    } catch (error) {}
  }

  const handleCancelOrder = async () => {
    setCancelStatus('confirming')
  }

  const handleDelOrder = () => {
    setDeleteStatus('confirming')
  }

  const handlePayOrder = () => {
    PopUpStore.setPayPopup({
      visible: true,
      payOrderNo,
      onSuccess: () => {
        onPay && onPay(item)
      }
    })
  }

  useEffect(() => {
    TGASDK.addModule(VIP)
  }, [])

  return (
    <>
      <div className={`mb-md overflow-hidden rounded-xl bg-white`}>
        <div
          className={`relative aspect-[350/112] w-full cursor-pointer overflow-hidden bg-vip-order bg-contain p-md text-yellow-950 ${status === 'canceled' && 'grayscale'}`}
          onClick={handleDetailClick}
        >
          <h4 className="mb-1 text-lg font-semibold text-yellow-950">
            {vipCommodityName} {vipName}
          </h4>
          <div className="mb-4 text-xs">{east8TimestampToDateTime(createTime)}</div>
          <div>
            {t('tip.expireAt')}
            {t('tip.expireDay', { count: expireDay })}
          </div>
          <div className="absolute right-0 top-0 rounded-bl-lg bg-gradient-to-r from-[#fcf2c6] to-[#f9e397] px-sm py-1 text-xs">
            {t(`status.${status}`)}
          </div>
          <div className="absolute right-sm top-1/2 rotate-180">
            <ArrowIcon />
          </div>
        </div>
        <div className="flex items-center justify-between px-md py-sm">
          <div className="text-base">
            {t('tip.priceTotal')} {amount}
          </div>
          <div className="flex gap-sm">
            {status === 'pending' ? (
              <>
                <Button type="default" size="small" loading={cancelStatus === 'pedding'} onClick={handleCancelOrder}>
                  {t('btn.cancelOrder')}
                </Button>
                <Button type="primary" size="small" className="flex gap-1" onClick={handlePayOrder}>
                  <span>{t('btn.payOrder')}</span>
                  <span className="block w-9 text-center">
                    <TimeRemainning timestamp={createTime} expireSeconds={15 * 60} onComplete={handleTimeComplete} />
                  </span>
                </Button>
              </>
            ) : (
              <Button type="default" size="small" loading={deleteStatus === 'pedding'} onClick={handleDelOrder}>
                {t('btn.deleteOrder')}
              </Button>
            )}
          </div>
        </div>
      </div>
      {cancelStatus === 'confirming' && (
        <Modal
          visible={true}
          title={t('title.cancelOrder')}
          okText={t('btn.yes')}
          cancelText={t('btn.no')}
          onOk={handleCancelModalOk}
          onCancel={handleModalCancel}
        >
          {t('tip.cancelOrder')}
        </Modal>
      )}
      {deleteStatus === 'confirming' && (
        <Modal
          visible={true}
          title={t('title.deleteOrder')}
          okText={t('btn.yes')}
          cancelText={t('btn.no')}
          onOk={handleDeleteModalOk}
          onCancel={handleModalCancel}
        >
          {t('tip.deleteOrder')}
        </Modal>
      )}
    </>
  )
}

interface PropType {
  item: VIPOrder
  onRemove?: (item: VIPOrder) => void
  onCancel?: (item: VIPOrder) => void
  onPay?: (item: VIPOrder) => void
}

export default VIPOrderItem
