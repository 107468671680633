import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

import { CurrencyCode } from '@kulee/tga-sdk/types/types/wallet'

const Price: React.FC<PropType> = ({
  currencyCode,
  currencyPrefix,
  price,
  type = 'normal',
  size = 'middle',
  className
}) => {
  const { t } = useTranslation('wallet')

  const classNames = twMerge(
    'w-full text-center',
    size === 'large' ? 'text-3xl font-custom-bold' : size === 'small' ? 'text-sm' : 'text-base',
    className
  )
  const priceClassNames = twMerge()
  const unitClassNames = twMerge(type === 'stress' ? (size === 'large' ? 'text-base' : 'text-xs') : '')

  const priceNode = <span className={priceClassNames}>{price}</span>
  const unitNode = <span className={unitClassNames}>{currencyPrefix}</span>

  return (
    <div className={classNames}>
      <div className={`inline-flex items-baseline ${type === 'delete' && 'line-through'}`}>
        {['GOLD', 'DIAMOND'].indexOf(currencyCode) >= 0 ? (
          <Trans
            t={t}
            i18nKey={`currencyUnit.${(currencyCode as string).toLowerCase()}`}
            count={price}
            components={[priceNode, unitNode]}
          ></Trans>
        ) : (
          <>
            {unitNode}
            {priceNode}
          </>
        )}
      </div>
    </div>
  )
}

interface PropType {
  currencyCode: CurrencyCode
  currencyPrefix: string
  price: number
  type?: 'normal' | 'stress' | 'delete'
  size?: 'small' | 'middle' | 'large'
  className?: string
}

export default Price
