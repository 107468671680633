import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import AuthContext from '@/context/AuthContext'
import VIPContext from '@/context/VIPContext'
import { PopUpStore } from '@/store/PopupStore'
import Avatar from '@/component/Avatar'
import vipBg from '@/asset/image/vip_bg.png'
import VIPIcon from '@/component/VIPIcon'
import VIPSeller from '../VIPSeller'
import WhiteCard from '@/component/card/WhiteCard'
import Button from '@/component/Button'

const VIPMamin: React.FC<PropType> = () => {
  const { t } = useTranslation('vip')
  const { user } = useContext(AuthContext)
  const { userVIPs } = useContext(VIPContext)

  const handleMyOrderClick = () => {
    PopUpStore.setVIPOrderPopupVisible(true)
  }

  const handleMyBenefitClick = () => {
    PopUpStore.setVIPBenefitPopupVisible(true)
  }

  return (
    <div className="flex h-full flex-col overflow-hidden">
      <div className="relative flex aspect-[640/297] w-full flex-shrink-0 flex-grow-0 items-end overflow-hidden text-[#002231]">
        <div className="absolute left-0 top-0 h-full w-full">
          <img src={vipBg} />
        </div>
        <div className="relative mb-7 flex h-12 items-center gap-sm px-md">
          <div className="h-full">
            <Avatar />
          </div>
          <div>
            <div className="flex items-center gap-sm">
              <div className="text-base font-custom-bold">{user?.nickname}</div>
              <div>
                <VIPIcon />
              </div>
            </div>
            {userVIPs.length > 0 && (
              <div className="text-xs">
                <label>{t('tip.expireTime')} </label>
                {userVIPs[0].expireTime}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="overflow-y-auto p-md">
        <VIPSeller />
        <WhiteCard title={t('title.moreService')}>
          <Button size="small" className="mr-sm" onClick={handleMyOrderClick}>
            {t('btn.myOrder')}
          </Button>
          <Button size="small" onClick={handleMyBenefitClick}>
            {t('btn.myBenefit')}
          </Button>
        </WhiteCard>
      </div>
    </div>
  )
}

interface PropType {}

export default VIPMamin
