import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'

i18n.use(Backend).use(initReactI18next)

const i18nInit = async (lang: string) => {
  return i18n.init({
    debug: true,
    lng: lang,
    fallbackLng: lang,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      addPath: '/locales/{{lng}}/{{ns}}.json'
    },
    load: 'languageOnly'
  })
}

export { i18nInit }

export default i18n
