import React from 'react'

const WhiteCard: React.FC<PropType> = ({ title, children }) => {
  return (
    <section className="rounded-lg bg-white p-md text-[#002231]">
      {title && <h3 className="mb-sm">{title}</h3>}
      {children}
    </section>
  )
}

interface PropType {
  title?: string
  children: React.ReactNode
}

export default WhiteCard
