import React from 'react'
import { twMerge } from 'tailwind-merge'

const Divider: React.FC<PropType> = ({ className }) => {
  const classNames = twMerge('h-[1px] py-3', className)

  return (
    <div className={classNames}>
      <span className="bg-fontcolor-300 block h-[1px] w-full"></span>
    </div>
  )
}

interface PropType {
  className?: string
}

export default Divider
