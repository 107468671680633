class CustomError extends Error {
  public code: string
  public payload: any

  constructor(config: CustomErrorConfig) {
    const { code, message = '', payload = {} } = config

    super(message)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomError)
    }

    Object.setPrototypeOf(this, CustomError.prototype)

    this.code = code || 'UNKNOWN_ERROR'
    this.payload = payload

    const errorPayload = payload
    if (errorPayload && errorPayload.stateCode) {
      if (errorPayload.stateCode === -1) {
        this.code = 'AUTH_INVALID'
        this.message = 'Authoriztion Invalid'
      }

      if (errorPayload.stateCode === -5) {
        this.code = 'SERVER_ERROR'
        this.message = 'Server Error'
      }
    }

    this.name = 'TGA PLATFORM | ' + this.code
  }

  static throwError(error: Error | CustomErrorConfig) {
    throw error instanceof Error ? error : new CustomError(error as CustomErrorConfig)
  }
}

interface CustomErrorConfig {
  code: string
  message?: string
  payload?: any
}

export default CustomError
