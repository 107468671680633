const RESPONSE_CODES_FOR_GAME = {
  // ---通用模块---
  // 成功
  SUCCESS: 1000,
  // 参数错误
  PARAMETER_INVALID: 2001,
  // 未知错误
  UNKNOWN_ERROR: 3000,

  // ---用户中心模块---
  // 应用禁用用户中心
  UC_DISABLED: 3002,
  // 登录失败
  UC_LOGIN_FAILED: 3003,
  UC_GET_USER_INFO_FAILED: 3004,

  // 以下可能未使用
  // ---用户中心模块---
  // 无效用户认证
  AUTH_INVALID: 3001,
  // 权限不足
  // PERMISSION_INVALID: 'PERMISSION_INVALID',
  // 登录失败
  // LOGIN_FAILED: 'LOGIN_FAILED',

  // ---排行榜模块---
  RANK_SCORE_OVER_LIMIT: 3101

  // ---游戏房间模块---
  // 创建或者加入房间错误
  // ROOM_JOIN_OR_CREATE_ERROR: 'ROOM/JOIN_OR_CREATE_ERROR',
  // 用户房间不存在
  // ROOM_USER_ROOM_NOT_EXIST: 'ROOM/USER_ROOM_NOT_EXIST',
  // 连接websocket失败
  // ROOM_WEBSOCKET_UNAVAILABLE: 'ROOM/WEBSOCKET_UNAVALILABLE'
}

export default RESPONSE_CODES_FOR_GAME
