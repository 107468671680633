import React from 'react'

import type { Payment } from '@kulee/tga-sdk/types/types/wallet'

const PayChannelItem: React.FC<PropType> = ({ item, checked = false, onChange }) => {
  const { code, name, icon } = item

  const handleChange = () => {
    onChange && onChange(item)
  }

  return (
    <label className="flex h-9 cursor-pointer items-center gap-sm">
      <div className="h-full">
        <img src={icon} className="h-full" />
      </div>
      <div className="flex-grow text-lg">{name}</div>
      <div>
        <input
          type="radio"
          name="paychannel"
          value={code}
          checked={checked}
          onChange={handleChange}
          className="aspect-square w-5"
        />
      </div>
    </label>
  )
}

interface PropType {
  item: Payment
  checked?: boolean
  onChange?: (item: Payment) => void
}

export default PayChannelItem
