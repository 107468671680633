import { CustomError } from '@kulee/helper'

import BaseService from '../../BaseService'
import { handleError } from '../../helper/request'
import logger from '../../config/logger'
import Convert from './Convert'

import type { CustomAxiosResponse } from '../../types'
import type {
  Wallet,
  ChargeCommodity,
  GetBillingOptions,
  GetChargeCommoditiesOptions,
  WalletBilling,
  CreateChargeOrderOptions,
  ChargeOrder,
  GetPaymentsOptions,
  Payment,
  PayResult,
  PayOrderType
} from '../../types/wallet'

class WalletServer extends BaseService {
  /**
   * 获取用户钱包信息
   * @returns 用户钱包信息
   */
  public async getWallet(): Promise<CustomAxiosResponse<Wallet>> {
    try {
      const res = await this.request.get<unknown, CustomAxiosResponse<WalletDataNS.Wallet>>(
        `${this.apiUrl}/v1/pay/app/accounts`
      )

      return {
        ...res,
        data: Convert.walletToClient(res.data)
      }
    } catch (error: any) {
      logger.error(`get user wallet error`, error)
      throw new CustomError(handleError(error))
    }
  }

  /**
   * 获取钱包流水
   * @param options 参数
   * @returns 流水
   */
  public async getBilling(options: GetBillingOptions = {}): Promise<CustomAxiosResponse<WalletBilling[]>> {
    try {
      const { type, ...rest } = options
      const res = await this.request.get<unknown, CustomAxiosResponse<WalletDataNS.WalletBilling[]>>(
        `${this.apiUrl}/v1/pay/app/account/trade/record`,
        {
          params: {
            ...rest,
            type: type === 'in' ? 1 : type === 'out' ? 2 : undefined,
            orderByColumn: 'createTime',
            isAsc: 'desc'
          }
        }
      )

      return {
        ...res,
        data: Convert.billingsToClient(res.data)
      }
    } catch (error: any) {
      logger.error(`get billing error`, error)
      throw new CustomError(handleError(error))
    }
  }

  /**
   * 获取钱包商品列表
   * @param options 参数
   * @returns 商品列表
   */
  public async getChargeCommodities(
    options: GetChargeCommoditiesOptions
  ): Promise<CustomAxiosResponse<ChargeCommodity[]>> {
    const { targetCurrency, payCurrency, price, quantity, ...rest } = options
    try {
      const res = await this.request.get<unknown, CustomAxiosResponse<WalletDataNS.ChargeCommodity[]>>(
        `${this.apiUrl}/v1/pay/app/commodity`,
        {
          params: {
            currencyCode: targetCurrency,
            paymentCurrencyCode: payCurrency,
            price,
            quantity,
            orderByColumn: 'quantity',
            ...rest
          }
        }
      )

      return {
        ...res,
        data: Convert.chargeCommoditiesToClient(res.data)
      }
    } catch (error: any) {
      logger.error(`get commodities error`, error)
      throw new CustomError(handleError(error))
    }
  }

  /**
   * 获取支付渠道
   * @param options 参数
   * @returns 支付渠道列表
   */
  public async getPayments(options: GetPaymentsOptions): Promise<CustomAxiosResponse<Payment[]>> {
    const { currencyCode, platform } = options
    try {
      return this.request.get<unknown, CustomAxiosResponse<Payment[]>>(`${this.apiUrl}/v1/pay/app/payment/channel`, {
        params: {
          currencyCode,
          platformCode: platform
        }
      })
    } catch (error: any) {
      logger.error(`get payments error`, error)
      throw new CustomError(handleError(error))
    }
  }

  /**
   * 创建充值定单
   * @param options 参数
   * @returns 充值订单
   */
  public async createChargeOrder(options: CreateChargeOrderOptions): Promise<CustomAxiosResponse<ChargeOrder>> {
    const { commodityId, autoPay = false, quantity, paymentCode } = options

    try {
      const res = await this.request.post<unknown, CustomAxiosResponse<WalletDataNS.CreateChargeOrderResult>>(
        `${this.apiUrl}/v1/pay/app/commodity/orders`,
        {
          commodityId,
          quantity,
          paymentCode,
          autoPay: autoPay ? 1 : 0
        }
      )

      return {
        ...res,
        data: Convert.createChargeOrderResultToChargeOrder(res.data)
      }
    } catch (error: any) {
      logger.error(`create charge order error`, error)
      throw new CustomError(handleError(error))
    }
  }

  public async getChargeOrderDetail(orderNo: string): Promise<CustomAxiosResponse<ChargeOrder>> {
    try {
      const res = await this.request.get<unknown, CustomAxiosResponse<WalletDataNS.ChargeOrder>>(
        `${this.apiUrl}/v1/pay/app/commodity/orders/${orderNo}`
      )

      return {
        ...res,
        data: Convert.ChargeOrderToClient(res.data)
      }
    } catch (error: any) {
      logger.error('get charge order detail error', error)
      throw new CustomError(handleError(error))
    }
  }

  public async getPayOrderInfo(payOrderNo: string): Promise<CustomAxiosResponse<PayOrderType>> {
    try {
      const res = await this.request.get<unknown, CustomAxiosResponse<WalletDataNS.PayOrderType>>(
        `${this.apiUrl}/v1/pay/app/payment/orders/${payOrderNo}`
      )

      return {
        ...res,
        data: Convert.payOrderToClient(res.data)
      }
    } catch (error: any) {
      logger.error(`get payments error`, error)
      throw new CustomError(handleError(error))
    }
  }

  public async pay(payOrderNo: string): Promise<CustomAxiosResponse<PayResult>> {
    try {
      const res = await this.request.post<unknown, CustomAxiosResponse<WalletDataNS.PayResult>>(
        `${this.apiUrl}/v1/pay/app/payment/orders/confirm`,
        {
          payOrderNo
        }
      )

      const { orderNo, amount, description: desc, payStatus } = res.data

      return {
        ...res,
        data: {
          payOrderNo: orderNo,
          amount,
          desc,
          status: Convert.payStatusToClient(payStatus)
        }
      }
    } catch (error: any) {
      logger.error(`pay error`, error)
      throw new CustomError(handleError(error))
    }
  }
}

export default WalletServer
