function run() {
  return {
    language: navigator.language.toLowerCase(),
    version: (function () {
      const u = navigator.userAgent

      return {
        mobile:
          !!u.match(/AppleWebKit.*Mobile.*/) ||
          (!!u.match(/AppleWebKit/) && u.indexOf('QIHU') && u.indexOf('QIHU') > -1 && u.indexOf('Chrome') < 0),
        android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1,
        ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
        iPad: u.indexOf('iPad') > -1,
        kuleWebview: u.indexOf('KuleWebview') > -1
      }
    })()
  }
}

export default /* #__PURE__ */ run()
