import React from 'react'
import { useTranslation } from 'react-i18next'

import { twMerge } from 'tailwind-merge'

import { ReactComponent as EmptySVG } from '@/asset/svg/empty.svg'

const Empty: React.FC<PropType> = ({ size = 'middle', text, className }) => {
  const { t } = useTranslation()

  const config = {
    small: 'w-24',
    middle: 'w-28',
    large: 'w-36'
  }

  const classNames = twMerge('aspect-square fill-fontcolor-400', `${config[size] || config['middle']}`)

  return (
    <div className={twMerge('flex flex-col items-center justify-center text-fontcolor-300', className)}>
      <EmptySVG className={classNames} />
      <p>{text ? text : t('tip.empty')}</p>
    </div>
  )
}

interface PropType {
  size?: 'small' | 'middle' | 'large'
  text?: string
  className?: string
}

export default Empty
