import React from 'react'
import { useTranslation } from 'react-i18next'

import useStatePopup from '@/hook/useStatePopup'
import { PopupSelector, PopUpStore } from '@/store/PopupStore'
import Drawer from '@/component/Drawer'
import AuthRequired from '@/component/AuthRequired'
import TopUpGoldMain from './TopUpGoldMain'

const TopUpGoldPopup: React.FC<PropType> = () => {
  const { t } = useTranslation('wallet')
  const topUpGoldPopupVisible = useStatePopup(PopupSelector.topUpGoldPopupVisible)

  const handleClose = () => {
    PopUpStore.setTopUpGoldPopupVisible(false)
  }

  return (
    <Drawer
      visible={topUpGoldPopupVisible}
      zIndex={901}
      onClose={handleClose}
      lockScroll={true}
      className="bg-primary-light"
      title={t('modalTitle.topUpGold')}
    >
      <AuthRequired>
        <TopUpGoldMain />
      </AuthRequired>
    </Drawer>
  )
}

interface PropType {}

export default TopUpGoldPopup
