import React from 'react'
import { useTranslation } from 'react-i18next'

import { east8TimestampToDateTime } from '@/helper'
import type { WalletBilling } from '@kulee/tga-sdk/types/types/wallet'

const BillingItem: React.FC<PropType> = ({ item }) => {
  const { t } = useTranslation('wallet')

  const { amount, businessCode, type, createTime } = item

  const amountComp = type === 'in' ? <span className="text-primary-dark">+ {amount}</span> : <span>- {amount}</span>

  return (
    <div className="flex items-center">
      <div className="flex-grow">
        <p className="text-base font-semibold">{t(`bussiness.${businessCode.toLocaleLowerCase()}`)}</p>
        <p className="text-gray-400">{east8TimestampToDateTime(createTime)}</p>
      </div>
      <div className="text-lg font-semibold">{amountComp}</div>
    </div>
  )
}

interface PropType {
  item: WalletBilling
}

export default BillingItem
