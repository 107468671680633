import type { BaseGameType, BaseTagType, GameDetailType, GamesWithTag, TagDetailType } from '../../types/game'

class Convert {
  /**
   * 游戏数据转换
   * @param data 服务端游戏数据
   * @returns 客户端游戏数据
   */
  public static baseGameToClient(data: ServerDataNS.BaseGameType): BaseGameType {
    const { id, code, name, icon, recommendReason, battlePlayersNum, type } = data

    const game: BaseGameType = {
      id,
      code,
      name,
      icon
    }

    recommendReason && (game.recommend = recommendReason)
    type === 1 && battlePlayersNum !== null && (game.pvp = battlePlayersNum)

    return game
  }

  /**
   * 游戏数据转换(列表)
   * @param data 服务端游戏数据
   * @returns 客户端游戏数据
   */
  public static baseGamesToClient(data: ServerDataNS.BaseGameType[]): BaseGameType[] {
    return data.map(item => {
      return this.baseGameToClient(item)
    })
  }

  /**
   * 游戏详情数据转换
   * @param data 服务端游戏数据
   * @returns 客户端游戏数据
   */
  public static gameDetailToClient(data: ServerDataNS.GameDetailType): GameDetailType {
    const baseGame = this.baseGameToClient(data)

    const { orientation, url, width, height, description: desc, tagVoList, gameType, preloadAllowed: preload } = data

    return {
      ...baseGame,
      url,
      orientation: orientation === 0 ? 'both' : orientation === 1 ? 'landscape' : 'portrait',
      desc,
      width,
      height,
      gameType,
      preload,
      tags: tagVoList ? this.baseTagsToClient(tagVoList) : []
    }
  }

  /**
   * 标签数据转换
   * @param data 服务端标签数据
   * @returns 客户端标签数据
   */
  public static baseTagToClient(data: ServerDataNS.BaseTagType): BaseTagType {
    const { code, name, icon } = data

    return {
      code,
      name,
      icon
    }
  }

  /**
   * 标签数据转换（列表）
   * @param data 服务端标签数据
   * @returns 客户端标签数据
   */
  public static baseTagsToClient(data: ServerDataNS.BaseTagType[]): BaseTagType[] {
    return data.map(item => {
      return this.baseTagToClient(item)
    })
  }

  /**
   * 游戏标签详情数据转换
   * @param data 服务端标签数据
   * @returns 客户端标签数据
   */
  public static tagDetailToClient(data: ServerDataNS.TagDetailType): TagDetailType {
    const baseTag = this.baseTagToClient(data)

    const { description: desc } = data

    return {
      ...baseTag,
      desc
    }
  }

  /**
   * 所有游戏列表，带游戏标签数据转换
   * @param data 服务端数据
   * @returns 客户端数据
   */
  public static gamesWithTagToClient(data: ServerDataNS.GamesWithTag[]): GamesWithTag[] {
    return data.map(item => {
      return {
        games: this.baseGamesToClient(item.gameList),
        tag: this.baseTagToClient(item.tagInfo)
      }
    })
  }
}

export default Convert
