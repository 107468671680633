import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'react-use'
import { useLocation } from 'react-router-dom'

import useScreenFixed from '@/hook/useScreenFixed'
import useThemeBasis from '@/hook/useThemeBasis'
import useRuntime from '@/hook/useRuntime'
import Input from '@/component/Input'
import SearchResult from './SearchResult'
import { ReactComponent as SearchSVG } from '@/asset/svg/search.svg'
import { ReactComponent as BackSVG } from '@/asset/svg/back.svg'
import useLinkTo from '@/hook/useLinkTo'

const Search: React.FC<PropType> = () => {
  const { t } = useTranslation()
  const { client } = useRuntime()
  const { lightHeader } = useThemeBasis()
  const { linkTo } = useLinkTo()
  const location = useLocation()
  const setScreenFixed = useScreenFixed()
  const inputRef = useRef<HTMLInputElement>(null)
  const [value, setValue] = useState<string>()
  const [keyword, setKeyword] = useState<string>()
  const [formOpened, setFormOpened] = useState(false)

  const close = () => {
    setFormOpened(false)
    setValue('')
  }

  const handleSearchBtnClick = () => {
    setFormOpened(true)
    inputRef.current?.focus()
  }

  const handleBackBtnClick = () => {
    close()
    inputRef.current?.blur()
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log('xxxxxxxxxxxxxxx outer handleChange', e)
    setValue(e.target.value)
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    !value && close()
    // setTimeout(() => {
    //   document.activeElement !== inputRef.current && close()
    // }, 10)
  }

  const handlePressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    linkTo(`/search?keyword=${value}`)
    close()
  }

  const handleResulePannelClick = () => {
    inputRef.current?.focus()
  }

  const handleMaskClick = () => {
    setFormOpened(false)
    setValue('')
  }

  useEffect(() => {
    setScreenFixed(!!(client === 'mobile' && value))
  }, [value])

  const [, cancel] = useDebounce(
    () => {
      setKeyword(value)
    },
    800,
    [value]
  )

  useEffect(() => {
    close()
  }, [location])

  return (
    <>
      <button className={`flex h-10 w-10 items-center justify-center sm:hidden`} onClick={handleSearchBtnClick}>
        <SearchSVG className={`w-6 ${lightHeader ? 'fill-primary' : 'fill-primary-text'}`} />
      </button>
      <div
        className={`fixed top-0 z-30 flex h-14 w-full items-center bg-primary pr-2 transition-all sm:static sm:block sm:h-auto sm:bg-transparent lg:pr-48 ${formOpened ? 'left-0' : 'left-full'}`}
      >
        <button
          className={`flex h-10 w-10 items-center justify-center sm:hidden ${formOpened ? 'block' : 'hidden'}`}
          onClick={handleBackBtnClick}
        >
          <BackSVG className="w-6 fill-primary-text" />
        </button>
        <div className="mx-auto max-w-[520px] flex-grow px-6">
          <div className="w-full sm:relative">
            <Input
              ref={inputRef}
              value={value}
              allowClear={true}
              placeholder={t('search.placeholder')}
              onPressEnter={handlePressEnter}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {value && (
              <div
                className="fixed left-0 top-14 h-[calc(100%-3.5rem)] w-full overflow-hidden bg-primary sm:absolute sm:top-12 sm:h-auto sm:rounded-md"
                onClick={handleResulePannelClick}
              >
                <SearchResult keyword={keyword} />
              </div>
            )}
          </div>
        </div>
      </div>
      {value && (
        <div className="fixed left-0 top-0 hidden h-full w-full bg-black/30 sm:block" onClick={handleMaskClick}></div>
      )}
    </>
  )
}

interface PropType {}

export default Search
