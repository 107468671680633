import { Logger } from '@kulee/helper'
import { RESPONSE_CODES } from '@kulee/tga-constant'

// 日志前缀
export const LOGGER_PREFIX = '__CLAZZ__'
// 日志输出等级
export const LOGGER_LEVEL = Logger.LEVEL.INFO

// 错误映射
export const ERROR_MAP: Record<number, string> = {
  0: RESPONSE_CODES.UNKNOWN_ERROR,
  401: RESPONSE_CODES.UC_PERMISSION_INVALID,
  500: RESPONSE_CODES.SERVER_ERROR,
  503: RESPONSE_CODES.SERVER_ERROR,
  10014: RESPONSE_CODES.UC_PASSWORD_INVALID,
  10019: RESPONSE_CODES.UC_USER_NOT_EXIST
}
