import BaseModule from '../../BaseModule'
import logger from '../../config/logger'

import RankService from './Service'

class Rank extends BaseModule {
  public static override clazz = 'Rank'

  public service!: RankService

  public override run(): void {
    logger.log(`run module ${Rank.clazz}`)

    this.service = new RankService(this.context)
  }
}

export default Rank
