class EventBus {
  private static listeners: Record<string, Handler[]> = {}

  public static addEventListener(eventName: string, callback: Callback, scope: any = null) {
    const handlers = this.listeners[eventName] || []
    handlers.push({
      scope,
      callback
    })

    this.listeners[eventName] = handlers
  }

  public static removeEventListener(eventName: string, callback?: Callback, scope: any = null) {
    const handlers = this.listeners[eventName] || []

    let newHandlers: Handler[] = []

    if (callback) {
      newHandlers = handlers.filter(handler => {
        return handler.callback !== callback && handler.scope === scope
      })
    }

    this.listeners[eventName] = newHandlers
  }

  public static hasEventListener(eventName: string, callback?: Callback, scope: any = null) {
    const handlers = this.listeners[eventName] || []

    if (!callback && scope === null) {
      return handlers.length > 0
    }

    const handlersFiltered = handlers.filter(handler => {
      return handler.callback !== callback && handler.scope === scope
    })

    return handlersFiltered.length > 0
  }

  public static dispatch(eventName: string, ...args: any[]) {
    const handlers = (this.listeners[eventName] || []).slice()
    handlers.forEach(handler => {
      const { callback, scope } = handler
      callback.apply(scope, args)
    })
  }
}

type Callback = (...args: any[]) => void
type Handler = {
  scope: any
  callback: Callback
}
// type Event = {
//   eventName: string
//   data?: any
// }

export default EventBus
