import { useEffect, useMemo, useState } from 'react'
import { VIP } from '@kulee/tga-sdk'

import TGASDK from '@/config/TGASDK'
import logger from '@/helper/logger'

import type { VIPContextType, VIPStatus } from '@/type/vip'
import type { UserVIP, UserVIPBenefit, VIPBenefitCode } from '@kulee/tga-sdk/types/types/vip'

const useProvideVIP = (): VIPContextType => {
  const vipEnabled = window.AppConfig.data.feature.vip.enabled

  const [status, setStatus] = useState<VIPStatus>('unready')
  const [userVIPs, setUserVIPs] = useState<UserVIP[]>([])
  const [userVIPBenefits, setUserVIPBenefits] = useState<UserVIPBenefit[]>([])

  // 初始化vip
  const init = async () => {
    if (!vipEnabled) {
      return
    }

    try {
      const { data } = await TGASDK.module.VIP.service.getUserVIPs()

      if (data.length > 0) {
        const userVipBenefits = await TGASDK.module.VIP.getUserVipBenefits()
        setUserVIPBenefits(userVipBenefits)
      }

      setUserVIPs(data)
      setStatus('ready')
    } catch (error) {
      logger.error('vip init error', error)
    }
  }

  const getBenefit = (code: VIPBenefitCode): UserVIPBenefit | undefined => {
    try {
      return TGASDK.module.VIP.getBenefit(code)
    } catch (error) {
      return undefined
    }
  }

  useEffect(() => {
    TGASDK.addModule(VIP)
  }, [])

  return useMemo(
    () => ({
      status,
      userVIPs,
      userVIPBenefits,
      getBenefit,
      init
    }),
    [status, userVIPs, userVIPBenefits]
  )
}

export default useProvideVIP
