import React from 'react'
import { useTranslation } from 'react-i18next'

import useStatePopup from '@/hook/useStatePopup'
import { PopupSelector, PopUpStore } from '@/store/PopupStore'
import Drawer from '@/component/Drawer'
import VIPMamin from './VIPMain'

const VIPPopup: React.FC<PropType> = () => {
  const { t } = useTranslation('vip')
  const vipPopupVisible = useStatePopup(PopupSelector.vipPopupVisible)

  const handleClose = () => {
    PopUpStore.setVIPPopupVisible(false)
  }

  return (
    <Drawer
      visible={vipPopupVisible}
      zIndex={902}
      onClose={handleClose}
      lockScroll={true}
      destroyOnClose
      className="bg-primary-extralight text-[#002231]"
      contentClassName="-mt-14 text-fontcolor-500"
      title={t('modalTitle.vip')}
    >
      <VIPMamin />
    </Drawer>
  )
}

interface PropType {}

export default VIPPopup
