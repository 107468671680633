import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import WalletContext from '@/context/WalletContext'
import Button from '@/component/Button'
import CurrencyIcon from '@/component/CurrencyIcon'
import { PopUpStore } from '@/store/PopupStore'

const DiamondBalance: React.FC<PropType> = () => {
  const { t } = useTranslation('wallet')
  const { wallet } = useContext(WalletContext)

  const diamondCurrency = wallet['DIAMOND']

  const handleTopUpClick = () => {
    PopUpStore.setTopUpDiamondPopupVisible(true)
  }

  if (!diamondCurrency) {
    return null
  }

  return (
    <div>
      <p className="font-custom-bold mb-sm text-base">
        {t('currency.diamond')} {t('title.balance')}
      </p>
      <div className="flex items-center">
        <div className="flex flex-grow items-center gap-sm">
          <CurrencyIcon code="diamond" size="large" />
          <span className="font-custom-bold text-4xl">{wallet.DIAMOND?.balance}</span>
        </div>
        <div>
          <Button className="px-md font-normal leading-5" onClick={handleTopUpClick}>
            {t('btn.topUp')}
          </Button>
        </div>
      </div>
    </div>
  )
}

interface PropType {}

export default DiamondBalance
