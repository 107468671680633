export enum EVENT_CMDS {
  // 监听语音初始化成功
  TALK_INITED = 'talk.inited',
  // 监听自己麦克风打开
  MY_MIC_ON = 'talk.mac.on',
  // 监听自己用户麦克风关闭
  MY_MIC_OFF = 'talk.mac.off',
  // 监听自己用户扬声器打开
  MY_SPEAKER_ON = 'talk.speaker.on',
  // 监听自己用户扬声器关闭
  MY_SPEAKER_OFF = 'talk.speaker.off',
  // 监听其他麦克风打开
  OTHER_MIC_ON = 'talk.other.mac.on',
  // 监听其他用户麦克风关闭
  OTHER_MIC_OFF = 'talk.other.mac.off',
  // 监听其他用户扬声器打开
  OTHER_SPEAKER_ON = 'talk.other.speaker.on',
  // 监听其他用户扬声器关闭
  OTHER_SPEAKER_OFF = 'talk.other.speaker.off',
  // 监听其他用户开始讲话
  OTHER_SPEAK = 'talk.other.speak',
  // 监听其他用户停止讲话
  OTHER_STOP_SPEAK = 'talk.other.speak.stop',
  // 监听成员是否发生变化
  MEMBER_CHANGE = 'talk.member.change'
}
