import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

import Mask from '../Mask'
import { ReactComponent as CloseSVG } from '@/asset/svg/close.svg'
import { twMerge } from 'tailwind-merge'
import Button from '../Button'

const Modal: React.FC<PropType> = ({
  visible = false,
  zIndex = 950,
  getContainer = document.body,
  title,
  closable = true,
  width,
  lockScroll = true,
  destroyOnClose = false,
  mask = true,
  okText,
  cancelText,
  footer,
  children,
  rootClassName,
  onOk = () => {},
  onCancel = () => {}
}) => {
  const [status, setStatus] = useState<'unready' | 'ready'>('unready')

  useEffect(() => {
    if (status === 'unready' && !visible) {
      return
    }

    setStatus('ready')
  }, [visible])

  if (status === 'unready') {
    return null
  }

  const container = typeof getContainer === 'function' ? getContainer() : getContainer

  const handleOkClick = () => {
    onOk()
  }

  const handleCancelClick = () => {
    onCancel()
  }

  const rootClassNames = twMerge('pointer-events-none inset-0', container ? 'fixed' : 'absolute', rootClassName)

  const okBtn = (
    <Button type="primary" size="small" onClick={handleOkClick}>
      {okText || 'OK'}
    </Button>
  )
  const cancelBtn = (
    <Button size="small" onClick={handleCancelClick}>
      {cancelText || 'Cancel'}
    </Button>
  )

  const modal = (
    <div
      className={rootClassNames}
      style={{
        zIndex: zIndex
      }}
    >
      <div
        className="pointer-events-auto relative top-1/3 z-10 mx-auto max-w-[calc(100vw-16px)]"
        style={{
          width: width ? width : '288px',
          visibility: visible ? 'visible' : 'hidden'
        }}
      >
        <div className="rounded-xl bg-white p-4 text-[#002231]">
          {(closable || title) && (
            <div className="flex items-center justify-end gap-4">
              {title && <h3 className="flex-grow overflow-hidden text-ellipsis text-nowrap">{title}</h3>}
              {closable && (
                <button className="flex-grow-0" onClick={handleCancelClick}>
                  <CloseSVG className="h-6 w-6" />
                </button>
              )}
            </div>
          )}
          <div className="py-4">{destroyOnClose ? (visible ? children : null) : children}</div>
          {footer !== null && (
            <div className="flex justify-end gap-2">
              {cancelBtn}
              {okBtn}
            </div>
          )}
        </div>
      </div>

      {mask && (
        <Mask visible={visible} getContainer={false} zIndex={1} lockScroll={lockScroll} onClick={handleCancelClick} />
      )}
    </div>
  )

  return container ? createPortal(<div>{modal}</div>, document.body) : <>{modal}</>
}

interface PropType {
  visible?: boolean
  zIndex?: number
  getContainer?: false | HTMLElement | (() => HTMLElement)
  title?: string
  closable?: boolean
  width?: number | string
  lockScroll?: boolean
  destroyOnClose?: boolean
  mask?: boolean
  footer?: React.ReactNode | null
  okText?: string
  cancelText?: string
  rootClassName?: string
  children: React.ReactNode
  onCancel?: () => void
  onOk?: () => void
}

export default Modal
