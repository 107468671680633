const ENV = {
  DEVELOPMENT: 'development',
  TEST: 'test',
  PRODUCTION: 'production',
  BIP_TEST: 'bip-test',
  BIP: 'bip',
  METAONE: 'metaone'
}

export default ENV
