class URL {
  /**
   * 获取url参数对象
   * @returns url参数对象
   */
  public static getUrlQuery(): Record<string, string> {
    let query = window.location.href.split('?')[1]
    const result: Record<string, string> = {}

    if (query) {
      if (query.indexOf('#') > -1) {
        query = query.split(/\/?#/)[0] as string
      }

      const queryArr = query.split('&')
      queryArr.forEach(param => {
        const pair = param.split('=')
        result[pair[0] as string] = pair[1] as string
      })
    }

    return result
  }

  /**
   * 获取url某个参数值
   * @param name 参数名
   * @returns 参数值
   */
  public static getUrlQueryByName(name: string): string | null {
    const query = this.getUrlQuery()
    return query[name] || null
  }
}

export default URL
