import { createContext } from 'react'

import type { VIPContextType } from '@/type/vip'

const VIPContext = createContext<VIPContextType>({
  status: 'unready',
  userVIPs: [],
  userVIPBenefits: [],
  init: () => new Promise(() => {}),
  getBenefit: () => undefined
})

export default VIPContext
