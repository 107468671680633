import React from 'react'
import { useTranslation } from 'react-i18next'

import NavItem from '../NavItem'
import LinkEnhance from '@/component/LinkEnhance'

const NavFooter: React.FC<PropType> = () => {
  const { t } = useTranslation()
  const path = window.location.pathname
  const section = path.split('/').filter(Boolean).pop()

  return (
    <div className="w-48">
      <div className="mb-4">
        <NavItem
          item={{
            name: 'allTag',
            icon: 'tag.svg',
            url: '/tags/',
            active: section === 'tags'
          }}
        />
      </div>
      {window.AppConfig.data.page.footerEnabled && (
        <div className="px-4 group-hover:visible sm:invisible 2xl:visible">
          <ul className="mb-10 text-sm [&>li]:py-[2px]">
            <li>
              <LinkEnhance href="terms/">{t('footerMenu.terms')}</LinkEnhance>
            </li>
            <li>
              <LinkEnhance href="privacy/">{t('footerMenu.privacy')}</LinkEnhance>
            </li>
            <li>
              <LinkEnhance href="cookies/">{t('footerMenu.cookies')}</LinkEnhance>
            </li>
          </ul>
          <div className="text-xs">© 2016-2024 JUST4FUN</div>
        </div>
      )}
    </div>
  )
}

interface PropType {}

export default NavFooter
