import logger from './log'

class PM {
  private static instance: PM

  private target: Window
  private clazz: string
  private origin: string
  private listeners: Record<string, EventObj[]> = {}

  constructor(options: PMOptions) {
    const { target, clazz = 'default', origin = '*' } = options
    this.target = target
    this.clazz = clazz
    this.origin = origin
    if (window.addEventListener) {
      window.addEventListener('message', this.handler.bind(this), false)
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.attachEvent('onmessage', this.handler.bind(this))
    }
  }

  public static getInstance(options: PMOptions): PM {
    if (!this.instance) {
      this.instance = new PM(options)
    } else {
      const { target, clazz = 'default', origin = '*' } = options
      this.instance.target = target
      this.instance.clazz = clazz
      this.instance.origin = origin
    }

    return this.instance
  }

  public on(cmd: string, handler: Handler, scope: any = window): PM {
    const { listeners, clazz } = this
    listeners[cmd] = listeners[cmd] = []
    listeners[cmd]?.push({
      scope,
      clazz,
      handler
    })
    return this
  }

  public emit(cmd: string, data: any = ''): PM {
    const { origin } = this
    logger.log(`emit postmessage cmd '${cmd}', data `, data)

    this.target.postMessage(
      {
        cmd,
        data
      },
      origin
    )
    return this
  }

  public remove(cmd: string, handler: Handler, scope: any = window): PM {
    const exactListeners = this.listeners[cmd]

    if (exactListeners && exactListeners.length > 0) {
      const exactlistenersAfter: EventObj[] = []
      exactListeners.forEach(listener => {
        if (listener.scope !== scope || listener.handler !== handler) {
          exactlistenersAfter.push(listener)
        }
      })

      this.listeners[cmd] = exactlistenersAfter
    }

    return this
  }

  public removeAll(cmd?: string): PM {
    if (cmd) {
      delete this.listeners[cmd]
    } else {
      this.listeners = {}
    }

    return this
  }

  public hasEventListener(cmd: string, handler: Handler, scope: any = window): boolean {
    const exactListeners = this.listeners[cmd]

    if (!exactListeners) {
      return false
    }

    let result = false
    exactListeners.forEach(listener => {
      if (listener.scope === scope && listener.handler === handler) {
        result = true
      }
    })
    return result
  }

  private handler(event: MessageEvent) {
    if (!this.isEventAvailable(event)) {
      return
    }

    const { listeners } = this
    const { cmd, ...rest } = event.data
    const exactListeners = listeners[cmd]
    if (exactListeners) {
      exactListeners.slice().forEach(listener => {
        const { scope, handler } = listener
        logger.info(`handle event '${cmd}' call data `, rest)
        handler.call(scope, rest)
      })
    }
  }

  private isEventAvailable(event: MessageEvent): boolean {
    const { data = {} } = event
    const { cmd, clazz = 'default' } = data
    return cmd && clazz === this.clazz
  }
}

interface PMOptions {
  target: Window
  // 场景：当一个父级页面以iframe加载了两个相同的子页面时，可以根据这个clazz来隔离两个子页面与父页面的通信
  clazz?: string
  origin?: string
}

interface EventObj {
  // 回调的上下文
  scope: any
  clazz: string
  handler: Handler
}
type Handler = (payload: any) => void

export default PM
