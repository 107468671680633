import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useAchievements } from '@/hook/data/useGrowth'

const MyAchievement: React.FC<PropType> = () => {
  const { t } = useTranslation('growth')
  const { data: achievements } = useAchievements({ refetchOnMount: 'always' })

  const count = achievements?.filter(item => item.status === 'received').length || 0

  return (
    <div className="mb-md text-center text-base">
      <Trans
        t={t}
        i18nKey={'tip.achievement'}
        count={count}
        components={[<span className="text-xl font-custom-bold text-primary-dark">0</span>]}
      ></Trans>
    </div>
  )
}

interface PropType {}

export default MyAchievement
