import React from 'react'

import Picture from '@/component/Picture'
import type { AchievementItemType } from '@kulee/tga-sdk/types/types/growth'

const AchievementItem: React.FC<PropType> = ({ item }) => {
  const { title, receivedIcon, progresses, status } = item

  return (
    <div className="text-center">
      <div className={`mx-auto aspect-square w-36 ${status !== 'received' && 'grayscale'}`}>
        <Picture src={receivedIcon} />
      </div>
      <h3>{title}</h3>
      {status !== 'received' && (
        <ul className="text-fontcolor-400 flex flex-col gap-1 text-xs">
          {progresses.map((progress, index) => (
            <li key={index}>{progress}</li>
          ))}
        </ul>
      )}
    </div>
  )
}

interface PropType {
  item: AchievementItemType
}

export default AchievementItem
