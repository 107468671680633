import { useMemo } from 'react'
import { useWindowSize } from 'react-use'

const SIZES = ['xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl'] as Size[]

const useMediaBreakpoint = (): MediaBreakpoint => {
  const { width } = useWindowSize()

  const size =
    width >= 640 && width < 768
      ? SIZES[1]
      : width >= 768 && width < 1024
        ? SIZES[2]
        : width >= 1024 && width < 1280
          ? SIZES[3]
          : width >= 1280 && width < 1536
            ? SIZES[4]
            : width >= 1536 && width < 1920
              ? SIZES[5]
              : width >= 1920
                ? SIZES[6]
                : SIZES[0]

  const overSize = (s: Size) => {
    return SIZES.indexOf(size) >= SIZES.indexOf(s)
  }

  const getTotal = <T>(query: T[]): T => {
    const index = SIZES.indexOf(size)

    return query[index] || query[index - 1] || query[0]
  }

  return useMemo(
    () => ({
      size,
      overSize,
      getTotal
    }),
    [size]
  )
}

interface MediaBreakpoint {
  size: Size
  overSize: (s: Size) => Boolean
  getTotal: <T>(query: T[]) => T
}

type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl'

export default useMediaBreakpoint
