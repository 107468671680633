import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Wallet } from '@kulee/tga-sdk'

import TGASDK from '@/config/TGASDK'
import useRuntime from '../useRuntime'
import { QUERY_KEYS } from '@/config/constant'

import type { ChargeCommodity } from '@kulee/tga-sdk/types/types/wallet'

const useChargeCommodity = (targetCurrency: string, payCurrency?: string, price?: number) => {
  const { lang, channel } = useRuntime()

  useEffect(() => {
    TGASDK.addModule(Wallet)
  }, [])

  return useQuery({
    queryKey: [QUERY_KEYS.WALLET_CHARGE_COMMODITY, lang, channel, targetCurrency, payCurrency, price],
    queryFn: async (): Promise<ChargeCommodity[]> => {
      const { data } = await TGASDK.module.Wallet.service.getChargeCommodities({
        targetCurrency,
        payCurrency,
        price
      })

      return data
    }
  })
}

export default useChargeCommodity
