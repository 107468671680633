import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { twMerge } from 'tailwind-merge'

import Mask from '../Mask'
import { ReactComponent as CloseSVG } from '@/asset/svg/close.svg'

const Drawer: React.FC<PropType> = ({
  visible = false,
  zIndex = 900,
  getContainer = document.body,
  placement = 'right',
  width = 378,
  height = 328,
  title,
  closable = true,
  mask = true,
  lockScroll = true,
  destroyOnClose = false,
  className,
  rootClassName,
  contentClassName,
  onClose,
  children,
  onMouseOver,
  onMouseOut
}) => {
  const sizeStyles = {
    width: placement === 'left' || placement === 'right' ? width + 'px' : 'unset',
    height: placement === 'top' || placement === 'bottom' ? height + 'px' : 'unset'
  }
  const positionStyles = {
    top: placement === 'left' || placement === 'right' ? 0 : 'unset',
    bottom: placement === 'left' || placement === 'right' ? 0 : 'unset',
    left: placement === 'top' || placement === 'bottom' ? 0 : 'unset',
    right: placement === 'top' || placement === 'bottom' ? 0 : 'unset'
  }
  const transitionBeforeStyles = {
    top: placement === 'top' ? `-${height}px` : positionStyles.top,
    bottom: placement === 'bottom' ? `-${height}px` : positionStyles.bottom,
    left: placement === 'left' ? `-${width}px` : positionStyles.left,
    right: placement === 'right' ? `-${width}px` : positionStyles.right
  }
  const transitionOverStyles = {
    top: placement === 'top' ? 0 : positionStyles.top,
    bottom: placement === 'bottom' ? 0 : positionStyles.bottom,
    left: placement === 'left' ? 0 : positionStyles.left,
    right: placement === 'right' ? 0 : positionStyles.right
  }

  // unready: 未初始化、firstEntering：首次进场中、entering：进场中、enter：进场结束、exiting：出场中、exit：出场结束
  const [status, setStatus] = useState<'unready' | 'firstEntering' | 'entering' | 'enter' | 'exiting' | 'exit'>(
    'unready'
  )
  const [styles, setStyles] = useState<React.CSSProperties>({
    visibility: 'visible',
    ...transitionBeforeStyles
  })

  const container = typeof getContainer === 'function' ? getContainer() : getContainer

  const handleTransitionEnd = () => {
    if (!visible) {
      setStyles({
        visibility: 'hidden',
        ...transitionBeforeStyles
      })
    }
  }

  const handleClose = () => {
    onClose && onClose()
  }

  useEffect(() => {
    if (status === 'unready' && !visible) {
      return
    }

    setStatus(visible ? (status === 'unready' ? 'firstEntering' : 'entering') : 'exiting')
  }, [visible])

  useEffect(() => {
    switch (status) {
      case 'firstEntering':
      case 'entering':
        setTimeout(
          () => {
            setStyles({
              visibility: 'visible',
              ...transitionOverStyles
            })
          },
          status === 'firstEntering' ? 50 : 0
        )
        break
      case 'exiting':
        setStyles({
          visibility: 'visible',
          ...transitionBeforeStyles
        })
        break
      default:
        break
    }
  }, [status])

  if (status === 'unready') {
    return null
  }

  const classNames = twMerge('flex h-full w-full flex-col bg-white', className)
  const rootClassNames = twMerge('pointer-events-none inset-0', container ? 'fixed' : 'absolute', rootClassName)
  const contentClassNames = twMerge('flex-grow overflow-y-auto overflow-x-hidden', contentClassName)

  const drawer = (
    <div
      className={rootClassNames}
      style={{
        zIndex: zIndex
      }}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <div
        className={'pointer-events-auto absolute z-10 max-w-[100vw] shadow-lg transition-all duration-200 ease-in-out'}
        style={{
          ...sizeStyles,
          ...positionStyles,
          ...styles
        }}
        onTransitionEnd={handleTransitionEnd}
      >
        <div className={classNames}>
          {(closable || title) && (
            <div className="relative z-[99999] flex h-14 flex-shrink-0 items-center gap-4 px-4">
              {closable && (
                <button className="flex-grow-0" onClick={handleClose}>
                  <CloseSVG className="h-7 w-7 fill-current" />
                </button>
              )}
              {title && <h2 className="overflow-hidden text-ellipsis text-nowrap">{title}</h2>}
            </div>
          )}
          <div className={contentClassNames}>{destroyOnClose ? (visible ? children : null) : children}</div>
        </div>
      </div>
      {mask && <Mask visible={visible} getContainer={false} zIndex={1} lockScroll={lockScroll} onClick={handleClose} />}
    </div>
  )

  return container ? createPortal(<div>{drawer}</div>, document.body) : <>{drawer}</>
}

interface PropType {
  visible?: boolean
  zIndex?: number
  getContainer?: false | HTMLElement | (() => HTMLElement)
  placement?: 'left' | 'right' | 'top' | 'bottom'
  width?: number
  height?: number
  title?: string
  closable?: boolean
  mask?: boolean
  lockScroll?: boolean
  destroyOnClose?: boolean
  className?: string
  rootClassName?: string
  contentClassName?: string
  children: React.ReactNode
  onClose?: () => void
  onMouseOver?: React.MouseEventHandler<HTMLDivElement>
  onMouseOut?: React.MouseEventHandler<HTMLDivElement>
}

export default Drawer
