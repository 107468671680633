import React from 'react'
import { useTranslation, Trans } from 'react-i18next'

import { useCheckinStatus } from '@/hook/data/useGrowth'
import Award from '@/component/Award'

const MyCheckin: React.FC<PropType> = () => {
  const { t } = useTranslation('growth')
  const { data: checkinStatus } = useCheckinStatus()

  return (
    <div className="mb-md flex flex-col items-center text-center text-base">
      <p>
        <Trans
          t={t}
          i18nKey={'tip.checkinTotal'}
          count={checkinStatus?.count}
          components={[<span className="text-xl font-custom-bold text-primary-dark">0</span>]}
        ></Trans>
      </p>
      <div className="text-primary-dark">
        <Award items={checkinStatus?.rewards} />
      </div>
    </div>
  )
}

interface PropType {}

export default MyCheckin
