import React from 'react'

import useStatePopup from '@/hook/useStatePopup'
import { PopupSelector, PopUpStore } from '@/store/PopupStore'
import Drawer from '@/component/Drawer'
import PayMain from './PayMain'

const PayPopup: React.FC<PropType> = () => {
  const { visible } = useStatePopup(PopupSelector.payPopup)

  const handleClose = () => {
    PopUpStore.setPayPopup({
      visible: false
    })
  }

  return (
    <Drawer
      visible={visible}
      placement="bottom"
      zIndex={910}
      onClose={handleClose}
      lockScroll={true}
      destroyOnClose
      className="bg-white"
    >
      <PayMain />
    </Drawer>
  )
}

interface PropType {}

export default PayPopup
