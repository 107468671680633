import React from 'react'
import Picture from '@/component/Picture'
import { twMerge } from 'tailwind-merge'

import { ReactComponent as OKSVG } from '@/asset/svg/ok.svg'

const PictureChooseItem: React.FC<PropType> = ({ url, checked = false, onClick }) => {
  const handleClick = () => {
    onClick && onClick(url)
  }

  const classNames = twMerge(
    'relative w-full h-full cursor-pointer overflow-hidden rounded-lg border-[3px]',
    checked ? 'border-semantic-success' : 'border-transparent'
  )

  return (
    <div className={classNames} onClick={handleClick}>
      <Picture src={url} />
      {checked && (
        <div className="bg-semantic-success absolute right-0 top-0 flex h-6 w-6 items-center justify-center overflow-hidden rounded-bl-lg">
          <OKSVG className="h-5 w-5 fill-white" />
        </div>
      )}
    </div>
  )
}

interface PropType {
  url: string
  checked?: boolean
  onClick?: (url: string) => void
}

export default PictureChooseItem
