import { useContext, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Growth } from '@kulee/tga-sdk'

import TGASDK from '@/config/TGASDK'
import AuthContext from '@/context/AuthContext'
import useRuntime from '../useRuntime'
import { QUERY_KEYS } from '@/config/constant'
import { taskStatusData } from '@/helper/faker'
import type {
  AchievementItemType,
  CheckinDataType,
  TaskWithGroup,
  UserTaskStatus
} from '@kulee/tga-sdk/types/types/growth'

import type { MyUseQueryOptions } from '@/type/common'

const useCheckinData = () => {
  const { user } = useContext(AuthContext)
  const { lang, channel } = useRuntime()

  useEffect(() => {
    TGASDK.addModule(Growth)
  }, [])

  return useQuery({
    queryKey: [QUERY_KEYS.CHECKINS, lang, channel, user?.userId],
    refetchOnMount: 'always',
    queryFn: async (): Promise<CheckinDataType> => {
      const { data } = await TGASDK.module.Growth.service.getCheckinData()
      return data
    }
  })
}

const useCheckinStatus = () => {
  const { user } = useContext(AuthContext)
  const { lang, channel } = useRuntime()

  useEffect(() => {
    TGASDK.addModule(Growth)
  }, [])

  return useQuery({
    queryKey: [QUERY_KEYS.CHECKIN_STATUS, lang, channel, user?.userId],
    queryFn: async (): Promise<UserTaskStatus> => {
      const { data } = await TGASDK.module.Growth.service.getCheckinStatus()
      return data
    },
    placeholderData: taskStatusData
  })
}

const useTasksWithGroup = () => {
  const { user } = useContext(AuthContext)
  const { lang, channel } = useRuntime()

  useEffect(() => {
    TGASDK.addModule(Growth)
  }, [])

  return useQuery({
    queryKey: [QUERY_KEYS.TASKS, lang, channel, user?.userId],
    refetchOnMount: 'always',
    queryFn: async (): Promise<TaskWithGroup[]> => {
      const { data } = await TGASDK.module.Growth.service.getTasksWithGroup()
      return data
    }
  })
}

const useTaskStatus = () => {
  const { user } = useContext(AuthContext)
  const { lang, channel } = useRuntime()

  useEffect(() => {
    TGASDK.addModule(Growth)
  }, [])

  return useQuery({
    queryKey: [QUERY_KEYS.TASKS_STATUS, lang, channel, user?.userId],
    queryFn: async (): Promise<UserTaskStatus> => {
      const { data } = await TGASDK.module.Growth.service.getTaskStatus()
      return data
    },
    placeholderData: taskStatusData
  })
}

const useAchievements = ({ refetchOnMount = true }: MyUseQueryOptions = {}) => {
  const { user } = useContext(AuthContext)
  const { lang, channel } = useRuntime()

  useEffect(() => {
    TGASDK.addModule(Growth)
  }, [])

  return useQuery({
    queryKey: [QUERY_KEYS.ACHIEVEMENTS, lang, channel, user?.userId],
    refetchOnMount,
    queryFn: async (): Promise<AchievementItemType[]> => {
      const { data } = await TGASDK.module.Growth.service.getAchievements()

      // 自动领取成就
      const dones = data.filter(item => item.status === 'done')
      try {
        dones.map(async item => {
          await TGASDK.module.Growth.service.getAchievementReward(item.id)
        })
      } catch (error) {}

      // 临时添加图标
      return data.map((item, index) => {
        item.icon = 'https://cdn2.j4fgame.com/resource/achievement/game_time.png'
        item.receivedIcon = 'https://cdn2.j4fgame.com/resource/achievement/game_time.png'

        return item
      })
    }
  })
}

export { useCheckinData, useCheckinStatus, useTasksWithGroup, useTaskStatus, useAchievements }
