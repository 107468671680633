import React from 'react'
import { twMerge } from 'tailwind-merge'

const Message: React.FC<PropType> = ({ type, children, className }) => {
  const classNames = twMerge(
    'text-center',
    type === 'success' ? 'text-semantic-success' : type === 'error' ? 'text-semantic-error' : 'text-semantic-warning'
  )

  return <div className={classNames}>{children}</div>
}

interface PropType {
  type: 'success' | 'error' | 'warn'
  children: React.ReactNode
  className?: string
}

export default Message
