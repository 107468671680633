// import { CustomError } from '@kulee/helper'
// import { RESPONSE_CODES } from '@kulee/tga-constant'
import ReconnectingWebSocket from 'reconnecting-websocket'
import type { Event } from 'reconnecting-websocket'

import BaseModule from '../../BaseModule'
import PKGameService from './Service'
import logger from '../../config/logger'
import { CMDS, DEFAULT_USER } from './constant'
import Convert from './Convert'

import type { BaseGameType } from '../../types/game'
import type { GameRoomInfo, PKGameContext, WSMessage } from '../../types/PKGame'
import type { UserInfo } from '../../types/uc'

class PKGame extends BaseModule {
  public static override clazz = 'PKGame'

  public service!: PKGameService

  public override run(): void {
    logger.log(`run module ${PKGame.clazz}`)

    this.service = new PKGameService(this.context)
  }

  private user!: UserInfo
  private gameInfo!: BaseGameType
  private gameRoom!: GameRoomInfo

  private websocket: ReconnectingWebSocket | null = null
  private heartBitTimeID: ReturnType<typeof setTimeout> | null = null
  private heartBitStartTime: number | null = null

  private userOfflineTime = 0
  private userQuitTime = 0

  // ws连接成功回调
  public onWSOpen: () => void = () => {}
  // ws连接关闭回调
  public onWSClose: () => void = () => {}
  // 房间信息变化回调
  public onGameRoomChange: (gameRoom: GameRoomInfo) => void = () => {}
  // 房间号变化回调
  public onGameRoomNoChange: (gameRoom: GameRoomInfo) => void = () => {}
  // ws ping回调
  public onWSHeartBit: (time: number) => void = () => {}
  // 匹配成功回调
  public onMatch: (payload: GameRoomInfo) => void = () => {}
  // 玩家准备回调
  public onAllUserReady: (payload: GameRoomInfo) => void = () => {}
  // 玩家准备回调
  public onUserCancelReady: (payload: GameRoomInfo) => void = () => {}
  // 开始倒计时
  public onGameCountdown: (payload: GameRoomInfo) => void = () => {}
  // 玩家加注
  public onBetDouble: (payload: GameRoomInfo) => void = () => {}
  // 加载游戏
  public onGameLoad: (payload: GameRoomInfo) => void = () => {}
  // 游戏完成，开始游戏
  public onGameStart: (payload: GameRoomInfo) => void = () => {}
  // 游戏结束
  public onGameFinish: (payload: GameRoomInfo) => void = () => {}
  // 退出游戏回调
  public onGameQuit: (time: number) => void = () => {}
  // 切换游戏回调
  public onGameChange: (payload: GameRoomInfo) => void = () => {}
  // 游戏用户离线
  public onGameUserOffline: (time: number) => void = () => {}
  // 游戏用户在
  public onGameUserOnline: (payload: GameRoomInfo) => void = () => {}
  // 进入房间回调
  public onGameRoomEnter: (payload: GameRoomInfo) => void = () => {}
  // 退出房间回调
  public onGameRoomQuit: (payload: GameRoomInfo) => void = () => {}
  // 创建游戏
  public onGameCreate: (payload: GameRoomInfo) => void = () => {}
  // 关闭对局回调
  public onGameClose: (payload: GameRoomInfo) => void = () => {}

  public async init(): Promise<void> {
    this.user = await this.SDK.module.UC.getUserInfo()

    this.resetGameRoom()
  }

  public setGame(gameInfo: BaseGameType) {
    this.gameInfo = gameInfo
  }

  public getContext(): PKGameContext {
    return {
      gameRoom: this.gameRoom,
      userOfflineTime: this.userOfflineTime,
      userQuitTime: this.userQuitTime
    }
  }

  /**
   * 匹配
   */
  public async match() {
    this.resetGameRoom()

    return this.service.match({ gameId: this.gameInfo.id })
  }

  /**
   * 创建房间
   * @param roomNo 房间号
   */
  public async createGameRoom(roomNo: string) {
    this.resetGameRoom(roomNo)
    return this.service.createGameRoom(roomNo)
  }

  /**
   * 进入房间
   * @param roomNo 房间号
   */
  public enterGameRoom(roomNo: string) {
    this.resetGameRoom(roomNo)

    return this.service.enterGameRoom({
      gameId: this.gameInfo.id,
      roomNo
    })
  }

  /**
   * 创建游戏对局
   * @param roomNo 房间号
   */
  public createGameBattle() {
    this.sendMessage({
      cmd: CMDS.CREATE_GAME_BATTLE,
      payload: {
        roomNo: this.gameRoom.roomNo
      }
    })
  }

  /**
   * 关闭对局
   */
  public closeGameBattle() {
    this.sendMessage({
      cmd: CMDS.CLOSE_GAME_BATTLE
    })
  }

  /**
   * 退出
   */
  public async quit() {
    if (this.gameRoom.status === 'none') {
      await this.service.quitMatch()
      this.gameRoom.roomNo && (await this.service.quitGameRoom(this.gameRoom.roomNo))
    } else {
      await this.service.quitGame()
    }
  }

  public async quitAndCloseWS() {
    await this.quit()
    this.closeWs()
  }

  // /**
  //  * 退出匹配
  //  */
  // private async quitMatch() {
  //   this.sendMessage({
  //     cmd: CMDS.MATCH_QUIT
  //   })
  // }

  // /**
  //  * 退出游戏
  //  */
  // private async quitGame() {
  //   this.sendMessage({
  //     cmd: CMDS.GAME_QUIT
  //   })
  // }

  // /**
  //  * 退出房间
  //  */
  // private quitGameRoom() {
  //   this.gameRoom.roomNo &&
  //     this.sendMessage({
  //       cmd: CMDS.ROOM_QUIT,
  //       payload: {
  //         roomNo: this.gameRoom.roomNo
  //       }
  //     })
  // }

  /**
   * 用户准备
   */
  public userReady() {
    this.sendMessage({
      cmd: CMDS.USER_READY
    })
  }

  /**
   * 用户取消准备
   */
  public userCancelReady() {
    this.sendMessage({
      cmd: CMDS.USER_CANCEL_READY
    })
  }

  /**
   * 开始游戏倒计时，房主调
   */
  public gameCountDown() {
    if (this.gameRoom.owner === this.user.userId) {
      this.sendMessage({
        cmd: CMDS.GAME_COUNTDOWN
      })
    }
  }

  /**
   * 倒计时完成，可以开始加载游戏
   */
  public gameCountDownFinish(): void {
    this.sendMessage({
      cmd: CMDS.GAME_COUNTDOWN_FINISH
    })
  }

  /**
   * 加注
   */
  public betDouble(): void {
    this.sendMessage({
      cmd: CMDS.BET_DOUBLE
    })
  }

  /**
   * 游戏加载完成，准备就绪
   */
  public gameReady(): void {
    this.sendMessage({
      cmd: CMDS.GAME_LOADED
    })
  }

  /**
   * 游戏结束
   */
  public gameFinish(winner: string): void {
    this.sendMessage({
      cmd: CMDS.GAME_FINISH,
      payload: {
        userIds: winner
      }
    })
  }

  /**
   * 转发游戏数据
   */
  public sendGameData(data: string, exceptSelf = false): void {
    this.sendMessage({
      cmd: CMDS.GAME_DATA,
      payload: {
        data,
        exceptSelf
      }
    })
  }

  /**
   * 切换游戏
   */
  public gameChange(gameId: number): void {
    this.sendMessage({
      cmd: CMDS.GAME_CHANGE,
      payload: {
        gameId
      }
    })
  }

  public async initWS() {
    return new Promise(resolve => {
      this.closeWs()

      const wsUrl = this.service.getWSUrl()
      const ws = new ReconnectingWebSocket(wsUrl)

      ws.onopen = event => {
        this.websocket = ws

        this.handleWSOpen(event)
        resolve(null)
      }
      ws.onmessage = this.handleWSMessage.bind(this)
      ws.onerror = this.handleWSError.bind(this)
      ws.onclose = event => {
        this.websocket = null

        this.handleWSClose(event)
      }
    })
  }

  public closeWs() {
    if (this.heartBitTimeID) {
      clearInterval(this.heartBitTimeID)
      this.heartBitStartTime = null
    }
    if (this.websocket) {
      this.websocket.close()
    }
  }

  private handleWSOpen = (event: Event) => {
    logger.log('ws onopen', event)
    this.ping()
    this.onWSOpen()
  }

  private handleWSError = (event: Event) => {
    logger.log('ws onerror', event)
  }

  private handleWSClose = (event: Event) => {
    logger.log('ws onclose', event)
    this.onWSClose()
  }

  private ping() {
    if (this.heartBitTimeID) {
      clearInterval(this.heartBitTimeID)
      this.heartBitStartTime = null
    }

    this.heartBitTimeID = setInterval(() => {
      this.heartBitStartTime = new Date().getTime()
      this.sendMessage({
        module: 'SYSTEM',
        cmd: CMDS.HEART_BIT
      })
    }, 2000)
  }

  private resetGameRoom(roomNo = '') {
    if (this.gameRoom?.roomNo === roomNo) {
      return
    }

    this.userOfflineTime = 0
    this.userQuitTime = 0

    const { userId, nickname, avatar } = this.user
    this.setGameRoom({
      roomNo,
      gameId: this.gameInfo?.id || '',
      status: 'none',
      owner: '',
      allReady: false,
      allBet: false,
      full: false,
      teams: [
        {
          id: '1',
          user: [{ ...DEFAULT_USER, userId, nickname, avatar, role: 'admin' }]
        },
        {
          id: '2',
          user: [DEFAULT_USER]
        }
      ]
    })
  }

  private sendMessage<T>({
    cmd,
    module = 'BATTLE',
    payload
  }: {
    cmd: CMDS
    module?: 'BATTLE' | 'SYSTEM'
    payload?: T
  }) {
    const data = {
      module,
      cmd,
      payload
    }
    cmd !== CMDS.HEART_BIT && logger.log(`send message ${cmd}, payload:`, data)

    if (this.websocket) {
      this.websocket.send(JSON.stringify(data))
    } else {
      logger.error('init websocket first')
    }
  }

  private handleWSMessage = (event: MessageEvent) => {
    const data: WSMessage = JSON.parse(event.data)
    const { cmd, payload: payloadStr = null } = data

    const payload = JSON.parse(payloadStr)
    if (
      [CMDS.HEART_BIT, CMDS.MATCH_SUCCESS].indexOf(cmd) < 0 &&
      payload &&
      payload.roomNo &&
      payload.roomNo !== this.gameRoom.roomNo
    ) {
      logger.warn(`ignore: get message ${cmd}, payload:`, payload)
      return
    }

    if (payload?.code) {
      logger.error(`message error`, payload)
      return
    }

    cmd !== CMDS.HEART_BIT && logger.log(`get message ${cmd}, payload:`, payload)

    switch (cmd) {
      // 心跳
      case CMDS.HEART_BIT:
        this.onWSHeartBit(new Date().getTime() - (this.heartBitStartTime || 0))
        break
      // 匹配成功
      case CMDS.MATCH_SUCCESS:
        this.handleGameRoomData(payload, this.onMatch)
        break
      // 进入房间回调
      case CMDS.ROOM_ENTER:
        this.handleGameRoomData(this.specialPayloadToNormal(payload), this.onGameRoomEnter)
        break
      // 退出房间回调
      case CMDS.ROOM_QUIT:
        this.handleGameRoomData(this.specialPayloadToNormal(payload), this.onGameRoomQuit)
        break
      // 创建游戏回调
      case CMDS.CREATE_GAME_BATTLE:
        this.handleGameRoomData(payload, this.onGameCreate)
        break
      // 关闭对局回调
      case CMDS.CLOSE_GAME_BATTLE:
        this.handleGameRoomData(payload, this.onGameClose)
        break
      // 玩家准备
      case CMDS.USER_READY:
        this.handleUserReady(payload)
        break
      // 玩家取消准备
      case CMDS.USER_CANCEL_READY:
        this.handleGameRoomData(payload, this.onUserCancelReady)
        break
      // 开始倒计时
      case CMDS.GAME_COUNTDOWN:
        this.handleGameRoomData(payload, this.onGameCountdown)
        break
      // 玩家加注
      case CMDS.BET_DOUBLE:
        this.handleGameRoomData(payload, this.onBetDouble)
        break
      // 加载游戏
      case CMDS.GAME_LOAD:
        this.handleGameRoomData(payload, this.onGameLoad)
        break
      // 可以开始游戏
      case CMDS.GAME_START:
        this.handleGameRoomData(payload, this.onGameStart)
        break
      // 游戏结束
      case CMDS.GAME_FINISH:
        this.handleGameRoomData(payload, this.onGameFinish)
        break
      // 退出游戏
      case CMDS.GAME_QUIT:
        this.handleUserQuit(payload)
        break
      // 转发游戏数据
      case CMDS.GAME_DATA:
        this.SDK.module.GameInteraction.sendBattleGameData(payload.data)
        break
      // 切换游戏
      case CMDS.GAME_CHANGE:
        this.handleGameRoomData(payload, this.onGameChange)
        break
      // 用户离线回调
      case CMDS.GAME_USER_OFFLINE:
        this.handleUserOffline(payload)
        break
      // 用户在线回调
      case CMDS.GAME_USER_ONLINE:
        this.handleUserOnline(payload)
        break

      default:
        break
    }
  }

  private setGameRoom(room: GameRoomInfo) {
    const preRoomNo = this.gameRoom?.roomNo || ''
    this.gameRoom = room

    this.onGameRoomChange(this.gameRoom)
    if (preRoomNo !== room.roomNo) {
      this.onGameRoomNoChange(this.gameRoom)
    }
  }

  private handleGameRoomData(payload: PKGameDataNS.GameRoomInfo, handler?: RoomChangeHandler): GameRoomInfo {
    try {
      this.setGameRoom(Convert.gameRoomInfoToClient(payload, this.user))

      handler && handler(this.gameRoom)
    } catch (error) {
      logger.error('handle gameroom data error', error)
    }

    return this.gameRoom
  }

  private handleUserReady(payload: PKGameDataNS.GameRoomInfo): GameRoomInfo {
    const gameRoom = this.handleGameRoomData(payload)
    if (gameRoom.allReady) {
      this.onAllUserReady(gameRoom)
    }

    return gameRoom
  }

  private handleUserOffline(payload: PKGameDataNS.GameRoomInfo): GameRoomInfo {
    const gameRoom = this.handleGameRoomData(payload)

    this.userOfflineTime = new Date().getTime()
    this.onGameUserOffline(this.userOfflineTime)

    return gameRoom
  }

  private handleUserOnline(payload: PKGameDataNS.GameRoomInfo): GameRoomInfo {
    const gameRoom = this.handleGameRoomData(payload)

    this.userOfflineTime = 0
    this.onGameUserOnline(gameRoom)

    return gameRoom
  }

  private handleUserQuit(payload: PKGameDataNS.GameRoomInfo): GameRoomInfo {
    const gameRoom = this.handleGameRoomData(payload)

    this.userQuitTime = new Date().getTime()
    this.onGameQuit(this.userQuitTime)

    return gameRoom
  }

  private specialPayloadToNormal(payload: PKGameDataNS.GameRoomEnterPayload): PKGameDataNS.GameRoomInfo {
    const {
      roomNo,
      userIds,
      userInfos,
      detail: { gameId, gameMode }
    } = payload

    const defaultUser: PKGameDataNS.PKGameUser = {
      header: '',
      name: '',
      id: '',
      txnId: '',
      online: true,
      bet: false,
      bot: false,
      ready: false,
      role: 'default'
    }
    const user1: PKGameDataNS.PKGameUser = userInfos[0]
      ? {
          ...defaultUser,
          ...userInfos[0]
        }
      : defaultUser
    const user2: PKGameDataNS.PKGameUser = userInfos[1]
      ? {
          ...defaultUser,
          ...userInfos[1]
        }
      : defaultUser

    return {
      id: '',
      gameId,
      roomNo,
      gameMode,
      status: -1,
      userIds,
      readyIds: '',
      betIds: '',
      teamsInfo: [
        {
          id: '1',
          score: 0,
          leaderId: '',
          userIds: '',
          userInfos: [user1]
        },
        {
          id: '2',
          score: 0,
          leaderId: '',
          userIds: '',
          userInfos: [user2]
        }
      ]
    }
  }
}

type RoomChangeHandler = (payload: GameRoomInfo) => void

export default PKGame
