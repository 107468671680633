import BaseModule from '../../BaseModule'
import logger from '../../config/logger'
import VIPService from './Service'

import type { UserVIPBenefit, VIPBenefitCode } from '../../types/vip'
class VIP extends BaseModule {
  public static override clazz = 'VIP'

  public service!: VIPService

  private userVipBenefits: UserVIPBenefit[] | null = null

  public override run(): void {
    logger.log(`run module ${VIP.clazz}`)

    this.service = new VIPService(this.context)
  }

  public async getUserVipBenefits(): Promise<UserVIPBenefit[]> {
    if (!this.userVipBenefits) {
      const { data } = await this.service.getUserVipBenefits()
      this.userVipBenefits = data
    }

    return this.userVipBenefits
  }

  public getBenefit(code: VIPBenefitCode) {
    if (this.userVipBenefits) {
      return this.userVipBenefits.find(item => item.code === code)
    }
    throw new Error('getUserVipBenefits first')
  }
}

export default VIP
