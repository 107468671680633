import React from 'react'
import { RouterProvider } from 'react-router-dom'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { PersistQueryClientProvider, persister, queryClient } from './config/reactQuery'
import { AuthProvider } from './component/provider/AuthProvider'
import { WalletProvider } from './component/provider/WalletProvider'
import { VIPProvider } from './component/provider/VIPProvider'
import router from './router'

function App() {
  return (
    <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
      <AuthProvider>
        <WalletProvider>
          <VIPProvider>
            <RouterProvider router={router} fallbackElement={<></>} />
          </VIPProvider>
        </WalletProvider>
      </AuthProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </PersistQueryClientProvider>
  )
}

export default App
