import axios, { AxiosInstance } from 'axios'
import { Cookies } from '@kulee/helper'
import { STORAGE_KEYS, getBaseHost, RESPONSE_CODES } from '@kulee/tga-constant'

import { getSign, handleError } from '../helper/request'
import logger from './logger'

let requestInstanceCache: null | AxiosInstance = null

function getRequestInstance(options: {
  env: string
  appId: string
  lang: string
  requestConfig?: RequestConfig
}): AxiosInstance {
  const { env, appId, lang, requestConfig = {} } = options
  const { error = () => {} } = requestConfig

  // 是否正在获取token
  let gettingToken = false
  let waitingCalls: Array<any> = []

  const axiosInstance = axios.create({
    timeout: 15000
  })

  axiosInstance.interceptors.request.use(
    config => {
      config.headers = config.headers || {}
      const accessToken = Cookies.get(STORAGE_KEYS.USER_ACCESS_TOKEN)
      // 时区名称
      const timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone

      accessToken && (config.headers.Authorization = accessToken)
      appId && (config.headers['appId'] = appId)
      lang && (config.headers['Accept-Language'] = lang)
      config.headers['x-timezone'] = timezoneName

      if (config.method && config.method.toLocaleLowerCase() === 'post') {
        // 获取 POST 接口准备发送的参数
        const postData = config.data
        if (postData) {
          const sign = getSign(postData)
          sign && (config.headers['sign'] = sign)
        }

        config.data = config.data || {}

        if (Object.prototype.toString.call(config.data) === '[object String]') {
          config.data = JSON.parse(config.data)
        }

        accessToken && (config.data.token = accessToken)
        appId && (config.data.appId = appId)
        lang && (config.data.lang = lang)
      }

      return config
    },
    err => {
      logger.warn('[request] request error')
      error(err)

      err.code = RESPONSE_CODES.UNKNOWN_ERROR
      err.message = 'request error'
      return Promise.reject(err)
    }
  )

  axiosInstance.interceptors.response.use(
    response => {
      const {
        data: { stateCode, code },
        config
      } = response

      if (config.url?.endsWith('.json')) {
        return {
          code: 200,
          data: response.data,
          msg: 'success',
          success: true,
          traceId: 'traceId',
          version: 'version'
        }
      }

      // 用户token失效
      const refreshToken = Cookies.get(STORAGE_KEYS.USER_REFRESH_TOKEN)
      if ((stateCode === -1 || code === 401) && refreshToken) {
        console.warn('[request] auto token invalid, ready to get token again.')

        return new Promise((resolve, reject) => {
          waitTokenRefreshAndCall({
            resolve,
            reject,
            config,
            data: response.data
          })
        })
      }

      // 非成功，全部reject
      // 判断stateCode不等于-1或者code不等于200/201
      if (stateCode !== 1 && code !== 200) {
        error(response.data)

        return Promise.reject(handleError(response.data))
      }
      // 抹平新老的接口差异
      if (response.data.resultInfo) {
        return response.data.resultInfo
      }

      return response.data
    },
    err => {
      logger.warn('[request] request error', err)
      const { data, config } = err.response

      const { stateCode, code } = data

      const refreshToken = Cookies.get(STORAGE_KEYS.USER_REFRESH_TOKEN)
      if ((stateCode === -1 || code === 401) && refreshToken) {
        logger.warn('error auto token invalid, ready to get token again.')

        return new Promise((resolve, reject) => {
          waitTokenRefreshAndCall({
            resolve,
            reject,
            config,
            data: err.response
          })
        })
      }

      error(err)

      return Promise.reject(handleError(data))
    }
  )

  async function waitTokenRefreshAndCall(options: { resolve: any; reject: any; config: any; data: any }) {
    waitingCalls.push(options)

    if (!gettingToken) {
      gettingToken = true
      try {
        await getToken()
        waitingCalls.forEach(({ resolve, config }) => {
          resolve(axiosInstance.request(config))
        })

        gettingToken = false
        waitingCalls = []
      } catch (error) {
        waitingCalls.forEach(({ reject, data }) => {
          reject(handleError(data))
        })
      }
    }
  }

  async function getToken() {
    try {
      try {
        // 在kuleWebview中
        const { token, refreshToken } = await window.Bridge.system.resetToken()
        Cookies.set(STORAGE_KEYS.USER_ACCESS_TOKEN, token)
        Cookies.set(STORAGE_KEYS.USER_REFRESH_TOKEN, refreshToken)
      } catch (error) {
        const refreshToken = Cookies.get(STORAGE_KEYS.USER_REFRESH_TOKEN)

        logger.log('loginWithFpId get token')
        const {
          data: { code, data }
        } = await axios.post(`${getBaseHost(env)}/api/v1/auth/token/refresh`, {
          refreshToken
        })
        if (code === 200) {
          Cookies.set(STORAGE_KEYS.USER_ACCESS_TOKEN, data.accessToken)
          Cookies.set(STORAGE_KEYS.USER_REFRESH_TOKEN, data.refreshToken)

          try {
            window.Bridge.system.resetToken({
              token: data.accessToken,
              refreshToken: data.refreshToken
            })
          } catch (error) {}
        } else {
          throw new Error('')
        }
      }
    } catch (error) {
      logger.warn('[request] get token error', error)

      Cookies.remove(STORAGE_KEYS.USER_ACCESS_TOKEN)
      Cookies.remove(STORAGE_KEYS.USER_REFRESH_TOKEN)

      throw error
    }
  }

  return axiosInstance
}

export default function (options: { env: string; appId: string; lang: string; requestConfig?: RequestConfig }) {
  if (requestInstanceCache === null) {
    requestInstanceCache = getRequestInstance(options)
  }

  return requestInstanceCache
}

type RequestConfig = {
  error?: (error: Error & ResponseError) => void
}

interface ResponseError {
  success: boolean
  code: number
  msg: string
  traceId: string
  data: any
  stack: any
}
