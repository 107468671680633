import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { Wallet } from '@kulee/tga-sdk'

import TGASDK from '@/config/TGASDK'
import WalletContext from '@/context/WalletContext'
import useChargeCommodity from '@/hook/data/useChargeCommodity'
import DiamondCommodityItem from '../DiamondCommodityItem'
import Loading from '@/component/Loading'
import Message from '@/component/Message'

import type { BussnessOrderType } from '@kulee/tga-sdk/types/types/wallet'

const DiamondCommodityList: React.FC<PropType> = () => {
  const { t } = useTranslation('wallet')
  const { init: walletInit } = useContext(WalletContext)
  const { data: commodities } = useChargeCommodity('DIAMOND')
  const [orderStatus, setOrderStatus] = useState<'success' | 'error' | 'warn'>()

  const checkOrderMutation = useMutation({
    mutationFn: async (order: BussnessOrderType) => {
      const { data } = await TGASDK.module.Wallet.service.getChargeOrderDetail(order.orderNo)

      if (data.status === 'completed') {
        return data
      }

      throw new Error('')
    },
    retry: 3,
    retryDelay: 1000
  })

  const handlePaySuccess = (order: BussnessOrderType) => {
    checkOrderMutation.mutate(order, {
      onSuccess: () => {
        setOrderStatus('success')
        walletInit()
      },
      onError: error => {
        setOrderStatus('error')
      }
    })
  }

  useEffect(() => {
    TGASDK.addModule(Wallet)
  }, [])

  if (!commodities) {
    return <Loading className="h-40" />
  }

  return (
    <div>
      <h3 className="mb-sm">{t('title.diamondCharge')}</h3>
      <div className="grid grid-cols-3 gap-sm">
        {commodities?.map(item => <DiamondCommodityItem key={item.id} item={item} onPaySuccess={handlePaySuccess} />)}
      </div>
      {orderStatus && (
        <Message type={orderStatus} className="mb-sm">
          {orderStatus === 'warn'
            ? t('tip.orderChecking')
            : orderStatus === 'success'
              ? t('tip.orderSuccess')
              : t('tip.orderFail')}
        </Message>
      )}
    </div>
  )
}

interface PropType {}

export default DiamondCommodityList
