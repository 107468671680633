import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { VIP } from '@kulee/tga-sdk'

import TGASDK from '@/config/TGASDK'
import { east8TimestampToDateTime } from '@/helper'
import useStatePopup from '@/hook/useStatePopup'
import { PopupSelector } from '@/store/PopupStore'
import Loading from '@/component/Loading'
import type { VIPOrder } from '@kulee/tga-sdk/types/types/vip'

const VIPOrderDetailMain: React.FC<PropType> = () => {
  const { t } = useTranslation('vip')
  const { orderNo } = useStatePopup(PopupSelector.vipOrderDetailPopup)
  const [orderDetail, setOrderDetail] = useState<VIPOrder>()

  const init = async () => {
    if (!orderNo) {
      return
    }

    const { data } = await TGASDK.module.VIP.service.getOrderDetail(orderNo)
    setOrderDetail(data)
  }

  useEffect(() => {
    TGASDK.addModule(VIP)
    init()
  }, [])

  if (!orderDetail) {
    return <Loading className="h-40" />
  }

  return (
    <div className="mx-md flex flex-col gap-sm overflow-hidden rounded-lg bg-white p-md">
      <div>
        {t('tip.orderNo')}
        {orderDetail?.orderNo}
      </div>
      <div>
        {t('tip.payOrderNo')}
        {orderDetail?.payOrderNo}
      </div>
      <div>
        {t('tip.vipName')}
        {orderDetail?.vipCommodityName} - {orderDetail?.vipName}
      </div>
      <div>
        {t('tip.payment')}
        {orderDetail?.paymentCode}
      </div>
      <div>
        {t('tip.priceTotal')}
        {orderDetail?.amount}
      </div>
      <div>
        {t('tip.orderTime')}
        {orderDetail?.createTime && east8TimestampToDateTime(orderDetail?.createTime)}
      </div>
      <div>
        {t('tip.expireAt')}
        {t('tip.expireDay', { count: orderDetail?.expireDay })}
      </div>
    </div>
  )
}

interface PropType {}

export default VIPOrderDetailMain
