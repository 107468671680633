import React from 'react'
import { useTranslation } from 'react-i18next'

import useStatePopup from '@/hook/useStatePopup'
import { PopupSelector, PopUpStore } from '@/store/PopupStore'
import Drawer from '@/component/Drawer'
import VIPBenefitMain from './VIPBenefitMain'

const VIPBenefitPopup: React.FC<PropType> = () => {
  const { t } = useTranslation('vip')
  const vipBenefitPopupVisible = useStatePopup(PopupSelector.vipBenefitPopupVisible)

  const handleClose = () => {
    PopUpStore.setVIPBenefitPopupVisible(false)
  }

  return (
    <Drawer
      visible={vipBenefitPopupVisible}
      zIndex={903}
      onClose={handleClose}
      lockScroll={true}
      destroyOnClose
      className="bg-primary-light"
      title={t('modalTitle.benefit')}
    >
      <VIPBenefitMain />
    </Drawer>
  )
}

interface PropType {}

export default VIPBenefitPopup
