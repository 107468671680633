class AdLimiter {
  private static GREY_NUMBER_KEY = 'TGA-AD-LIMITER'
  private static GREY_TOTAL = 10000

  public static check(limit = 1): boolean {
    const limitMax = limit * this.GREY_TOTAL
    let greyNum = window.localStorage.getItem(this.GREY_NUMBER_KEY)

    if (!greyNum) {
      const randomNum = this.getRandomInt(1, this.GREY_TOTAL).toString()
      window.localStorage.setItem(this.GREY_NUMBER_KEY, randomNum)
      greyNum = randomNum
    }

    return parseInt(greyNum as string) <= limitMax
  }

  private static getRandomInt = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }
}

export default AdLimiter
