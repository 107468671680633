import React from 'react'
import { twMerge } from 'tailwind-merge'

import LoadingIcon from '../Loading/LoadingIcon'

const Button: React.FC<PropType> = ({
  children,
  icon,
  loading,
  disabled,
  size = 'middle',
  type = 'default',
  className,
  onClick,
  ...rest
}) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    !loading && onClick && onClick(e)
  }

  const classNames = twMerge(
    'inline-flex items-center justify-center gap-2 text-nowrap rounded-full px-8',
    size === 'xsmall' && 'font-normal text-xs px-3 h-5',
    size === 'small' && 'font-normal text-sm px-3 h-7',
    size === 'middle' && 'font-semibold text-base h-9',
    size === 'large' && 'font-semibold text-base h-12',
    type === 'primary' && 'bg-btncolor-primary text-btncolor-primarytext hover:bg-opacity-80 active:bg-opacity-90',
    type === 'secondary' &&
      'bg-btncolor-secondary text-btncolor-secondarytext hover:bg-opacity-80 active:bg-opacity-90',
    type === 'default' && 'border border-btncolor-primary text-btncolor-primary',
    type === 'link' && 'bg-inherit hover:bg-inherit active:bg-inherit text-btncolor-primary',
    type === 'variety' && 'bg-gradient-to-r from-[#f08130] to-[#f83f9c] text-white',
    disabled &&
      'bg-[#e1e2e5] text-[#c3c4ca] border-inherit hover:bg-inherit hover:bg-opacity-inherit active:bg-opacity-inherit hover:border-inherit hover:text-black/20',
    loading && 'opacity-65',
    className
  )

  return (
    <button type="button" className={classNames} {...rest} disabled={disabled} onClick={handleClick}>
      {loading && <LoadingIcon size="small" fill="fill-primary-text" />}
      {!loading && icon && icon}
      {children}
    </button>
  )
}

type PropType = Omit<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  'type'
> & {
  icon?: React.ReactNode
  loading?: boolean
  disabled?: boolean
  size?: 'xsmall' | 'small' | 'middle' | 'large'
  type?: 'primary' | 'secondary' | 'default' | 'link' | 'variety'
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export default Button
