import { createContext } from 'react'
import { WalletContextType } from '@/type/wallet'

const WalletContext = createContext<WalletContextType>({
  status: 'unready',
  wallet: {},
  init: () => new Promise(() => {})
})

export default WalletContext
