import React, { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

const Tab: React.FC<PropType> = ({
  items,
  activeKey,
  destroyInactive,
  className,
  barClassName,
  contentClassName,
  onChange
}) => {
  const [active, setActive] = useState<string>()

  const handleClick = (key: string) => {
    setActive(key)
    onChange && onChange(key)
  }

  useEffect(() => {
    typeof activeKey !== 'undefined' ? setActive(activeKey) : setActive(items[0]?.key)
  }, [activeKey])

  const classNames = twMerge('', className)
  const handlerClassNames = twMerge('mb-4 flex gap-sm', barClassName)
  const contentClassNames = twMerge(contentClassName)

  return (
    <div className={classNames}>
      <div className={handlerClassNames}>
        {items.map(item => (
          <div
            key={item.key}
            className={`text-primary-text relative cursor-pointer rounded-md rounded-t-md border bg-primary px-3 py-2 after:absolute after:-bottom-1 after:left-1/2 after:aspect-square after:w-5 after:-translate-x-1/2 after:rotate-45 after:bg-primary ${active === item.key ? 'grayscale-0' : 'opacity-70 grayscale after:content-none'}`}
            onClick={() => {
              handleClick(item.key)
            }}
          >
            <span className="relative z-10">{item.label}</span>
          </div>
        ))}
      </div>
      <div className={contentClassNames}>
        {items.map(item => {
          return destroyInactive && active !== item.key ? null : (
            <div key={item.key} className={active === item.key ? 'block' : 'hidden'}>
              {item.children}
            </div>
          )
        })}
      </div>
    </div>
  )
}

interface PropType {
  items: TabItem[]
  activeKey?: string
  destroyInactive?: boolean
  className?: string
  barClassName?: string
  contentClassName?: string
  onChange?: (activeKey: string) => void
}

export interface TabItem {
  label: string
  key: string
  children: React.ReactNode
}

export default Tab
