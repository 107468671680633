import { getBaseHost } from '@kulee/tga-constant'

import type { AxiosInstance } from 'axios'
import type { Context } from './types'

abstract class BaseService {
  protected context: Context
  protected appId: string
  protected appCode: string
  protected env: string
  protected lang: string
  protected request: AxiosInstance
  protected apiUrl: string

  constructor(context: Context) {
    this.context = context
    this.appId = context.appConfig.appId
    this.appCode = context.appConfig.appCode
    this.env = context.env
    this.lang = context.lang
    this.request = context.request
    this.apiUrl = `${getBaseHost(this.env)}/api`
  }
}

export default BaseService
