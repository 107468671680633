import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useTasksWithGroup } from '@/hook/data/useGrowth'
import Tab, { TabItem } from '@/component/Tab'
import Loading from '@/component/Loading'
import Empty from '@/component/Empty'
import TaskList from '../TaskList'

const TaskMain: React.FC<PropType> = () => {
  const { t } = useTranslation('growth')
  const { data: tasksWithGroup } = useTasksWithGroup()
  const [items, setItems] = useState<TabItem[]>()

  useEffect(() => {
    if (!tasksWithGroup) {
      return
    }

    const items = tasksWithGroup.map(item => {
      return {
        label: t(`taskType.${item.group.toLocaleLowerCase()}`),
        key: item.group,
        children: <TaskList tasks={item.tasks} />
      }
    })
    setItems(items)
  }, [tasksWithGroup])

  if (!items) {
    return <Loading className="h-40" />
  }

  if (items && items.length <= 0) {
    return <Empty />
  }

  return <Tab items={items} activeKey={items.length > 0 ? items[0].key : ''} />
}

interface PropType {}

export default TaskMain
