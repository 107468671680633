import { useTranslation } from 'react-i18next'

import useRuntime from './useRuntime'

const useWebTitle = () => {
  const { t } = useTranslation()
  const { channel } = useRuntime()

  return t(`web.title-${channel}`, t('web.title'))
}

export default useWebTitle
