import { CustomError } from '@kulee/helper'

import BaseService from '../../BaseService'
import logger from '../../config/logger'
import Convert from './Convert'
import { handleError } from '../../helper/request'

import type { CustomAxiosResponse } from '../../types'
import type { AchievementItemType, UserTaskStatus, TaskWithGroup, CheckinDataType } from '../../types/growth'

class GrowthService extends BaseService {
  /**
   * 获取带分组任务列表
   * @returns 带分组任务列表
   */
  public async getTasksWithGroup(): Promise<CustomAxiosResponse<TaskWithGroup[]>> {
    try {
      const res = await this.request.get<unknown, CustomAxiosResponse<ServerDataNS.TasksWithGroup[]>>(
        `${this.apiUrl}/v1/achievement/app/task/group`
      )

      return {
        ...res,
        data: res.data.map(item => {
          return {
            group: item.categoryTitle,
            tasks: Convert.tasksToClient(item.tasks)
          }
        })
      }
    } catch (error: any) {
      logger.error('get task with group error', error)
      throw new CustomError(handleError(error))
    }
  }

  /**
   * 领取任务奖励
   * @param taskId 任务ID
   * @returns null
   */
  public async getTaskReward(taskId: string): Promise<CustomAxiosResponse<null>> {
    try {
      const res = await this.request.post<unknown, CustomAxiosResponse<null>>(
        `${this.apiUrl}/v1/achievement/app/task/claim/${taskId}`
      )

      return res
    } catch (error: any) {
      logger.warn('get task reward error', error)
      throw new CustomError(handleError(error))
    }
  }

  /**
   * 统计用户任务数据
   * @returns 用户任务统计数据
   */
  public async getTaskStatus(): Promise<CustomAxiosResponse<UserTaskStatus>> {
    try {
      const res = await this.request.get<unknown, CustomAxiosResponse<ServerDataNS.TaskStatus>>(
        `${this.apiUrl}/v1/achievement/app/task/sum`
      )

      return {
        ...res,
        data: {
          count: res.data.count || 0,
          rewards: Convert.rewardsToClient(res.data.rewards || [])
        }
      }
    } catch (error: any) {
      logger.error('get task status error', error)
      throw new CustomError(handleError(error))
    }
  }

  /**
   * 获取签到列表
   * @returns 签到列表
   */
  public async getCheckinData(): Promise<CustomAxiosResponse<CheckinDataType>> {
    try {
      const res = await this.request.get<unknown, CustomAxiosResponse<ServerDataNS.CheckinDayItem[]>>(
        `${this.apiUrl}/v1/achievement/app/check`
      )

      return {
        ...res,
        data: Convert.checkinDataToClient(res.data)
      }
    } catch (error: any) {
      logger.error('get checkin list error', error)
      throw new CustomError(handleError(error))
    }
  }

  /**
   * 签到
   * @param id 签到id
   * @returns
   */
  public async checkin(id: string): Promise<CustomAxiosResponse<null>> {
    try {
      const res = await this.request.post<unknown, CustomAxiosResponse<null>>(
        `${this.apiUrl}/v1/achievement/app/check/${id}`
      )

      return res
    } catch (error: any) {
      logger.warn('checkin error', error)
      throw new CustomError(handleError(error))
    }
  }

  /**
   * 统计用户签到
   * @returns 用户签到统计数据
   */
  public async getCheckinStatus(): Promise<CustomAxiosResponse<UserTaskStatus>> {
    try {
      const res = await this.request.get<unknown, CustomAxiosResponse<ServerDataNS.CheckinStatus>>(
        `${this.apiUrl}/v1/achievement/app/check/sum`
      )

      return {
        ...res,
        data: {
          count: res.data.sumCheckDays,
          rewards: Convert.rewardsToClient(res.data.sumRewards)
        }
      }
    } catch (error: any) {
      logger.error('get checkin status error', error)
      throw new CustomError(handleError(error))
    }
  }

  /**
   * 获取成就列表
   * @returns 成就列表
   */
  public async getAchievements(): Promise<CustomAxiosResponse<AchievementItemType[]>> {
    try {
      const res = await this.request.get<unknown, CustomAxiosResponse<ServerDataNS.AchievementItem[]>>(
        `${this.apiUrl}/v1/achievement/app/user-achievement-record`
      )

      return {
        ...res,
        data: Convert.achievementsToClient(res.data)
      }
    } catch (error: any) {
      logger.error('get achievement list error', error)
      throw new CustomError(handleError(error))
    }
  }

  /**
   * 领取成就奖励
   * @param id 成就ID
   */
  public async getAchievementReward(id: string): Promise<CustomAxiosResponse<null>> {
    try {
      const res = await this.request.post<unknown, CustomAxiosResponse<null>>(
        `${this.apiUrl}/v1/achievement/app/user-achievement-record/claim/${id}`
      )

      return res
    } catch (error: any) {
      logger.warn('get achievement reward error', error)
      throw new CustomError(handleError(error))
    }
  }
}

export default GrowthService
