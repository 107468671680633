import React from 'react'

import menus from '@/config/menu.json'
import type { NavItemType } from '@/type/common'
import NavItem from '../NavItem'

const MenuList: React.FC<PropType> = () => {
  const path = window.location.pathname
  const section = path.split('/').filter(Boolean).pop()

  return (
    <>
      {(menus as NavItemType[]).map((item, index) => {
        const newItem = { ...item, active: item.name === section }
        return <NavItem item={newItem} key={index} />
      })}
    </>
  )
}

interface PropType {}

export default MenuList
