import React from 'react'

import TaskItem from '../TaskItem'
import type { TaskItemType } from '@kulee/tga-sdk/types/types/growth'

const TaskList: React.FC<PropType> = ({ tasks }) => {
  return (
    <div className="max-h-[calc(100vh-7rem)] overflow-y-auto">
      {tasks.map(task => (
        <TaskItem key={task.id} task={task} />
      ))}
    </div>
  )
}

interface PropType {
  tasks: TaskItemType[]
}

export default TaskList
