const env = (process.env.SDP_ENV || 'development') as EnvType

const getClient = (width?: number) => {
  return (width ? width : document.body.clientWidth) < 640 ? 'mobile' : 'pc'
}

const addParamsToUrl = (url: string, params: { [key: string]: string }) => {
  try {
    const urlObj = new URL(url)
    const searchParams = urlObj.searchParams

    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        const value = params[key]
        searchParams.append(key, value)
      }
    }

    return urlObj.toString()
  } catch (error) {
    return url
  }
}

const getFileNameFromUrl = (url?: string): string => {
  if (!url) {
    return ''
  }

  const arr = url.split('/') || []
  return arr[arr.length - 1].split('.')[0]
}

const east8TimestampToLocal = (east8Timestamp: number): number => {
  const east8Date = new Date(east8Timestamp)
  const timeDifferenceInMilliseconds = (-8 * 60 - new Date().getTimezoneOffset()) * 60 * 1000
  const localTimestamp = east8Date.getTime() - timeDifferenceInMilliseconds
  return localTimestamp
}

const east8TimestampToDateTime = (east8Timestamp: number): string => {
  const date = new Date(east8TimestampToLocal(east8Timestamp))
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const seconds = String(date.getSeconds()).padStart(2, '0')

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

const timeRemaining = (utcTimestamp: number, expireSeconds: number): number => {
  const timestamp = east8TimestampToLocal(utcTimestamp)
  const currentTimestamp = new Date().getTime()
  const expirationTime = timestamp + expireSeconds * 1000
  const remainingTime = expirationTime - currentTimestamp

  return remainingTime <= 0 ? 0 : Math.floor(remainingTime / 1000)
}

const coverUrlToFit = (url: string, size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl'): string => {
  const newUrl = url.replace(/_\d+x\d+\.(jpg|png)/, '.$1')

  if (['xs', 'sm'].indexOf(size) >= 0) {
    return newUrl.replace(/\.(jpg|png)$/, '_640x320.$1')
  }
  return newUrl
}

const generateUniqueId = (): string => {
  const timestamp = Date.now().toString()
  const randomNum = Math.floor(Math.random() * 1000000)
    .toString()
    .padStart(6, '0')

  return timestamp.slice(-10) + randomNum.slice(-6)
}

const filterObject = <T extends object>(
  obj: T,
  predicate: <K extends keyof T>(value: T[K], key: K) => boolean
): { [K in keyof T]?: T[K] } => {
  const result: { [K in keyof T]?: T[K] } = {}
  ;(Object.keys(obj) as Array<keyof T>).forEach(name => {
    if (predicate(obj[name], name)) {
      result[name] = obj[name]
    }
  })
  return result
}

export {
  env,
  getClient,
  addParamsToUrl,
  getFileNameFromUrl,
  east8TimestampToDateTime,
  timeRemaining,
  coverUrlToFit,
  generateUniqueId,
  filterObject
}
