import React from 'react'
import { twMerge } from 'tailwind-merge'

import { ReactComponent as LoadingSVG } from '@/asset/svg/loading.svg'

const LoadingIcon: React.FC<PropType> = ({ size = 'middle', fill = 'text-primary-dark' }) => {
  const config = {
    small: 'w-5',
    middle: 'w-8',
    large: 'w-10'
  }

  const classNames = twMerge('aspect-square animate-spin', fill, `${config[size] || config['middle']}`)

  return <LoadingSVG className={classNames} />
}

export interface PropType {
  size?: 'small' | 'middle' | 'large'
  fill?: string
}
export default LoadingIcon
