import React, { useContext } from 'react'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import TGASDK from '@/config/TGASDK'
import WalletContext from '@/context/WalletContext'
import { useCheckinData } from '@/hook/data/useGrowth'
import { queryClient } from '@/config/reactQuery'
import { QUERY_KEYS } from '@/config/constant'
import Loading from '@/component/Loading'
import BlankCard from '@/component/card/BlankCard'
import DayItem from '../DayItem'
import Button from '@/component/Button'

const CheckinMain: React.FC<PropType> = () => {
  const { t } = useTranslation('growth')
  const { init } = useContext(WalletContext)
  const { isFetching, data: checkinData } = useCheckinData()

  const mutation = useMutation({
    mutationFn: (id: string) => {
      return TGASDK.module.Growth.service.checkin(id)
    }
  })

  const handleCheckinClick = async () => {
    if (!checkinData) {
      return
    }

    mutation.mutate(checkinData.todayId, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.CHECKIN_STATUS]
        })
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.CHECKINS]
        })

        init()
      }
    })
  }

  if (!checkinData) {
    return <Loading className="h-40" />
  }

  return (
    <>
      <BlankCard className="mb-md p-md">
        <div className="grid grid-cols-3 gap-sm">
          {checkinData.items.map(item => (
            <DayItem key={item.id} item={item} today={checkinData.todayId === item.id} />
          ))}
        </div>
      </BlankCard>
      <Button
        type="primary"
        className="w-full"
        onClick={handleCheckinClick}
        disabled={isFetching || checkinData.todayDone}
        loading={mutation.isPending}
      >
        {t('btn.checkin')}
      </Button>
    </>
  )
}

interface PropType {}

export default CheckinMain
