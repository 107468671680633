import React, { useContext, useEffect } from 'react'

import useProvideVIP from '@/hook/useProvideVIP'
import VIPContext from '@/context/VIPContext'
import AuthContext from '@/context/AuthContext'

const VIPProvider: React.FC<PropType> = ({ children }) => {
  const auth = useContext(AuthContext)
  const vip = useProvideVIP()

  useEffect(() => {
    if (auth.status === 'authorized') {
      // VIP初始化
      vip.init()
    }
  }, [auth.status])

  return <VIPContext.Provider value={vip}>{children}</VIPContext.Provider>
}

interface PropType {
  children: React.ReactNode
}

export { VIPProvider }
