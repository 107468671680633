import type {
  AchievementItemType,
  CheckinDataType,
  CheckinDayItemType,
  RewardItem,
  TaskItemType,
  TaskLinkType
} from '@/types/growth'

class Convert {
  /**
   * 任务数据转换
   * @param task 服务端任务数据
   * @returns 客户端任务数据
   */
  public static taskToClient(task: ServerDataNS.TaskItem): TaskItemType {
    const { id, title, rewards, progresses, status } = task

    const obj = this.parseProgresses(progresses)

    return {
      id,
      title,
      rewards,
      progresses: obj.progresses,
      status: status === 0 ? 'undone' : status === 1 ? 'done' : 'received',
      link: obj.link
    }
  }

  /**
   * 任务列表数据转换
   * @param tasks 服务端任务列表数据
   * @returns 客户端任务列表数据
   */
  public static tasksToClient(tasks: ServerDataNS.TaskItem[]): TaskItemType[] {
    return tasks.map(task => this.taskToClient(task))
  }

  /**
   * 签到列表数据转换
   * @param checkins 服务端签到列表数据
   * @returns 客户端签到列表数据
   */
  public static checkinDataToClient(checkins: ServerDataNS.CheckinDayItem[]): CheckinDataType {
    let todayId = ''
    let todayDone = true
    let lastId = ''

    const items = checkins.map((checkin, index) => {
      const { id, title, status, reward } = checkin

      // 当日可签到
      if (status === 1) {
        todayDone = false
        todayId = id
      }
      if (index === 0) {
        lastId = id
      }
      if (status === 2) {
        lastId = id
      }

      return {
        id,
        title,
        status: status === 0 ? 'undone' : status === 1 ? 'done' : 'received',
        rewards: reward
      } as CheckinDayItemType
    })

    return {
      todayId: todayId || lastId,
      todayDone,
      items
    }
  }

  /**
   * 成就数据转换
   * @param achievement 服务端成就数据
   * @returns 客户端成就数据
   */
  public static achievementToClient(achievement: ServerDataNS.AchievementItem): AchievementItemType {
    const { id, title, icon, iconClaimed, progresses, status } = achievement
    const obj = this.parseProgresses(progresses)

    return {
      id,
      title,
      icon,
      receivedIcon: iconClaimed,
      progresses: obj.progresses,
      status: status === 0 ? 'undone' : status === 1 ? 'done' : 'received',
      link: obj.link
    }
  }

  /**
   * 成就列表数据转换
   * @param achievements 服务端成就列表数据
   * @returns 客户端成就列表数据
   */
  public static achievementsToClient(achievements: ServerDataNS.AchievementItem[]): AchievementItemType[] {
    return achievements.map(achievement => this.achievementToClient(achievement))
  }

  /**
   * 奖励数据转换
   * @param rewards 服务端奖励数据
   * @returns 客户端奖励数据
   */
  public static rewardsToClient(rewards: ServerDataNS.RewardItem[]): RewardItem[] {
    if (rewards.length === 0) {
      return [
        {
          code: 'GOLD',
          count: 0,
          title: ''
        }
      ]
    }
    return rewards
  }

  private static parseProgresses(progresses: ServerDataNS.Progress[]): { link: TaskLinkType; progresses: string[] } {
    const progressesAfter: string[] = []
    let link: TaskLinkType = null

    progresses.map(progress => {
      progressesAfter.push(progress.title)
      if (progress.status === 0 && !link) {
        link = {
          code: progress.code
        }
        if (progress.code.startsWith('PLAY_GAME') && progress.rule.PLAY_GAME_CODE) {
          const codes = progress.rule.PLAY_GAME_CODE.split(',')
          link.param = {
            code: codes[Math.floor(Math.random() * codes.length)] as string
          }
        }
      }
      return progress
    })

    return {
      link,
      progresses: progressesAfter
    }
  }
}

export default Convert
