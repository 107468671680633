import { browser } from '@kulee/helper'
import { AD_CHANNEL_CODE, Applovin, KuleAd } from '@kulee/ad'

import AdLimiter from '../../helper/AdLimiter'
import BaseModule from '../../BaseModule'
import logger from '../../config/logger'
import type { ShowInterstitialAdOptions, ShowPrerollAdOptions, ShowRewardAdOptions } from '@kulee/ad/types/types'

class Ad extends BaseModule {
  public static override clazz = 'Ad'

  private kuleAd!: KuleAd

  private free = false

  public override run(): void {
    logger.log(`run module ${Ad.clazz}`)
  }

  public setFree(value: boolean) {
    this.free = value
  }

  /**
   * 获取游戏广告配置
   * @returns 游戏广告配置
   */
  public getGameAdConfig() {
    const gameAdConfig = browser.version.kuleWebview
      ? browser.version.android
        ? this.context.appConfig.gameAdConfig['android']
        : this.context.appConfig.gameAdConfig['ios']
      : this.context.appConfig.gameAdConfig['web']

    return {
      ...gameAdConfig,
      free: this.free
    }
  }

  public async init(): Promise<boolean> {
    const gameAdConfig = this.getGameAdConfig()
    const {
      channel: { channelCode }
    } = gameAdConfig

    // google AFG广告游戏中处理
    if (channelCode === AD_CHANNEL_CODE.GOOGLE_AFG) {
      logger.log('google AFG channel, no need to init kulee ad sdk.')
      return true
    }

    if (channelCode === AD_CHANNEL_CODE.APPLOVIN) {
      this.kuleAd = new KuleAd({
        channel: new Applovin(),
        gameAdConfig
      })
    }

    if (this.kuleAd) {
      return this.kuleAd.init()
    }

    logger.warn('none game ad config or ad is not enabled.')
    return false
  }

  public showPrerollAd(options: ShowPrerollAdOptions = {}) {
    if (this.kuleAd) {
      this.kuleAd.showPrerollAd(options)
    }
  }

  public showInterstitialAd(options: ShowInterstitialAdOptions) {
    if (this.kuleAd) {
      return this.kuleAd.showInterstitialAd(options)
    }
  }

  public showRewardAd(options: ShowRewardAdOptions) {
    if (this.kuleAd) {
      return this.kuleAd.showRewardAd(options)
    }
  }

  /**
   * 广告限流
   * @param limit 0-1
   * @returns 是否限流
   */
  public adLimiter(limit?: number) {
    return AdLimiter.check(limit)
  }
}

export default Ad
