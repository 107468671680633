import React from 'react'
import { twMerge } from 'tailwind-merge'

import LoadingIcon, { PropType as LoadingIconPropType } from './LoadingIcon'

const Loading: React.FC<PropType> = ({ className, ...rest }) => {
  const classNames = twMerge('flex h-full w-full items-center justify-center', className)

  return (
    <div className={classNames}>
      <LoadingIcon {...rest} />
    </div>
  )
}

type PropType = LoadingIconPropType & {
  className?: string
}

export default Loading
