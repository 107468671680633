import type { PKGameUser } from '@kulee/tga-sdk/types/types/PKGame'
import type { BaseGameType, GameDetailType } from '@kulee/tga-sdk/types/types/game'
import type { UserTaskStatus } from '@kulee/tga-sdk/types/types/growth'
import type { GameRankConfig, GameRankItem } from '@kulee/tga-sdk/types/types/rank'

const baseGame: BaseGameType = {
  id: '',
  name: '--',
  code: '',
  icon: ''
}
const fakeBaseGames = (total: number): BaseGameType[] => {
  return new Array(total).fill(baseGame)
}

const gameInfo: GameDetailType = {
  ...baseGame,
  desc: '',
  url: '',
  orientation: 'both',
  width: 0,
  height: 0,
  gameType: 'sdk',
  preload: true,
  tags: []
}

const rankConfig: GameRankConfig = {
  id: '',
  order: 0,
  name: '--',
  unit: '',
  desc: ''
}

const rankData: GameRankItem = {
  id: '',
  userId: '',
  nickname: '',
  avatar: '',
  point: '--',
  orderNumber: -1
}

const taskStatusData: UserTaskStatus = {
  count: 0,
  rewards: []
}

const pkGameUser: PKGameUser = {
  userId: '',
  nickname: '',
  avatar: '',
  role: '',
  bot: false,
  bet: false,
  online: true,
  ready: false
}

export { baseGame, fakeBaseGames, gameInfo, rankConfig, rankData, taskStatusData, pkGameUser }
