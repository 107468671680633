import BaseChannel from '../BaseChannel'
import { AD_CHANNEL_CODE, RESPONSE_CODES } from '../constant'
import logger from '../logger'

import type { ShowInterstitialAdOptions, ShowPrerollAdOptions, ShowRewardAdOptions } from '../types'

class Applovin extends BaseChannel<GameAdConfigNS.GameAdApplovinConfig> {
  private channelCode = AD_CHANNEL_CODE.APPLOVIN as 'applovin'

  public override async init(): Promise<boolean> {
    const {
      enabled,
      testEnabled,
      channel: { channelConfig }
    } = this.gameAdConfig

    if (!enabled || testEnabled || !channelConfig) {
      logger.error('game ad for applovin channel config error')
      return false
    }

    try {
      await window.Bridge.ads.init({
        type: this.channelCode,
        appKey: channelConfig.sdkKey
      })
      logger.info('applovin init success')
      // 加载开屏或者插屏广告
      this.loadPrerollAd() || this.loadInterstitialAd()
      // 加载激励广告
      this.loadRewardAd()

      return true
    } catch (error) {
      logger.error('applovin init error', error)
      return false
    }
  }

  public override async showPrerollAd(options: ShowPrerollAdOptions = {}) {
    const { name = '', onError = () => {}, onFinish = () => {} } = options

    const onFinishEnhance = () => {
      this.loadInterstitialAd()
      onFinish()
    }

    try {
      const { isReady } = await window.Bridge.ads.checkReady({
        type: this.channelCode,
        adsType: 1
      })

      if (isReady) {
        window.Bridge.ads.show({
          type: this.channelCode,
          showType: 1,
          name,
          callBack: (error, payload) => {
            if (error) {
              onError({
                code: RESPONSE_CODES.AD_UNKNOWN_ERROR
              })
              onFinishEnhance()
              return
            }

            this.handleAdCallback(payload, {
              ...options,
              onFinish: onFinishEnhance
            })
          }
        })
      } else {
        onError({
          code: RESPONSE_CODES.AD_RESOURCE_UNREADY
        })
        onFinishEnhance()
      }
    } catch (error) {
      onError({
        code: RESPONSE_CODES.AD_UNKNOWN_ERROR
      })
      onFinish()
    }
  }

  public override async showInterstitialAd(options: ShowInterstitialAdOptions) {
    const { name = '', onError = () => {}, onFinish = () => {} } = options

    const onFinishEnhance = () => {
      this.loadInterstitialAd()
      onFinish()
    }

    try {
      const { isReady } = await window.Bridge.ads.checkReady({
        type: this.channelCode,
        adsType: 1
      })

      if (isReady) {
        window.Bridge.ads.show({
          type: this.channelCode,
          showType: 1,
          name,
          callBack: (error, payload) => {
            if (error) {
              onError({
                code: RESPONSE_CODES.AD_UNKNOWN_ERROR
              })
              onFinishEnhance()
              return
            }

            this.handleAdCallback(payload, {
              ...options,
              onFinish: onFinishEnhance
            })
          }
        })
      } else {
        onError({
          code: RESPONSE_CODES.AD_RESOURCE_UNREADY
        })
        onFinishEnhance()
      }
    } catch (error) {
      onError({
        code: RESPONSE_CODES.AD_UNKNOWN_ERROR
      })
      onFinish()
    }
  }

  public override async showRewardAd(options: ShowRewardAdOptions) {
    const { name = '', beforePlay, onError = () => {}, onFinish = () => {} } = options

    const onFinishEnhance = () => {
      this.loadRewardAd()
      onFinish()
    }

    try {
      const { isReady } = await window.Bridge.ads.checkReady({
        type: this.channelCode,
        adsType: 2
      })

      if (isReady) {
        beforePlay(() => {
          window.Bridge.ads.show({
            type: this.channelCode,
            showType: 2,
            name,
            callBack: (error, payload) => {
              if (error) {
                onError({
                  code: RESPONSE_CODES.AD_UNKNOWN_ERROR
                })
                onFinishEnhance()
                return
              }

              this.handleAdCallback(payload, {
                ...options,
                onFinish: onFinishEnhance
              })
            }
          })
        })
      } else {
        onError({
          code: RESPONSE_CODES.AD_RESOURCE_UNREADY
        })
        onFinishEnhance()
      }
    } catch (error) {
      onError({
        code: RESPONSE_CODES.AD_UNKNOWN_ERROR
      })
      onFinish()
    }
  }

  private loadPrerollAd(): boolean {
    const {
      channel: {
        channelConfig: {
          prerollAd: { enabled, unitId }
        }
      }
    } = this.gameAdConfig

    if (enabled) {
      this.loadInterAd(unitId)

      return true
    }

    return false
  }

  private loadInterstitialAd() {
    const {
      channel: {
        channelConfig: {
          interstitialAd: { enabled, unitId }
        }
      }
    } = this.gameAdConfig

    if (enabled) {
      this.loadInterAd(unitId)
    }
  }

  private async loadInterAd(unitId: string) {
    await window.Bridge.ads.load({
      type: this.channelCode,
      adsType: 1,
      unitId: unitId
    })
  }

  private async loadRewardAd() {
    const {
      channel: {
        channelConfig: {
          rewardAd: { enabled, unitId }
        }
      }
    } = this.gameAdConfig

    if (enabled) {
      await window.Bridge.ads.load({
        type: this.channelCode,
        adsType: 2,
        unitId
      })
    }
  }

  private handleAdCallback(
    payload: BridgeNS.AdCallbackRes,
    showAdOptions: Partial<ShowPrerollAdOptions & ShowInterstitialAdOptions & ShowRewardAdOptions>
  ) {
    const { action, state, isDisplay } = payload
    const { onPlay = () => {}, onFinish = () => {}, onReward = () => {} } = showAdOptions

    switch (action) {
      case 'video':
        // 广告开始播放
        if (state === 1) {
          logger.log('applovin ad start to play.')
        } else if (state === 2) {
          logger.log('applovin ad play end.')
        }
        break
      case 'userReward':
        logger.log('applovin ad user can get reward.')
        onReward()
        break
      case 'display':
        if (isDisplay === 1) {
          logger.log('applovin ad is display.')
          onPlay()
        } else if (isDisplay === 0) {
          logger.log('applovin ad is hide.')
          onFinish()
        }
        break
      case 'click':
        logger.log('applovin ad is clicked.')
        break
      default:
        break
    }
  }
}

export default Applovin
