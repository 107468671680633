import React from 'react'
import { Link } from 'react-router-dom'

import useLinkTo from '@/hook/useLinkTo'

const LinkEnhance: React.FC<PropType> = ({ href, children, className, style }) => {
  const { getLink } = useLinkTo()
  const link = getLink(href)

  // TODO 原生SDK环境下新容器打开
  return (
    <Link to={link} className={className} style={style}>
      {children}
    </Link>
  )
}

type PropType = React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>

export default LinkEnhance
