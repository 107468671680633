import React, { useContext, useEffect } from 'react'

import useProvideWallet from '@/hook/useProvideWallet'
import WalletContext from '@/context/WalletContext'
import AuthContext from '@/context/AuthContext'

const WalletProvider: React.FC<PropType> = ({ children }) => {
  const auth = useContext(AuthContext)
  const wallet = useProvideWallet()

  useEffect(() => {
    if (auth.status === 'authorized') {
      // 钱包初始化
      wallet.init()
    }
  }, [auth.status])

  return <WalletContext.Provider value={wallet}>{children}</WalletContext.Provider>
}

interface PropType {
  children: React.ReactNode
}

export { WalletProvider }
