import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'

const InjectScript: React.FC<PropType> = () => {
  const { enabled, testEnabled, channel } = window.AppConfig.data.contentAdConfig

  const adSenseEnabled = enabled && channel?.channelCode === 'googleAFC'
  const client = testEnabled ? 'ca-pub-123456789' : channel?.channelConfig.clientId

  return (
    <HelmetProvider>
      <Helmet>
        {adSenseEnabled && !testEnabled && (
          <script
            async
            src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${client}`}
            crossOrigin="anonymous"
          ></script>
        )}
      </Helmet>
    </HelmetProvider>
  )
}

interface PropType {}

export default InjectScript
