import tags from '@/config/menuTag.json'
import type { NavItemType } from '@/type/common'
import React from 'react'
import NavItem from '../NavItem'

const TagList: React.FC<PropType> = () => {
  const path = window.location.pathname
  const section = path.split('/').filter(Boolean).pop()
  return (
    <>
      {(tags as NavItemType[]).map((item, index) => {
        const newItem = { ...item, active: item.name === section }
        return <NavItem item={newItem} key={index} />
      })}
    </>
  )
}

interface PropType {}

export default TagList
