import { Md5 } from 'ts-md5'

import { ERROR_MAP } from '../config/constant'

/**
 * 获取签名
 * @param params 请求参数
 * @returns 签名
 */
const getSign = (params: Record<string, any>): string => {
  const keys = Object.keys(params).sort()
  const parts = []
  for (const key of keys) {
    const value = params[key]
    if (value !== undefined && value !== null) {
      parts.push(`${key}=${value}`)
    }
  }
  const str = parts.join('&')
  return Md5.hashStr(str)
}

const handleError = (error: ErrorResponse) => {
  const { code, msg: message = '' } = error
  const resCode = typeof code === 'string' ? code : ERROR_MAP[code] || (ERROR_MAP[0] as string)
  return {
    code: resCode,
    message,
    payload: error
  }
}

interface ErrorResponse {
  code: number | string
  msg?: string
  traceId?: string
  version?: string
  stack?: any[]
}

export { getSign, handleError }
