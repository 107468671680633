import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const RedirectLayout: React.FC<PropType> = () => {
  const { lang, appCode } = window.AppConfig.data
  const navigate = useNavigate()

  useEffect(() => {
    navigate(`/${lang}/${appCode}/`, { replace: true })
  })

  return null
}

interface PropType {}

export default RedirectLayout
