import React from 'react'
import { useTranslation } from 'react-i18next'

import WhiteCard from '@/component/card/WhiteCard'

import type { VIPBenefit } from '@kulee/tga-sdk/types/types/vip'

const BenefitList: React.FC<PropType> = ({ benefits }) => {
  const { t } = useTranslation('vip')

  return (
    <WhiteCard title={t('title.benefit')}>
      <div className="flex flex-col gap-sm">
        {benefits.map(item => (
          <div key={item.id} className="flex items-center gap-md rounded-lg bg-primary-dark/5 p-3">
            <div className="flex-grow-0 basis-11">
              <img
                src={require(`@/asset/image/vip_benefit_icon/vip_benefit_${String(item.code).toLocaleLowerCase()}.png`)}
              />
            </div>
            <div className="flex-grow">
              <h4 className="mb-1">{item.name}</h4>
              <div className="text-xs text-fontcolor-400">{item.desc}</div>
            </div>
          </div>
        ))}
      </div>
    </WhiteCard>
  )
}

interface PropType {
  benefits: VIPBenefit[]
}

export default React.memo(BenefitList)
