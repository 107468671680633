import React, { useCallback, useEffect, useState } from 'react'
import { Wallet } from '@kulee/tga-sdk'

import TGASDK from '@/config/TGASDK'
import PayChannelItem from '../PayChannelItem'
import Loading from '@/component/Loading'
import type { CurrencyCode, Payment } from '@kulee/tga-sdk/types/types/wallet'

const PayChannelList: React.FC<PropType> = ({ payCurrency, payment, onChange }) => {
  const [payments, setPayments] = useState<Payment[]>()

  const handlePaymentChange = useCallback((payment: Payment) => {
    onChange && onChange(payment)
  }, [])

  const init = async () => {
    // 获取支付渠道
    const { data } = await TGASDK.module.Wallet.service.getPayments({
      currencyCode: payCurrency,
      platform: 'Android'
    })

    setPayments(data)

    if (!payment) {
      onChange && onChange(data[0])
    }
  }

  useEffect(() => {
    if (payCurrency) {
      init()
    }
  }, [payCurrency])

  useEffect(() => {
    TGASDK.addModule(Wallet)
  }, [])

  if (!payments) {
    return <Loading className="h-32" />
  }

  return (
    <div className="flex flex-col gap-sm px-md">
      {payments.map(item => (
        <PayChannelItem
          key={item.code}
          item={item}
          checked={payment?.code === item.code}
          onChange={handlePaymentChange}
        />
      ))}
    </div>
  )
}

interface PropType {
  payCurrency: CurrencyCode
  payment?: Payment
  onChange?: (payment: Payment) => void
}

export default PayChannelList
