import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Wallet } from '@kulee/tga-sdk'

import TGASDK from '@/config/TGASDK'
import Loading from '@/component/Loading'
import BillingItem from '../BillingItem'
import Divider from '@/component/Divider'
import Empty from '@/component/Empty'
import type { CurrencyCode, WalletBilling } from '@kulee/tga-sdk/types/types/wallet'

const PAGE_SIZE = 20

const Billing: React.FC<PropType> = ({ currency }) => {
  const { t } = useTranslation()
  const [billings, setBillings] = useState<WalletBilling[]>()
  const [pageNum, setPageNum] = useState<number>(1)
  const [hasMore, setHasMore] = useState(true)

  const fetchData = async () => {
    const { data, total } = await TGASDK.module.Wallet.service.getBilling({
      pageNum: pageNum,
      pageSize: PAGE_SIZE,
      currencyCode: currency
    })
    setHasMore(pageNum * PAGE_SIZE < total)
    setPageNum(pageNum + 1)
    setBillings([...(billings ? billings : []), ...data])
  }

  useEffect(() => {
    TGASDK.addModule(Wallet)
    fetchData()
  }, [])

  if (!billings) {
    return <Loading className="h-40" />
  }

  if (billings.length <= 0) {
    return <Empty />
  }

  const loader = <div className="text-center">{t('tip.loadMore')}</div>
  const endMessage = <div className="text-fontcolor-300 text-center">{t('tip.nothingMore')}</div>
  const eleId = `billingScroll_${currency}`

  return (
    <div id={eleId} className="h-[calc(100vh-9rem)] overflow-auto pr-md lg:h-[calc(100vh-9.2rem)]">
      <InfiniteScroll
        dataLength={billings.length}
        next={fetchData}
        hasMore={hasMore}
        loader={loader}
        endMessage={endMessage}
        scrollableTarget={eleId}
      >
        {billings.map((billing, index) => (
          <Fragment key={index}>
            <BillingItem item={billing} />
            <Divider />
          </Fragment>
        ))}
      </InfiniteScroll>
    </div>
  )
}

interface PropType {
  currency: CurrencyCode
}

export default Billing
