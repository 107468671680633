import React from 'react'

import DiamondBalance from '../DiamondBalance'
import GoldExchange from '../GoldExchange'

const TopUpGoldMain: React.FC<PropType> = () => {
  return (
    <div className="flex h-full flex-col">
      <div className="mb-6 px-md">
        <DiamondBalance />
      </div>
      <div className="flex-grow overflow-hidden rounded-t-3xl bg-white p-md">
        <GoldExchange />
      </div>
    </div>
  )
}

interface PropType {}

export default TopUpGoldMain
