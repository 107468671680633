import { QueryClient } from '@tanstack/react-query'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60 * 2, // 2 hours
      // staleTime: 1000 * 60 * 5, // 5 mins
      // staleTime: 15000, // 15 sec
      gcTime: 1000 * 60 * 60 * 24 // 24 hours
    }
  }
})

const persister = createSyncStoragePersister({
  storage: window.localStorage
})

export { PersistQueryClientProvider, queryClient, persister }
