import type { GameRoomInfo } from './types/PKGame'
import type { SimpleGameType } from './types/game'
import type { BaseUserInfo } from './types/uc'

export default class BaseProvider {
  /**
   * 读取进度条
   */
  public loadingProgress(data: LoadingProgressData): void {
    throw new Error(`loadingProgress have to be implement ${data}`)
  }

  /**
   * 游戏资源加载完成
   */
  public gameLoadComplete() {
    throw new Error('gameLoadComplete have to be implement')
  }

  /**
   * 获取游戏信息
   */
  public getGameInfo(): SimpleGameType {
    throw new Error('getGameInfo have to be implement')
  }

  /**
   * 获取对战游戏房间信息
   * 对战游戏才需要实现该方法
   */
  public getGameRoom(): GameRoomInfo {
    throw new Error('getGameRoom have to be implement')
  }

  /**
   * 获取登录用户的信息
   * 调用此方法，说明游戏必须要用户登录才能玩
   * 如果应用不提供登录或者登录异常，需要提示用户，并引导用户从游戏中退出
   */
  public async getUser(): Promise<BaseUserInfo> {
    throw new Error('getUser have to be implement')
  }

  /**
   * 游戏资源就绪，随时可开始游戏
   */
  public gameReady() {
    throw new Error('gameReady have to be implement')
  }

  /**
   * 打开排行版面板
   */
  public showRank(): void {
    throw new Error('showRank have to be implement')
  }

  /**
   * 打开分享面板
   */
  public showShare(): void {
    throw new Error('showShare have to be implement')
  }

  /**
   * 显示提示
   * @param options 提示信息
   */
  public openMessage(options: {
    type: 'success' | 'error' | 'warning'
    // 显示的内容，如果内容的格式出现  /^(\S)+\/(\S)+$/.test(content) === true 时需要做国际化
    // 应用禁用用户中心 UC/DISABLED,
    // 广告资源未准备就绪 'AD/RESOURCE_UNREADY',
    // 广告没有可用资源 'AD/RESOURCE_NA',
    // 广告请求过于频繁 'AD/FREQUENTLY',
    // 未找到广告渠道 'AD/NO_CHANNEL',
    // 未知错误 'AD/UNKNOWN_ERROR'
    content: string
    onClosed?: () => void
  }): void {
    throw new Error(`openMessage have to be implement ${options}`)
  }

  /**
   * 打开右上角操作按钮
   */
  public showOptions(): void {
    throw new Error('showOptions have to be implement')
  }

  /**
   * 关闭右上角操作按钮
   */
  public hideOptions(): void {
    throw new Error('hideOptions have to be implement')
  }

  // /**
  //  * 登录窗口
  //  */
  // public async goToLogin(): Promise<Token> {
  //   throw new Error('goToLogin have to be implement')
  // }

  // /**
  //  * 隐藏loading窗口
  //  */
  // public hideLoading(): void {
  //   throw new Error('hideLoading have to be implement')
  // }
}

interface LoadingProgressData {
  progress: number
}
