import { CustomError } from '@kulee/helper'
import { RESPONSE_CODES } from '@kulee/tga-constant'

import BaseModule from '../../BaseModule'
import WalletService from './Service'
import logger from '../../config/logger'
import type { BussnessOrderType, PayResult } from '../../types/wallet'

class Wallet extends BaseModule {
  public static override clazz = 'Wallet'

  public service!: WalletService

  public override run(): void {
    logger.log(`run module ${Wallet.clazz}`)

    this.service = new WalletService(this.context)
  }

  /**
   * 支付
   * @param options 支付参数
   * @returns 订单
   */
  public async pay(options: BussnessOrderType): Promise<PayResult> {
    const { paymentCode, payOrderNo } = options

    if (paymentCode === 'DIAMOND_PAY') {
      const res = await this.service.pay(payOrderNo)
      return res.data
    } else if (paymentCode === 'GOOGLEPAY') {
      return this.googleKuleWebviewPay(options)
    } else if (paymentCode === 'BIP_PAY') {
      return this.bipKuleWebviewPay(options)
    }

    throw new CustomError({ code: RESPONSE_CODES.WALLET_PAY_FAILED })
  }

  private async googleKuleWebviewPay(options: BussnessOrderType): Promise<PayResult> {
    const { payOrderNo, amount, desc, ext } = options
    if (!ext) {
      throw new CustomError({
        code: RESPONSE_CODES.PARAMETER_INVALID
      })
    }

    const { code } = await window.Bridge.wallet.pay({
      type: 'google',
      data: {
        order: payOrderNo,
        sku: ext.sku
      }
    })

    if (code === 1) {
      return {
        payOrderNo,
        amount,
        desc,
        status: 'paid'
      }
    }

    throw new CustomError({
      code: RESPONSE_CODES.WALLET_PAY_FAILED
    })
  }

  private async bipKuleWebviewPay(order: BussnessOrderType): Promise<PayResult> {
    const { payOrderNo, amount, desc, ext } = order
    // bip需要唯一的transactionId，按内部约定规则进行转换
    const date = new Date()
    const transactionId = payOrderNo.replace(
      /^\w{2}\d{2}/,
      date.getMinutes().toString().padStart(2, '0') + date.getSeconds().toString().padStart(2, '0')
    )

    const { code } = await window.Bridge.wallet.pay({
      type: 'bip',
      data: {
        order: {
          // --bip方提供--
          paymentMethod: 'reserve',
          wsuser: 'bu38908717060846186',
          password: 'bu389087774216a7',
          channelId: 50,
          isDeliverable: true,
          // --bip方提供--
          transactionId,
          amount,
          payStatus: 0,
          deliverStatus: 0,
          description: desc,
          itemList: [{ count: 1, id: ext?.id, name: ext?.sku }]
        }
      }
    })

    if (code === 1) {
      return {
        payOrderNo,
        amount,
        desc,
        status: 'paid'
      }
    }

    throw new CustomError({
      code: RESPONSE_CODES.WALLET_PAY_FAILED
    })
  }
}

export default Wallet
