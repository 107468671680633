import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import useAppLogin from '@/hook/useAppLogin'
import Button from '../Button'
import { ReactComponent as ForbiddenSVG } from '@/asset/svg/403.svg'

const Forbidden: React.FC<PropType> = ({ message, onBackClick }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const appLogin = useAppLogin()

  const { enabled, appLoginEnabled } = window.AppConfig.data.feature.uc

  const handleLoginClick = async () => {
    appLogin()
  }
  const handleBackClick = () => {
    if (onBackClick) {
      return onBackClick()
    }

    navigate(-1)
  }

  return (
    <div className="flex flex-col justify-center gap-md px-md py-14 text-center">
      <div className="mb-md flex flex-col items-center justify-center text-fontcolor-300">
        <ForbiddenSVG className="aspect-square w-36 fill-gray-400" />
        <p>{message ? message : t('message.forbidden')}</p>
      </div>
      <div className="flex flex-col gap-md">
        {enabled && appLoginEnabled && (
          <Button type="primary" onClick={handleLoginClick}>
            {t('btn.login')}
          </Button>
        )}

        <Button onClick={handleBackClick}>{t('btn.back')}</Button>
      </div>
    </div>
  )
}

interface PropType {
  message?: string
  onBackClick?: () => void
}

export default Forbidden
