import React, { useContext } from 'react'
import { twMerge } from 'tailwind-merge'

import AuthContext from '@/context/AuthContext'
import useMediaBreakpoint from '@/hook/useMediaBreakpoint'
import { coverUrlToFit } from '@/helper'
import Mask from '@/component/Mask'
import Picture from '../Picture'
import { ReactComponent as CameraSVG } from '@/asset/svg/camera.svg'

const Cover: React.FC<PropType> = ({ hoverEdit = false, focusEdit = false, className, onClick }) => {
  const { user } = useContext(AuthContext)
  const { size } = useMediaBreakpoint()

  const handleClick = () => {
    onClick && onClick()
  }

  const classNames = twMerge(
    'group relative h-32 sm:h-48 md:h-80',
    onClick || hoverEdit || focusEdit ? 'cursor-pointer' : '',
    className
  )

  return (
    <div className={classNames} onClick={handleClick}>
      <Picture src={coverUrlToFit(user?.cover || '', size)} cover />
      {hoverEdit && (
        <>
          <Mask visible={true} getContainer={false} zIndex={10} className="hidden group-hover:block" />
          <div className="absolute bottom-2 right-2 z-20 hidden h-8 w-8 items-center justify-center overflow-hidden rounded-full bg-gray-200 group-hover:flex">
            <CameraSVG className="h-5 w-5" />
          </div>
        </>
      )}
      {focusEdit && (
        <div className="absolute left-1/2 top-1/2 flex h-10 w-10 -translate-x-1/2 -translate-y-1/2 items-center justify-center overflow-hidden rounded-full bg-black/80">
          <CameraSVG className="h-5 w-5 fill-white" />
        </div>
      )}
    </div>
  )
}

interface PropType {
  hoverEdit?: boolean
  focusEdit?: boolean
  className?: string
  onClick?: () => void
}

export default Cover
