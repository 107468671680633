import { STORAGE_KEYS } from '@kulee/tga-constant'
import { Cookies, EventBus } from '@kulee/helper'

import BaseModule from '../../BaseModule'
import logger from '../../config/logger'
import { EVENT_CMDS } from './constant'

import type { EventCallback, TalkMicStatus, TalkContext } from '../../types/Talk'

class Talk extends BaseModule {
  public static override clazz = 'Talk'
  public static EVENT_CMDS = EVENT_CMDS

  private enable = false
  private inited = false
  private channelId: string | null = null
  private mac: TalkMicStatus = Cookies.get(STORAGE_KEYS.USER_TALK_MIC_STATUS) === 'on' ? 'on' : 'off'
  private speaker: TalkMicStatus = Cookies.get(STORAGE_KEYS.USER_TALK_SPEAKER_STATUS) === 'on' ? 'on' : 'off'

  public override async run() {
    logger.log(`run module ${Talk.clazz}`)

    this.enable = this.context.appConfig.feature.talk.enabled && !!window.Bridge && !!window.Bridge.talk
    this.init()
  }

  public getContext(): TalkContext {
    return {
      enable: this.enable,
      inited: this.inited,
      channelId: this.channelId,
      mac: this.mac,
      speaker: this.speaker
    }
  }

  public addEventListener(cmd: EVENT_CMDS, callback: EventCallback) {
    EventBus.addEventListener(cmd, callback)
  }

  public removeEventListener(cmd: EVENT_CMDS, callback: EventCallback) {
    EventBus.removeEventListener(cmd, callback)
  }

  private dispatchEvent<T = any>(cmd: EVENT_CMDS, payload?: T) {
    EventBus.dispatch(cmd, payload)
  }

  public async init() {
    if (!this.enable) {
      return
    }

    if (!this.inited) {
      await window.Bridge.talk.init({
        ymKey: this.context.appConfig.feature.talk.key,
        ymSecret: this.context.appConfig.feature.talk.secret
      })

      this.removeCallback()
      this.addCallback()
      this.dispatchEvent(EVENT_CMDS.TALK_INITED)

      this.inited = true
    }
  }

  public async joinChannel(id: string, userId: string) {
    if (!this.enable) {
      return
    }

    if (this.channelId === id || !id) {
      return
    }
    if (!this.inited) {
      await this.init()
    }

    await window.Bridge.talk.joinChannel({
      channelId: id,
      userId
    })
    this.channelId = id

    this.mac === 'on' ? this.setMicOn() : this.setMicOff()
    this.speaker === 'on' ? this.setSpeakerOn() : this.setSpeakerOff()
  }

  public async exitChannel() {
    if (!this.enable) {
      return
    }

    if (!this.inited) {
      await this.init()
    }

    await window.Bridge.talk.leaveChannelAll()
    this.channelId = null
  }

  public async setMicOn() {
    if (!this.enable) {
      return
    }

    this.channelId && (await window.Bridge.talk.openMicrophone())

    Cookies.set(STORAGE_KEYS.USER_TALK_MIC_STATUS, 'on')
    this.mac = 'on'
    this.dispatchEvent(EVENT_CMDS.MY_MIC_ON)
  }

  public async setMicOff() {
    if (!this.enable) {
      return
    }

    this.channelId && (await window.Bridge.talk.closeMicrophone())

    Cookies.set(STORAGE_KEYS.USER_TALK_MIC_STATUS, 'off')
    this.mac = 'off'
    this.dispatchEvent(EVENT_CMDS.MY_MIC_OFF)
  }

  public async setSpeakerOn() {
    if (!this.enable) {
      return
    }

    this.channelId && (await window.Bridge.talk.openSpeaker())

    Cookies.set(STORAGE_KEYS.USER_TALK_SPEAKER_STATUS, 'on')
    this.speaker = 'on'
    this.dispatchEvent(EVENT_CMDS.MY_SPEAKER_ON)
  }

  public async setSpeakerOff() {
    if (!this.enable) {
      return
    }

    this.channelId && (await window.Bridge.talk.closeMicrophone())

    Cookies.set(STORAGE_KEYS.USER_TALK_SPEAKER_STATUS, 'off')
    this.speaker = 'off'
    this.dispatchEvent(EVENT_CMDS.MY_SPEAKER_OFF)
  }

  private addCallback(): void {
    window.Bridge.system.addEventListener<BridgeNS.EventTalkPayload>({
      eventName: 'othersMicOn',
      callBack: userId => {
        this.dispatchEvent(EVENT_CMDS.OTHER_MIC_ON, userId)
      }
    })

    // 监听其他用户麦克风关闭
    window.Bridge.system.addEventListener<BridgeNS.EventTalkPayload>({
      eventName: 'othersMicOff',
      callBack: userId => {
        this.dispatchEvent(EVENT_CMDS.OTHER_MIC_OFF, userId)
      }
    })

    // 监听其他用户扬声器打开
    window.Bridge.system.addEventListener<BridgeNS.EventTalkPayload>({
      eventName: 'othersSpeakerOn',
      callBack: userId => {
        this.dispatchEvent(EVENT_CMDS.OTHER_MIC_ON, userId)
      }
    })

    // 监听其他用户扬声器关闭
    window.Bridge.system.addEventListener<BridgeNS.EventTalkPayload>({
      eventName: 'othersSpeakerOff',
      callBack: userId => {
        this.dispatchEvent(EVENT_CMDS.OTHER_SPEAKER_OFF, userId)
      }
    })

    // 监听其他用户开始讲话
    window.Bridge.system.addEventListener<BridgeNS.EventTalkPayload>({
      eventName: 'othersVoiceOn',
      callBack: userId => {
        this.dispatchEvent(EVENT_CMDS.OTHER_SPEAK, userId)
      }
    })

    // 监听其他用户停止讲话
    window.Bridge.system.addEventListener<BridgeNS.EventTalkPayload>({
      eventName: 'othersVoiceOff',
      callBack: userId => {
        this.dispatchEvent(EVENT_CMDS.OTHER_STOP_SPEAK, userId)
      }
    })

    // 监听成员是否发生变化
    window.Bridge.system.addEventListener<BridgeNS.EventTalkPayload>({
      eventName: 'getMemberChange',
      callBack: userId => {
        this.dispatchEvent(EVENT_CMDS.MEMBER_CHANGE, userId)
      }
    })
  }

  /**
   * 移除语音事件回调
   */
  private removeCallback(): void {
    window.Bridge.system.removeEventListener({
      eventName: 'othersMicOn'
    })
    window.Bridge.system.removeEventListener({
      eventName: 'othersMicOff'
    })
    window.Bridge.system.removeEventListener({
      eventName: 'othersSpeakerOn'
    })
    window.Bridge.system.removeEventListener({
      eventName: 'othersSpeakerOff'
    })
    window.Bridge.system.removeEventListener({
      eventName: 'othersVoiceOn'
    })
    window.Bridge.system.removeEventListener({
      eventName: 'othersVoiceOff'
    })
    window.Bridge.system.removeEventListener({
      eventName: 'getMemberChange'
    })
  }
}

export default Talk
