import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import useStatePopup from '@/hook/useStatePopup'
import { PopupSelector, PopUpStore } from '@/store/PopupStore'
import Drawer from '../../Drawer'
import Cover from '../../Cover'
import Avatar from '../../Avatar'
import Button from '../../Button'
import AuthContext from '@/context/AuthContext'

const EditProfilePopup: React.FC<PropType> = () => {
  const { t } = useTranslation(['translation', 'uc'])
  const { user } = useContext(AuthContext)
  const editProfilePopupVisible = useStatePopup(PopupSelector.editProfilePopupVisible)

  const handleClose = () => {
    PopUpStore.setEditProfilePopupVisible(false)
  }

  const handleAvatarEditClick = () => {
    PopUpStore.setEditNicknamePopupVisible(true)
  }

  const handleCoverEditClick = () => {
    PopUpStore.setEditCoverPopupVisible(true)
  }

  return (
    <Drawer
      visible={editProfilePopupVisible}
      zIndex={900}
      onClose={handleClose}
      lockScroll={true}
      className="bg-primary-light"
      title={t('modalTitle.editProfile', { ns: 'uc' })}
    >
      <div className="px-sm">
        <Cover className="!h-32" focusEdit onClick={handleCoverEditClick} />
        <div className="-mt-7 px-5">
          <Avatar className="h-16" focusEdit />
          <div className="mt-md">
            <div className="flex gap-2">
              <div className="font-custom-bold flex-grow overflow-hidden text-ellipsis text-nowrap text-lg">
                {user?.nickname}
              </div>
              <Button type="primary" onClick={handleAvatarEditClick}>
                {t('btn.edit')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  )
}

interface PropType {}

export default EditProfilePopup
