import { Logger, browser } from '@kulee/helper'

// 是否是在SDK下运行
export const KULEWEBVIEW = browser.version.kuleWebview
// 移动端
export const MOBILE = browser.version.mobile
export const MOBILE_IOS = browser.version.ios
export const MOBILE_ANDROID = browser.version.android

export const KULEWEBVIEW_ANDROID = KULEWEBVIEW && MOBILE_ANDROID
export const KULEWEBVIEW_IOS = KULEWEBVIEW && MOBILE_IOS
// 日志前缀
export const LOGGER_PREFIX = 'PAGE'
// 日志输出等级
export const LOGGER_LEVEL = Logger.LEVEL.INFO

// 历史游戏本地存储KEY
export const RECENT_GAME_LOCAL_STOAGE_KEY = 'tga-recent-games'
// 历史游戏本地存储上限个数
export const RECENT_GAME_MAX = 60
// 对战游戏用户最长离线时间
export const PKGAME_USER_MAX_OFFLINE_TIME = 10 * 1000

// 头像配置
export const AVATAR = {
  BASE_PATH: 'https://cdn1.j4fgame.com/resource/avatars',
  LIST: [
    'default',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29'
  ]
}
// 主页封面配置
export const COVER = {
  BASE_PATH: 'https://cdn2.j4fgame.com/resource/cover',
  LIST: ['default', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14']
}

// react-query keys
export const QUERY_KEYS = {
  // 首页数据
  GAME_HOME_DATA: 'game/home',
  // 带标签游戏列表
  GAME_TGAGAMES_LIST: 'game/tags/games',
  // 游戏详情
  GAME_DETAIL: 'game/detail',
  // 标签列表
  TAG_LIST: 'tag/list',
  // 标签详情
  TAG_DETAIL: 'tag/detail',
  // 标签下游戏列表
  TAG_GAMES: 'tag/games',
  // 签到列表
  CHECKINS: 'checkin/data',
  // 签到状态
  CHECKIN_STATUS: 'checkin/status',
  // 任务列表
  TASKS: 'task/data',
  // 任务列表
  TASKS_STATUS: 'task/status',
  // 成就列表
  ACHIEVEMENTS: 'achievement/data',
  // 对战游戏配置
  PK_GAME_CONFIG: 'pkgame/config',
  // 排行榜
  RANK_CONFIG: 'rank/config',
  // 钱包充值商品列表
  WALLET_CHARGE_COMMODITY: 'wallet/charge-commodity'
}

export const EVENT_NAMESPACE = {
  POPUP: 'popup'
}
