// 游戏与平台通信事件名
// 站在游戏的角度来命名
const GAME_EVENTS = {
  // ----游戏前----
  // 游戏上报加载进度，从开始加载到GAME_READY事件的发出
  REPORT_LOADING_PROGRESS: 'tga-report-loading-progress',
  // 游戏资源加载完成
  GAME_LOAD_COMPLETE: 'tga-game-load-complete',
  // 平台通知游戏初始化数据
  ON_GAME_INIT: 'tga-on-game-init',
  // 游戏资源加载完成准备就绪
  GAME_READY: 'tga-game-ready',
  // 开始游戏,
  ON_GAME_START: 'tga-on-game-start',
  // 游戏暂停
  ON_GAME_PAUSE: 'tga-on-game-pause',
  // 游戏恢复
  ON_GAME_RESUME: 'tga-on-game-resume',

  // ----游戏中----
  // 上报用户完成的游戏关卡
  REPORT_LEVEL_PASSED: 'tga-report-level-passed',
  // 上报用户游戏通关
  REPORT_CLEAR_GAME: 'tga-report-clear-game',
  // 上报用户当前积分
  REPORT_SCORE: 'tga-report-score',
  // 上报用户当前积分结果
  REPORT_SCORE_RESULT: 'tga-report-score-result',
  // 展示用户排行窗口
  SHOW_RANK_MODAL: 'tga-show-rank-modal',
  // 分享
  SHARE: 'tga-share',
  // 打开全局提示消息
  OPEN_MESSAGE: 'tga-open-message',
  // 监听全局提示关闭事件
  ON_MESSAGE_CLOSED: 'tga-on-message-closed',

  // 获取广告配置
  GET_AD_CONFIG: 'tga-get-ad-config',
  // 监听收到广告配置
  ON_AD_CONFIG_RECEIVED: 'tga-on-ad-config-received',
  // 初始化广告
  AD_INIT: 'tga-ad-init',
  // 监听广告初始化结果
  ON_AD_INITED: 'tga-on-ad-inited',
  // 播放开屏广告
  SHOW_PREROLL_AD: 'tga-show-preroll-ad',
  // 播放插屏广告
  SHOW_INTERSTITIAL_AD: 'tga-show-interstitial-ad',
  // 准备播放激励广告
  PRE_SHOW_REWARD_AD: 'tga-pre-show-reward-ad',
  // 播放激励广告
  SHOW_REWARD_AD: 'tga-formal-show-reward-ad',
  // 接收广告过程中事件
  ON_AD_EVENT: 'tga-on-ad-event',
  // 发送广告过程中事件
  SEND_AD_EVENT: 'tga-send-ad-event',

  // 对战发送游戏消息
  SEND_GAME_MESSAGE: 'tga-send-game-message',
  // 对战接收游戏消息
  ON_RECEIVE_GAME_MESSAGE: 'tga-on-receive-game-message',
  // 收到用户退出消息
  ON_PLAYER_EXIT: 'tga-on-player-exit',
  // 对战游戏结束
  GAME_FINISH: 'tga-game-finish',

  // 支付
  PAY_ORDER: 'tga-pay-order',
  // 支付结果
  PAY_ORDER_RESULT: 'tga-pay-order-result'
}

export default GAME_EVENTS
